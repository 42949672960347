import { calculateAge, isHourAfter7PM, isWorkingDay, isNextDayWorkingDay } from './DateUtils'
import { getProcessWithSameBranchAndSubBranch } from '../services/ProcessesServices';
import { getCurrentState } from '../logic/process/ProcessLogic';
import { getUserType } from '../services/UserServices';
import { getTermsAccepted } from '../logic/cookies/CookiesLogic';
import { store } from '../store/store';
import { getActiveInsurances } from '../services/InsuranceServices';
import { getVwoGroup } from '../services/VwoServices';
import { getCurrentListOfVersions, versionsHasDoors, getSinistros } from '../logic/process/ProcessAutoLogic';
import { hasCarInfo, isAlreadyAutoClient, getCarProposalPercentage } from '../logic/process/ProcessAutoLogic';
/**
 * 
 * @param {*} options 
 * @returns 
 */
export function processDateOptions(options) {
    let objectToReturn = {
    };
    if (options) {
        let optionsKeys = Object.keys(options);
        for (let i = 0; i < optionsKeys.length; i++) {
            let currentMain = options[optionsKeys[i]];
            objectToReturn[optionsKeys[i]] = {};
            let optionsSubKeys = Object.keys(currentMain);
            for (let j = 0; j < optionsSubKeys.length; j++) {
                let currentSub = currentMain[optionsSubKeys[j]];
                objectToReturn[optionsKeys[i]][optionsSubKeys[j]] = processValueFormula(currentSub);
            }
        }
    }
    return objectToReturn;
};
/**
 * Operations regrarding uthere expressions in all configurations
 */
export function processValueFormula(formula, userValues) {
    let valueFormulaResult = null;
    if (formula.values && formula.values.length > 0) {
        let listOfProcessedValues = [];
        for (let i = 0; i < formula.values.length; i++) {
            let valueResult = processValueFromFormula(formula.values[i], userValues);
            if (valueResult !== null && valueResult !== undefined) {
                listOfProcessedValues.push(valueResult);
            }
        }
        if (formula.operation) {
            valueFormulaResult = processOperation(formula.operation, listOfProcessedValues, formula.castTo);
        }
        else {
            if (listOfProcessedValues.length > 0) {
                valueFormulaResult = listOfProcessedValues[0];
            }
        }
    }
    return valueFormulaResult;
};
/**
 * Process values from formulas
 * @param {} value 
 */
export function processValueFromFormula(value, userValues) {
    let valueToReturn = null;
    let notCastedValue = null;
    if (value && value.type) {
        switch (value.type) {
            case 'operation':
                notCastedValue = getOperationVariable(value);
                break;
            case 'constant':
                notCastedValue = value.value;
                break;
            case 'systemVariable':
                notCastedValue = getSystemVariable(value.value);
                break;
            case 'userVariable':
                if (userValues && value) {
                    if (Array.isArray(value.value)) {
                        let tmpValue = null;
                        for (let i = 0; i < value.value.length; i++) {
                            if (!tmpValue) {
                                tmpValue = userValues[value.value[i]];
                            }
                            else {
                                tmpValue = tmpValue[value.value[i]];
                            }
                        }
                        notCastedValue = tmpValue;
                    }
                    else {
                        notCastedValue = userValues[value.value];
                    }
                }
                break;
            case 'conditional_constant':
                notCastedValue = processBooleanCondition(value.condition, userValues) ? value.value1 : value.value2;
                break;
            default:
                notCastedValue = value.value;
                break;
        }
    }
    if (value.castTo) {
        switch (value.castTo) {
            case 'int':
                valueToReturn = parseInt(notCastedValue);
                break;
            case 'date':
                valueToReturn = new Date(notCastedValue);
                break;
            case 'timestamp':
                if (value.subType) {
                    switch (value.subType) {
                        case 'iso':
                            valueToReturn = new Date(notCastedValue).getTime();
                            break;
                        default:
                            valueToReturn = notCastedValue.getTime();
                    }
                }
                else {
                    valueToReturn = notCastedValue.getTime();
                }
                break;
            default:
                break;
        }
    }
    else {
        valueToReturn = notCastedValue;
    }
    return valueToReturn;
};

function getOperationVariable(operation) {
    let valueToReturn = null;
    if (operation) {
        let value1 = typeof operation.value === "string" ? getSystemVariable(operation.value) : operation.value;
        let value2 = typeof operation.value2 === "string" ? getSystemVariable(operation.value2) : operation.value2;

        if (operation.operation) {
            switch (operation.operation) {
                case 'MINUS':
                    valueToReturn = value1 - value2;
                    break;
                default:
                    break;
            }
        }
    }
    return valueToReturn;
}

/**
 * 
 * @param {*} variableName 
 */
function getSystemVariable(variableName) {
    let valueToReturn = null;
    switch (variableName) {
        case 'yearToday':
            valueToReturn = new Date().getFullYear();
            break;
        case 'monthToday':
            valueToReturn = new Date().getMonth();
            break;
        case 'dayToday':
            valueToReturn = new Date().getDate();
            break;
        case 'dateToday':
            valueToReturn = new Date();
            break;
        default:
            break;
    }
    return valueToReturn;
};
/**
 * 
 * @param {*} operation 
 * @param {*} values 
 */
function processOperation(operation, values, castTo) {
    let resultToReturn = null;
    if (operation) {
        switch (operation) {
            case 'MINUS':
                if (values.length > 1) {
                    resultToReturn = values[0] - values[1];
                }
                break;
            case 'PLUS':
                if (values.length > 1) {
                    resultToReturn = values[0] + values[1];
                }
                break;
            case 'ADD_DAYS':
                if (values.length > 1) {
                    if (castTo) {
                        switch (castTo) {
                            case "timestamp":
                                resultToReturn = values[0].setDate(values[0].getDate() + values[1]);
                                break;
                            default:
                                resultToReturn = new Date(values[0].setDate(values[0].getDate() + values[1]));
                                break;
                        }
                    }
                }
                break;
            default:
                break;
        }
    }
    return resultToReturn;
};

/**
 * 
 * @param {*} sequenceObject 
 * @returns 
 */
export function processBooleanSequence(sequenceObject, manualValues) {
    if (sequenceObject && sequenceObject.sequence && sequenceObject.sequence.length > 0) {
        let sequence = sequenceObject.sequence;
        let result = false;
        for (let i = 0; i < sequence.length; i++) {
            if ((i + 1) === sequence.length)
                return sequence[i].result;
            switch (sequence[i].type) {
                case 'OR':
                    result = processORFormula(sequence[i].conditions, manualValues);
                    break;
                case 'AND':
                    result = processANDFormula(sequence[i].conditions, manualValues);
                    break;
                default:
                    break;
            }
            if (result) {
                return sequence[i].result;
            }
        }
    }
    return null;
};
/**
 * 
 */
function processORFormula(conditions, manualValues) {
    let result = false;
    if (conditions) {
        for (let i = 0; i < conditions.length; i++) {
            let condition = conditions[i];
            result = processBooleanCondition(condition, null, manualValues);
            if (result) {
                break;
            }
        }
    }
    return result;
};

/**
 * 
 */
function processANDFormula(conditions, manualValues) {
    let result = true;
    if (conditions) {
        for (let i = 0; i < conditions.length; i++) {
            let condition = conditions[i];
            result = processBooleanCondition(condition, null, manualValues);
            if (!result) {
                return false;
            }
        }
    }
    return result;
};
/**
 * 
 * @param {*} condition 
 * @param {*} values 
 */
function processBooleanCondition(condition, userValues, manualValues) {
    let insurances = null;
    let value1 = null;
    let value2 = null;
    let value3 = null;
    let today = null;
    let bornDate = null;
    let simulationState = getCurrentState();
    switch (condition.comparator) {
        case 'ARRAY_EMPTY':
            value1 = processValueFromFormula(condition.values[0], manualValues ? manualValues : simulationState.userValues);
            if (value1 && value1.length === 0) {
                return true;
            }
            else {
                return false;
            }
        case 'GENDER_FEMALE':
            if (userValues && userValues.gender) {
                if (userValues.gender === "F")
                    return true;
            }
            else if (store.getState().user && store.getState().user.personalData
                && store.getState().user.personalData.gender && store.getState().user.personalData.gender === 'F') {
                return true;
            }
            return false
        case 'UTHERE_USER':
            return getUserType() === 'uthereUser';
        case 'EMAIL_USER':
            return getUserType() === 'uthereUser' || getUserType() === 'anonymous';
        case 'HAS_CAR_INFO':
            return hasCarInfo();
        case 'IS_ALREADY_AUTO_CLIENT':
            return isAlreadyAutoClient();
        case 'NOT_ALMOST_DONE_AUTO':
            let percentageProposal = getCarProposalPercentage();
            if (!percentageProposal || percentageProposal < 80) {
                return true;
            }
            else {
                return false;
            }
        case 'NOT_UTHERE_USER':
            return getUserType() !== 'uthereUser';
        case 'HAS_ACTIVE_HOUSE_INSURANCE_ONE':
            insurances = getActiveInsurances(null, 3);
            if (insurances && insurances.length === 1) {
                return true;
            }
            return false;
        case 'HAS_ACTIVE_HOUSE_INSURANCE_MULTI':
            insurances = getActiveInsurances(null, 3);
            if (insurances && insurances.length > 1) {
                return true;
            }
            return false;
        case 'HAS_ACTIVE_HOUSE_AT_LEAST_ONE':
            insurances = getActiveInsurances(null, 3);
            if (insurances && insurances.length > 0) {
                return true;
            }
            return false;
        case 'COOKIES_ACCEPTED':
            return getTermsAccepted();
        case 'LAST_PAGE_DIFFERENT':
            if (condition.values && condition.values.length > 0) {
                value1 = processValueFromFormula(condition.values[0], simulationState.userValues);
            }
            if (value1 !== undefined && value1 != null) {
                if (simulationState.pageHistory && simulationState.pageHistory.length > 0
                    && simulationState.pageHistory[simulationState.pageHistory.length - 1].id !== value1) {
                    return true;
                }
            }
            return false;
        case 'AUTO_SINISTRO':
            let sinistros = getSinistros()
            if (condition.values && condition.values.length > 0) {
                if (condition.values[0].value == sinistros) {
                    return true;
                }
            }
            return false;
        case 'CAR_HAS_VERSIONS':
            let listOfVersions = getCurrentListOfVersions();
            return listOfVersions && listOfVersions.length > 1 ? true : false;
        case 'CAR_HAS_NOT_VERSIONS':
            let listOfVersions2 = getCurrentListOfVersions();
            return listOfVersions2 && listOfVersions2.length > 1 ? false : true;
        case 'CAR_HAS_VERSIONS_DOORS':
            return versionsHasDoors()
        case 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH':
            let processId = getProcessWithSameBranchAndSubBranch()
            return processId !== null ? true : false;
        case 'VWO_GROUP_2':
            let vwoGroup = getVwoGroup();
            return vwoGroup == "2" ? true : false;
        case 'NOT_EMPTY':
            if (condition.values && condition.values.length > 0) {
                value1 = processValueFromFormula(condition.values[0], simulationState.userValues);
            }
            if (value1 !== undefined && value1 != null) {
                return true
            }
            else {
                return false;
            }
        case 'EMPTY':
            if (condition.values && condition.values.length > 0) {
                value1 = processValueFromFormula(condition.values[0], simulationState.userValues);
                if (value1 === undefined || value1 === null) {
                    return true
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        case 'NOT_WORKING_DAY':
            return !isWorkingDay() || (isHourAfter7PM() && !isNextDayWorkingDay());
        case 'IS_AFTER_7':
            return isHourAfter7PM();
        case 'EQUALS':
            if (condition.values && condition.values.length > 1) {
                value1 = processValueFromFormula(condition.values[0], manualValues ? manualValues : simulationState.userValues);
                value2 = processValueFromFormula(condition.values[1], manualValues ? manualValues : simulationState.userValues);
            }
            if (value1 !== null && value1 !== undefined && value2 !== null && value2 !== undefined) {
                return String(value1) === String(value2);
            }
            else {
                return false;
            }
        case 'NOT_EQUALS':
            if (condition.values && condition.values.length > 1) {
                value1 = processValueFromFormula(condition.values[0], manualValues ? manualValues : simulationState.userValues);
                value2 = processValueFromFormula(condition.values[1], manualValues ? manualValues : simulationState.userValues);
            }
            if (value1 !== null && value1 !== undefined && value2 !== null && value2 !== undefined) {
                if (String(value1) === String(value2)) {
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
        case 'CONTAINS':
            value1 = processValueFromFormula(condition.values[0], simulationState.userValues);
            if (condition.values && condition.values.length > 1) {
                value1 = processValueFromFormula(condition.values[0], simulationState.userValues);
                value2 = processValueFromFormula(condition.values[1], simulationState.userValues);
                if (value1 && value1[value2]) {
                    return true;
                }
            }

            return false;
        case 'GREATER':
            if (condition.values && condition.values.length > 1) {
                value1 = processValueFromFormula(condition.values[0], manualValues ? manualValues : simulationState.userValues);
                value2 = processValueFromFormula(condition.values[1], manualValues ? manualValues : simulationState.userValues);
            }
            if (value1 !== null && value1 !== undefined && value2 !== null && value2 !== undefined) {
                return parseInt(value1) > parseInt(value2);
            }
            else {
                return false;
            }
        case 'LESSER':
            if (condition.values && condition.values.length > 1) {
                value1 = processValueFromFormula(condition.values[0], manualValues ? manualValues : simulationState.userValues);
                value2 = processValueFromFormula(condition.values[1], manualValues ? manualValues : simulationState.userValues);
            }
            if (value1 !== null && value1 !== undefined && value2 !== null && value2 !== undefined) {
                return parseInt(value1) < parseInt(value2);
            }
            else {
                return false;
            }
        case 'AGE_GREATER':
            if (condition.values && condition.values.length > 0
                && simulationState.userValues && condition.bornDateValueId && simulationState.userValues[condition.bornDateValueId]) {
                bornDate = new Date(simulationState.userValues[condition.bornDateValueId].replace(/-/g, "/"));
                let age = calculateAge(bornDate);
                if (age > condition.values[0]) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        case 'AGE_LESS':
            if (condition.values && condition.values.length > 0
                && simulationState.userValues && condition.bornDateValueId && simulationState.userValues[condition.bornDateValueId]) {
                bornDate = new Date(simulationState.userValues[condition.bornDateValueId].replace(/-/g, "/"));
                let age = calculateAge(bornDate);
                if (age < condition.values[0]) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        case 'HASPROPOSAL':
            if (simulationState && simulationState.hasProposal) {
                return true;
            }
            return false;
        case 'BIRTHDAY':
            if (simulationState.userValues && condition.bornDateValueId && simulationState.userValues[condition.bornDateValueId]) {
                today = new Date();
                bornDate = new Date(simulationState.userValues[condition.bornDateValueId].replace(/-/g, "/"));
                if (today.getDate() === bornDate.getDate() &&
                    today.getMonth() === bornDate.getMonth()) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        case 'ALMOST_BIRTHDAY':
            if (simulationState.userValues && condition.bornDateValueId && simulationState.userValues[condition.bornDateValueId]) {
                today = new Date();
                bornDate = new Date(simulationState.userValues[condition.bornDateValueId].replace(/-/g, "/"));
                let currentYear = today.getFullYear();
                let birthDayTime = bornDate.setFullYear(currentYear);
                let todayTime = today.getTime();
                let todayPlus30 = today.setDate(today.getDate() + 30);
                return (birthDayTime > todayTime && birthDayTime < todayPlus30);
            }
            else {
                return false;
            }
        case 'BETWEEN':
            if (condition.values && condition.values.length > 2) {
                value1 = processValueFromFormula(condition.values[0], simulationState.userValues);
                value2 = processValueFromFormula(condition.values[1], simulationState.userValues);
                value3 = processValueFromFormula(condition.values[2], simulationState.userValues);
                if (value1 && value2 && value3) {
                    return (value2 > value1 && value2 < value3);
                }
            }
            return false;
        case 'CURRENT_STEP':
            if (simulationState && simulationState.currentPageId && condition && condition.values[0]
                && condition.values[0].value == simulationState.currentPageId) {
                return true;
            }
            return false;
        default:
            break;
    }
    return false;
}

export function getHierarchyValue(allValues, hierarquy) {
    let value = null;
    try {
        if (allValues && hierarquy) {
            let hierarchyArray = hierarquy.split(".");
            for (let i = 0; i < hierarchyArray.length; i++) {
                if (value === null) {
                    value = allValues[hierarchyArray[i]];
                }
                else {
                    value = value[hierarchyArray[i]];
                }
            }
        }
    }
    catch (e) {

    }
    return value;
}

export function replaceValueInText(text, values) {
    let valueToReturn = text;

    if (text) {
        let textToSet = text;
        if (typeof text === "object") {
            if (text.value && values[text.value]) {
                let genderValue = values[text.value] === "F" ? "F" : values[text.value] === "M" ? "M" : "U";
                textToSet = text[genderValue];
            }
            else {
                textToSet = text.M;
            }
        }

        let split = textToSet.split("{");
        if (split.length > 1) {
            let firstPartText = split[0];
            let secondPart = split[1];
            let secondPartSplit = secondPart.split("}");
            let elementVar = secondPartSplit[0];
            let secondPartText = secondPartSplit[1];
            let textToReplace = firstPartText + values[elementVar] + secondPartText;
            valueToReturn = textToReplace;
        }
        else {
            valueToReturn = textToSet;
        }
    }
    return valueToReturn;
}

