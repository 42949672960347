import './HomeFeatures.scss';
import checkSvg from '../../../assets/svg/home-check.svg'
import parse from 'html-react-parser';
/**
* HomeFeatures
*/
function HomeFeatures(props) {

    const cardConfiguration = props.branch === 1 ?
        [
            {
                title: "A sua garantia nos <span className='bold'>momentos mais difícieis</span> da sua vida",
                sub: 'Fique seguro',
                class: 'home-features-container-life-1',
            },
            {
                title: "<span className='bold'>Tem a certeza de que está protegido</span> pelo seu seguro de vida?",
                sub: 'Temos o melhor para si',
                class: 'home-features-container-life-2',
                dark: true,
            },
            {
                title: "<span className='bold'>Proteja o sonho</span> dos seus filhos",
                sub: 'Só o melhor para eles',
                class: 'home-features-container-life-3',
                white: true,
            },
            {
                title: "<span className='bold'>Já tem um seguro?</span><br/>Tratamos de tudo e ajudamos a transferir",
                sub: 'Liberte-se do banco',
                class: 'home-features-container-life-4',
                dark: true,
            },
            {
                title: "<span className='bold'>Poupe até 70%.</span><br/>Fazemos as contas e explicamos tudo",
                sub: 'Comece a poupar',
                class: 'home-features-container-life-5',
                white: true,
            },
        ]
        : props.branch === 3 ?
            [
                {
                    title: "<span className='bold'>Rebentou um cano</span> e inundou o seu chão?",
                    sub: 'Está coberto',
                    class: 'home-features-container-pipe',
                },
                {
                    title: "Lembra-se das <span className='bold'>inundações</span> do inverno",
                    sub: 'Está coberto',
                    class: 'home-features-container-flood',
                },
                {
                    title: "Arrombaram a sua porta e <span className='bold'>roubaram o computador?</span>",
                    sub: 'Está coberto',
                    class: 'home-features-container-computer',
                },
                {
                    title: "<span className='bold'>Partiu o lavatório</span> da sua casa de banho?",
                    sub: 'Está coberto',
                    class: 'home-features-container-torneira',
                },
                {
                    title: "Teve um <span className='bold'>incêndio na sua cozinha?</span>",
                    sub: 'Está coberto',
                    class: 'home-features-container-fire',
                },
            ]
            :
            [
                {
                    title: "<span className='bold'>Teve um acidente?</span>",
                    sub: 'Cobre o custo de reparação',
                    class: 'auto-features-container-accident',
                    white: true,
                },
                {
                    title: "O seu carro <span className='bold'>parou na autoestrada?</span>",
                    sub: 'Cobre o reboque do seu carro até a oficina, e a si até ao seu destino',
                    class: 'auto-features-container-batery',
                    white: true,
                },
                {
                    title: "Tem o <span className='bold'>vidro rachado</span>",
                    sub: 'Cobre reparação ou substituição do seu vidro',
                    class: 'auto-features-container-glass',
                    white: true,
                },
                {
                    title: "Precisa de <span className='bold'>carro de substituição?</span>",
                    sub: 'Tem um carro à sua disposição',
                    class: 'auto-features-container-sub',
                    white: true,
                },
                {
                    title: "O seu carro foi <span className='bold'>roubado?</span>",
                    sub: 'Cobre o custo de substituição do seu carro',
                    class: 'auto-features-container-stop',
                    white: true,
                },
            ]

    return (
        <div className='home-features-container'>
            {props.branch === 1 ?
                <div className='home-title-container'>
                    <h2>Um seguro de Vida que o <span className='bold'>protege a si,
                        à sua família e à sua casa</span></h2>
                    <h6>A partir de 10€ / mês</h6>
                </div> : props.branch === 3 ?
                    <div className='home-title-container'>
                        <h2>Um seguro Multirrisco Habitação que <span className='bold'>cobre muito,</span>
                            <span className='bold'> mas custa pouco</span></h2>
                        <h6>A partir de 8€ / mês</h6>
                    </div> :
                    <div className='home-title-container'>
                        <h2>Um Seguro automóvel<br />que<span className='bold'> funciona quando precisa</span></h2>
                        <h6>Porque às vezes precisa mesmo de ajuda</h6>
                    </div>}
            <div className='home-features-container-tiles'>
                <div className='home-features-container-tiles-vertical'>
                    <div className='home-features-container-tiles-vertical-up'>
                        <div
                            className={`home-features-container-element
                        ${cardConfiguration[0].class}`}>
                            <div className='home-features-container-tile-text'>
                                {parse(cardConfiguration[0].title)}
                            </div>
                            <div className='home-features-container-tile-subtext'>
                                <img src={checkSvg} alt={"Está coberto"}></img>
                                <div>{parse(cardConfiguration[0].sub)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='home-features-container-tiles-vertical-down'>
                        <div className={`home-features-container-element-1 ${cardConfiguration[1].class}`}>
                            <div className={`home-features-container-tile-text home-features-container-tile-text-2 ${cardConfiguration[1].dark ? "home-features-text-dark" : null}`}>
                                {parse(cardConfiguration[1].title)}
                            </div>
                            <div className='home-features-container-tile-subtext'>
                                <img src={checkSvg} alt={"Está coberto"}></img>
                                <div className={`${cardConfiguration[1].dark ? "home-features-text-dark" : null}`}>{parse(cardConfiguration[1].sub)}</div>

                            </div>
                        </div>
                        <div className={`home-features-container-element-1 ${cardConfiguration[2].class}`}>
                            <div className={`home-features-container-tile-text ${cardConfiguration[2].white ? "home-features-text-white" : null}`}>
                                {parse(cardConfiguration[2].title)}
                            </div>
                            <div className='home-features-container-tile-subtext'>
                                <img src={checkSvg} alt={"Está coberto"}></img>
                                <div className={`${cardConfiguration[2].white ? "home-features-text-white" : null}`}>{parse(cardConfiguration[2].sub)}</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='home-features-container-tiles-vertical'>
                    <div className={`home-features-container-element-2 ${cardConfiguration[3].class}`}>
                        <div className={`home-features-container-tile-text ${cardConfiguration[3].white ? "home-features-text-white" : cardConfiguration[3].dark ? "home-features-text-dark" : null}`}>
                            {parse(cardConfiguration[3].title)}
                        </div>
                        <div className='home-features-container-tile-subtext'>
                            <img src={checkSvg} alt={"Está coberto"}></img>
                            <div className={`${cardConfiguration[3].white ? "home-features-text-white" : cardConfiguration[3].dark ? "home-features-text-dark" : null}`}>{parse(cardConfiguration[3].sub)}</div>
                        </div>
                    </div>
                    <div className={`home-features-container-element-2 ${cardConfiguration[4].class}`}>
                        <div className={`home-features-container-tile-text ${cardConfiguration[4].white ? "home-features-text-white" : null}`}>
                            {parse(cardConfiguration[4].title)}
                        </div>
                        <div className='home-features-container-tile-subtext'>
                            <img src={checkSvg} alt={"Está coberto"}></img>
                            <div className={`${cardConfiguration[4].white ? "home-features-text-white" : null}`}>{parse(cardConfiguration[4].sub)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HomeFeatures;