export const creditBuyFlow = {
    begin: {
        sequence: [
            {
                result: 'continue',
                type: 'OR',
                conditions: [
                    {
                        comparator: 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH',

                    }
                ],
            },
            {
                result: 'whenHouseContract',
            }]
    },
    laraIntroMessage:
    {
        sequence:
            [{
                result: ['laraIntroMessage'],
                type: 'OR',
                conditions: [
                    {
                        comparator: 'NOT_EMPTY',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'name',
                            },
                        ]
                    }
                ],
            },
            {
                result: ['laraIntroMessage'],
            }]
    },
    pages: {
        continue: {
            type: 'continue',
            nextPageId: 'whenHouseContract',
            userMessage: 'userContinue',
            laraMessage: ['laraContinue'],
            desktopMessage: ['laraContinue'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblContinueSimulation',

                    },
                    {
                        value: 2,
                        label: 'lblStartNewSimulation',

                    },
                ]
            }
        },
        whenHouseContract: {
            type: 'radio',
            nextPageId: 'bornDate',
            laraMessage: ['laraLifeCreditBuyBegin', 'laraHasDeedDate'],
            desktopMessage: ['laraHasDeedDate'],
            userMessage:
            {
                sequence:
                    [{
                        result: 'userHasDeedDate',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'whenHouseContract',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userHasDeedDateNo',
                    }]
            },
            options: {
                columns: true,
                followupInline: true,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'deedDate',
                                title: 'Quando vai fazer a escritura?',
                                type: 'date',
                                options: {
                                    type: 'date',
                                    label: 'Data escritura',
                                    validation: 'notPast',
                                    validationMessage: 'Ups. Parece que inseriu uma data no passado',
                                    min: {
                                        year: {
                                            values: [
                                                {
                                                    type: 'systemVariable',
                                                    value: 'yearToday',
                                                },

                                            ]
                                        },
                                    },
                                    max: {
                                        year: {
                                            operation: 'PLUS',
                                            values: [
                                                {
                                                    type: 'systemVariable',
                                                    value: 'yearToday',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 1,
                                                },
                                            ]
                                        }
                                    },
                                    default: {
                                        day: {
                                            values: [
                                                {
                                                    type: 'systemVariable',
                                                    value: 'dayToday',
                                                },
                                            ]
                                        },
                                        year: {
                                            values: [
                                                {
                                                    type: 'systemVariable',
                                                    value: 'yearToday',
                                                },
                                            ]
                                        },
                                        month: {
                                            values: [
                                                {
                                                    type: 'systemVariable',
                                                    value: 'monthToday',
                                                }
                                            ]
                                        }
                                    }
                                }
                            }]
                    },
                    {
                        value: 2,
                        label: 'lblNo',
                    },
                ]
            }
        },
        bornDate: {
            type: 'date',
            laraMessage: ["laraBornDateNoName"],
            desktopMessage: ['laraBornDateNoName'],
            userMessage: 'userBornDate',
            nextPageId: 'ocupation',
            options: {
                onBirthday: "Parabéns! 🎉🍾",
                onAlmostBirthday: "Uau. Está quase a fazer anos 😀",
                validation: {
                    type: "AGE",
                    min: 18,
                    max: 65,
                    minText: "Não podemos apresentar uma proposta se não tiver 18 anos",
                    maxText: "Não podemos apresentar uma proposta se tiver mais do que 65 anos"
                },
                label: 'lblBorndDate',
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 40,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 66,
                            },
                        ]
                    }
                }
            }
        },
        ocupation: {
            type: 'ocupation',
            nextPageId: 'ocupationActivities',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHappyBirthday', 'laraOcupation'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                        ],
                    },
                    {
                        result: ['laraAlmostBirthday', 'laraOcupation'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                        ],
                    },
                    {
                        result: ['laraOcupation'],
                    }]
            },
            desktopMessage: ['laraOcupation'],
            userMessage: 'userOcupation',
        },
        ocupationActivities: {
            type: 'ocupationActivities',
            nextPageId: 'activities',
            laraMessage: ['laraOcupationActivities'],
            desktopMessage: ['laraOcupationActivities'],
            userMessage: 'userOcupationActivities',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                        {
                            value: 2,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                        {
                            value: 3,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                        {
                            value: 4,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                    ]
                },
                items:
                    [
                        {
                            value: 4,
                            label: 'lblOcupationActivities4',

                        },
                        {
                            value: 1,
                            label: 'lblOcupationActivities1',

                        },
                        {
                            value: 2,
                            label: 'lblOcupationActivities2',

                        },
                        {
                            value: 3,
                            label: 'lblOcupationActivities3',

                        },
                    ]
            }
        },
        activities : {
            type: 'switch',
            nextPageId: {
                sequence:
                    [{
                        result: 'whatSports',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'activities',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'insuranceOwnership',
                    }]
            },
            laraMessage: ['laraActivities', 'laraActivities2'],
            desktopMessage: ['laraActivities'],
            userMessage: 'userActivities',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblMotorcycle',
                    },
                    {
                        value: 2,
                        label: 'lblSports',
                    },
                ]
            }
        },
        whatSports: {
            laraMessage: ['laraSports'],
            desktopMessage : ['laraSports'],
            userMessage: 'userSports',
            nextPageId: 'insuranceOwnership',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectSports',
                list: 'sportList',
                subType : 'sports',
            }
        },
        insuranceOwnership: {
            type: 'radio',
            nextPageId: {
                sequence: [

                    {
                        result: 'bornDateOther',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],

                    },
                    {
                        result: 'debtValue',
                    }]
            },
            laraMessage: ['laraInsuranceOwnershipBuy'],
            desktopMessage: ['laraInsuranceOwnershipBuy'],
            userMessage: 'userInsuranceOwnership',
            options: {
                otherValues: { otherGender: 'gender' },
                items: [
                    {
                        value: 0,
                        label: 'lblInMyNameBuy',
                        otherValues: { gender: 'U' }
                    },
                    {
                        value: 1,
                        label: 'lblInMyNameAndWifeBuy',
                        otherValues: { gender: 'F' }
                    },
                    {
                        value: 2,
                        label: 'lblInMyNameAndHusbandBuy',
                        otherValues: { gender: 'M' }
                    },
                    {
                        value: 3,
                        label: 'lblInMyNameAndOtherBuy',
                        otherValues: { gender: 'U' }
                    },
                ]
            }
        },
        bornDateOther: {
            type: 'date',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraOtherBornDate2F', 'laraOtherBornDateF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDate2M', 'laraOtherBornDateM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDate2', 'laraOtherBornDate'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraOtherBornDate2F', 'laraOtherBornDateF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDate2M', 'laraOtherBornDateM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDate2', 'laraOtherBornDate'],
                    }]
            },
            userMessage: 'userBornDateOther',
            nextPageId: 'ocupationOther',
            options: {
                label: 'lblBorndDate',
                onBirthday: "Parabéns! 🎉🍾",
                onAlmostBirthday: "Uau. Está quase a fazer anos 😀",
                validation: {
                    type: "AGE",
                    min: 18,
                    max: 65,
                    minText: "Não podemos apresentar uma proposta se não tiver 18 anos",
                    maxText: "Não podemos apresentar uma proposta se tiver mais do que 65 anos"
                },
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 30,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 17,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 90,
                            },
                        ]
                    }
                }
            }
        },
        ocupationOther: {
            type: 'ocupation',
            nextPageId: 'ocupationActivitiesOther',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHappyBirthday', 'laraOcupationOtherF'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraHappyBirthday', 'laraOcupationOtherM'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraHappyBirthday', 'laraOcupationOther'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                        ],
                    },
                    {
                        result: ['laraAlmostBirthday', 'laraOcupationOtherF'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraAlmostBirthday', 'laraOcupationOther'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOther'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraOcupationOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOther'],
                    }]
            },
            userMessage: 'userOcupationOther',
            options: {
                genderValue: 'otherGender'
            }
        },
        ocupationActivitiesOther: {
            type: 'ocupationActivities',
            nextPageId: 'activitiesOther',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraOcupationActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOther'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraOcupationActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOther'],
                    }]
            },
            userMessage: 'userOcupationActivitiesOther',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                        {
                            value: 2,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                        {
                            value: 3,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                        {
                            value: 4,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                    ]
                },
                items:
                    [
                        {
                            value: 1,
                            label: 'lblOcupationActivitiesOther1',

                        },
                        {
                            value: 2,
                            label: 'lblOcupationActivitiesOther2',

                        },
                        {
                            value: 3,
                            label: 'lblOcupationActivitiesOther3',

                        },
                        {
                            value: 4,
                            label: 'lblOcupationActivitiesOther4',

                        },
                    ],

            }
        },
        activitiesOther : {
            type: 'switch',
            nextPageId: {
                sequence:
                    [{
                        result: 'whatSportsOther',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'activitiesOther',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'debtValue',
                    }]
            },
            laraMessage: {
                sequence:
                    [{
                        result: ['laraActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOther'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOther'],
                    }]
            },
            userMessage: 'userActivitiesOther',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblMotorcycleOther',
                    },
                    {
                        value: 2,
                        label: 'lblSportsOther',
                    },
                ]
            }
        },
        whatSportsOther: {
            laraMessage: ['laraSports'],
            desktopMessage : ['laraSports'],
            userMessage: 'userSportsOther',
            nextPageId: 'debtValue',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectSports',
                list: 'sportList',
                subType : 'sports',
            }
        },
        debtValue: {
            type: 'input',
            refreshProposal: true,
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraDebtValueF'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'knowInsuranceBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'bankGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDebtValue'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'knowInsuranceBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDebtValueNoBank'],
                    }]
            },
            laraMessage:
            {
                sequence:
                    [{
                        result: ['laraDebtValue0F', 'laraDebtValueF'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'knowInsuranceBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'bankGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDebtValue0', 'laraDebtValue'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'knowInsuranceBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraDebtValueNoBank'],
                    }]
            },
            nextPageId: 'insuranceBank',
            userMessage: 'userDebtValue',
            options: {
                type: 'currency',
                decimals: 0,
                label: 'lblDebtValue',
                min: 5000,
                minWarn: 'O valor mínimo são 5.000€',
                max: 3000000,
                maxWarn: 'Uff, o valor máximo são 3 milhões de euro',
            }
        },
        insuranceBank: {
            laraMessage: ['laraInsuranceBankBuy', 'laraInsuranceBank2'],
            desktopMessage: ['laraInsuranceBankBuy'],
            desktopMessageSecondary: ['laraInsuranceBank2'],
            userMessage: 'userInsuranceBank',
            nextPageId: 'trip',
            type: 'fullRadio',
            options: {
                label: 'lblSelectBank',
                list: 'bankList',
                otherValues: { bankGender: 'gender', bankItp: 'itp' },
            }

        },
        trip: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'tripWhere',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'trip',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'generateProposal',
                        }
                    ]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraTripTwo'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTrip'],
                    }]
            },
            laraMessage: {
                sequence:
                    [{
                        result: ['laraTripTwo'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTrip'],
                    }]
            },
            userMessage: 'userTrip',
            options: {
                columns: 2,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                    {
                        value: 3,
                        label: 'lblDontKnowYet',

                    },
                ]
            }
        },
        tripWhere: {
            laraMessage: {
                sequence:
                    [{
                        result: ['laraTripWhereTwo'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTripWhere'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraTripWhereTwo'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTripWhere'],
                    }]
            },
            userMessage: 'userTripWhere',
            nextPageId: 'generateProposal',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectCountries',
                list: 'countryList',
            }
        },
        generateProposal: {
            type: 'loader',
            generateProposal: true,
            laraMessage: ['laraProposal'],
            desktopMessage: ['laraProposal'],
            userMessage: {
                sequence:
                    [{
                        result: null,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EMAIL_USER',
                            }
                        ],
                    },
                    {
                        result: null,
                    }]
            },
            options: {
                barNumber: 4,
                messages: [ "A analisar os <br/><b> seus dados</b>",
                "A procurar a <br/> <b> melhor proposta </b>",
                "A calcular  <br/><b> descontos</b>",
                "Um pouco de  <br/><b> magia uthere 😉</b>",
                "Encontrei a<br/><b>sua proposta</b>"],
                interval: 30
            },
            nextPageId: "showProposal",
        },
        showProposal: {
            allowBack : false,
            type: 'proposal',
            options: {
                subType: 'show',
            },
            nextPageId: {
                sequence: [
                    {
                        result: 'deedDate',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'whenHouseContract',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            }
                        ],

                    },
                    {
                        result: 'personalData',
                    }]
            },
            laraMessage: ['laraProposalShow'],
            desktopMessage: ['laraProposalShow'],
            userMessage: 'userProposal',
            userMessageOptions: {
                valueId: 'selectedProposal',
                type: 'window',
                items: [
                    {
                        id: 'selectedProposal',
                        type: 'selectedProposal',
                        titleLabel: 'Proposta'
                    },
                ]
            }

        },
        deedDate: {
            type: 'date',
            laraMessage: ['laraDeedDate'],
            desktopMessage: ['laraDeedDate'],
            userMessage: 'userDeedDate',
            nextPageId: {
                sequence: [
                    {
                        result: 'personalData',
                    }]
            },
            options: {
                label: 'lblDeedDate',
                validation: 'notPast',
                validationMessage: 'Ups. Parece que inseriu uma data no passado',
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'PLUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 2,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 0,
                            },
                        ]
                    }
                }
            },
            validation: {
                type: 'NO_PAST',
                text: 'Opps! Parece que escolheu uma data no passado. Tente novamente'
            }
        },
        personalData: {
            type: 'form',
            allowBack: false,
            userMessage: 'userPersonalDataNoName',
            laraMessage: {
                sequence:
                    [{
                        result: 'laraPersonalDataBuyTwo',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'laraPersonalDataDesktop',
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraPersonalDataBuyTwo'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraPersonalDataDesktop'],
                    }]
            },
            nextPageId: "questionnaireCheckout",
            userMessageOptions: {
                valueId: 'personalData',
                type: 'window',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais'
                    },
                ]
            },
            options: {
                buttonLabel: 'Dados pessoais',
                showReview: true,
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais',
                        preValues: {
                            firstName: 'name',
                            familyNames: 'familyNames',
                            email: 'email',
                        }
                    },
                ]
            }
        },
        questionnaireCheckout: {
            type: 'questionnaire',
            userMessage: 'userQuestionnaire',
            laraMessage: ['laraQuestionaireName'],
            desktopMessage: ['laraQuestionaireName'],
            nextPageId: {
                sequence: [
                    {
                        result: 'personalDataOther',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],

                    },
                    {
                        result: 'paymentData',
                    }]
            },
            valueId: 'questionnaire',
            userMessageOptions: {
                type: 'window',
                valueId: 'questionnaire',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        type: 'questionnaire',
                    }
                ]
            },
            options: {
                buttonLabel: 'Dados de saúde',
                genderValue : 'gender',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        text : 'Sofre de alguma doença ou está de baixa?',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        text : 'Fez alguma cirurgia ou está fazer algum tratamento?',
                        type: 'questionnaire',
                    },
                ]
            }
        },
        personalDataOther: {
            type: 'form',
            userMessage: 'userPersonalDataOther',
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraPersonalDataOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOtherM'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'M',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOther'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraPersonalDataOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOtherM'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'M',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOther'],
                        }]
            },
            nextPageId: 'questionnaireOther',
            userMessageOptions: {
                valueId: 'personalDataOther',
                type: 'window',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais',
                    },
                ]
            },
            options: {
                showReview: true,
                buttonLabel: 'Dados pessoais',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais',
                        preValues: {
                            firstName: 'otherName',
                            familyNames: 'otherFamilyNames',
                        }
                    },
                ]
            }
        },
        questionnaireOther: {
            type: 'questionnaire',
            userMessage: 'userQuestionnaireOther',
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraQuestionaireOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraQuestionaireOtherM'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'M',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraQuestionaireOther'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraQuestionaireOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraQuestionaireOther'],
                        }]
            },
            nextPageId: 'paymentData',
            userMessageOptions: {
                type: 'window',
                valueId: 'questionnaireOther',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        type: 'questionnaire',
                    }
                ]
            },
            options: {
                buttonLabel: 'Dados de saúde',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        text:
                        {
                            value: "otherGender",
                            M: 'Agora vou precisar de alguns dados de gerais de saúde do seu marido',
                            F: 'Agora vou precisar de alguns dados de gerais de saúde da sua mulher',
                            U: 'Agora vou precisar de alguns dados de gerais de saúde da segunda pessoa',
                        },
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e Cirurgias',
                        text:
                        {
                            value: "otherGender",
                            M: 'O seu marido, fez alguma cirurgia ou está fazer algum tratamento?',
                            F: 'A sua mulher, fez alguma cirurgia ou está fazer algum tratamento?',
                            U: 'A segunda pessoa, fez alguma cirurgia ou está fazer algum tratamento?',
                        },
                        type: 'questionnaire',
                    },
                ]
            }
        },
        paymentData: {
            type: 'paymentData',
            laraMessage: ['laraPaymentBuy'],
            desktopMessage: ['laraPaymentDataDesktop'],
            userMessage: 'userPaymentData',
            nextPageId: 'endSimulation',
            submit: {
                label: "lblSendingPropostal",
                state: 2
            }
        },
        endSimulation: {
            disableExitConfirm: true,
            last: true,
            desktopMessage:
            {
                sequence:
                    [{
                        result: ['laraEndSimulation2'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'analiseTecnica',
                                    },
                                    {
                                        type: 'constant',
                                        value: true,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraEndSimulation2'],
                    }]
            },
            laraMessage: ['laraEndSimulation', 'laraEndSimulation2'],
            hideAvatar: true,
            type: 'rate',
            userMessage: 'userMessageEndSimulation',
            nextPageId: 'exitSimulation',
            allowBack: false,
            allowRestart: false,
        },
        exitSimulation: {
            hideAvatar: true,
            laraMessage: ['laraExitSimulation'],
            desktopMessage: ['laraHouseEndSimulationAnaliseTecnica'],
            disableExitConfirm: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
        }
    },
};
