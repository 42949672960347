import { getFunctions } from '../firebase/UthereFirebase';
import { getCurrentState } from '../logic/process/ProcessLogic';
import { setEventGenerateProposal } from './AnalyticsServices';
import { store } from '../store/store';
import { getUserId } from './UserServices';
import { setTimeoutWait } from '../utils/GeneralUtils';

export function getProcessById(id) {
    let proposalToReturn = null;
    if (store && store.getState() && store.getState().processes
        && store.getState().processes.list) {
        if (store.getState().processes.list[id]) {
            return store.getState().processes.list[id];
        }
    }
    return proposalToReturn;
}

/**
 * 
 */
export function getProposal(type) {
    return new Promise(async function (resolve, reject) {
        let processData = getCurrentState();
        let userId = getUserId();
        let functions = await getFunctions();
        let functionsImpl = await import("firebase/functions");
        const uthereGetProposal = functionsImpl.httpsCallable(functions, 'uthereGetProposal');
        let result = null;
        let maxTries = 3;
        let tries = 0;
        try {
            if (type === "has" && !userId) {
                if (store && store.getState() && store.getState().proposals && store.getState().proposals.list) {

                    while (tries < maxTries) {
                        if (store.getState().proposals.list[processData.processId]) {
                            result = store.getState().proposals.list[processData.processId];
                            break
                        }
                        else {
                            await setTimeoutWait(3000);
                        }
                    }
                    resolve(result);
                }
                else {
                    resolve(null);
                }
            }
            else if (type === "get") {
                result = await uthereGetProposal({
                    processData: processData,
                    type: type
                });

                resolve(result);
            }
            else {
                if (type === "generate") {
                    try {
                        setEventGenerateProposal(processData.branch, processData.subBranch)
                    } catch (e) { };
                }
                uthereGetProposal({
                    processData: processData,
                    type: type
                }).catch(function (err) {
                })
                resolve();
            }
        }
        catch (e) {
            reject(e);
        }
    });
}
/**
 * 
 * @param {*} rawData 
 * @returns 
 */
export function processProposalData(rawData) {
    let processProposalDataToReturn = {
        beginDate: rawData.beginDate,
        selectedInclusions: rawData.selectedInclusions,
        proposals: [],
        recomendedIndex: null,
    };
    if (rawData && rawData.productsOrder && rawData.productsConfiguration) {
        let productsOrder = rawData.productsOrder;
        let productsConfiguration = rawData.productsConfiguration;
        if (productsOrder.length === 3) {
            processProposalDataToReturn.recomendedIndex = 1;
            processProposalDataToReturn.proposals.push(productsConfiguration[productsOrder[2]]);
            processProposalDataToReturn.proposals.push(productsConfiguration[productsOrder[0]]);
            processProposalDataToReturn.proposals.push(productsConfiguration[productsOrder[1]]);
        }
        else if (productsOrder.length === 2) {
            processProposalDataToReturn.recomendedIndex = 1;
            processProposalDataToReturn.proposals.push(productsConfiguration[productsOrder[1]]);
            processProposalDataToReturn.proposals.push(productsConfiguration[productsOrder[0]]);
        }
        else {
            processProposalDataToReturn.recomendedIndex = 0;
            processProposalDataToReturn.proposals.push(productsConfiguration[productsOrder[0]]);
        }
    }
    return processProposalDataToReturn;

};