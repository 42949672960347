/**
 * Faq
 */
import './Faq.scss';
import parse from 'html-react-parser';
import laraImage from '../../../../assets/lara/lara.png';
import luisImage from '../../../../assets/luis/luis.png';
import { isMobile } from '../../../../utils/DeviceUtils';

function Faq(props) {
    const faqList = props.faqList ? props.proposal ? props.faqList : props.faqList[props.type ? props.type : 1] : [];
    const mobile = isMobile();
    const handleAccordionClick = function (id) {
        const accordion = document.querySelector("#u-" + id);
        if (accordion) {
            accordion.classList.toggle('active')
        }
    }
    return (
        <div className='u-acordion-list'>
            {faqList.map((item, i) => (
                <div key={i}>
                    <div className="u-accordion" id={"u-" + item.id}>

                        <div
                            className="u-accordion-header"
                            onClick={() => handleAccordionClick(item.id)}
                        >
                            <div className="u-accordion-header-title">
                                <div>{parse(item.title)}</div>
                            </div>

                            <div className="u-accordion-header-icon">
                                <div>
                                    <svg
                                        className="u-accordion-header-icon-more"
                                        width="auto"
                                        height="auto"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M8 12H16" stroke="black" strokeLinecap="round" />
                                        <path
                                            d="M12 8L12 16"
                                            stroke="black"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                    <svg
                                        className="u-accordion-header-icon-less"
                                        width="auto"
                                        height="auto"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8 12H16"
                                            stroke="black"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </div>
                            </div>


                        </div>
                        <div className="u-accordion-content">
                            {!props.proposal || mobile ?
                                <div className="u-accordion-proposal-header-lara-container">
                                    <div className="u-accordion-proposal-header-lara-line"></div>
                                    {!props.type || props.type === 1 ?
                                        <img className="u-accordion-header-lara-name-img" src={laraImage} alt="Lara" />
                                        : <img className="u-accordion-header-lara-name-img" src={luisImage} alt="Luís" />}
                                    <div className="u-accordion-proposal-header-lara-line"></div>
                                </div> : null}
                            <div>{parse(item.text)}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Faq;