export function isIOSversion15() {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    return parseInt(v[1]) >= 15 ? true : false;
  }
}

export function getDeviceType() {
  let ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    if (/Instagram/.test(ua)) {
      return "mobile";
    } else {
      return "tablet";
    }
  }
  if (
    /Mobile|iP(hone|od)|Android|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

export function isMobile() {
  return getDeviceType() === "mobile";
}

export function isTablet() {
  return getDeviceType() === "tablet" || getDeviceType === "ipad";
}

export function isTouch() {
  return isTablet() || isMobile();
}

