export const lang = {
    //AVATAR
    luisIntroMessageName: 'Olá, eu vou ajudar a participar o seu sinistro',
    luisIntroMessageHouseName: 'Olá {name}, eu vou ajuda-lo a participar o seu sinistro',
    luisIntroMessage: 'Olá, eu vou ajudar a participar o seu sinistro',
    luisWhatInsuranceMessage : 'Que sinistro quer participar?',
    luisEndClaimOther: 'Claro. Espero que consiga resolver o seu sinistro',
    luisInsurer: 'Lamento que tenha acontecido. Vou já tratar disso são apenas alguns minutos',
    luisInsurer2: 'Sem o número da sua apólice não o consigo ajudar',
    luisInsurer4: 'Mas posso dar-lhe o contacto da sua seguradora que o vai ajudar. Em que seguradora tem o seu seguro?',
    luisInsurer3: 'Em que seguradora fez o seu seguro?',
    luisInsuranceOwner: 'É titular do seguro?',
    luisInsuranceOwnerRelation: 'Não tem problema. Qual é a sua relação com o titular do seguro?',
    luisHouseRoberyDontHaveCoverage : 'Infelizmente como não adicionou o plano protecção extra quando comprou a sua apólice não está coberto contra roubos. Lamento muito',
    luisHouseRoberyDontHaveCoverage2 : 'Posso adicionar esta cobertura por apenas (valor da cobertura) por (periodicidade selecionada na compra da apólice). Ela estará activa na próxima vez que tiver um roubo. Posso adicionar?',
    luisName: 'Não tem problema. Como se chama?',
    luisInsurerData: 'O telefone da {insurer} é {insurerPhone} e o e-mail para participação do sinistro é {insurerEmail}',
    luisInsurerData2 : 'Eles vão ajudar',
    luisName2: 'Perfeito. Como se chama?',
    luisHouseClaimTypeName: 'Olá {name}, o que aconteceu?',
    luisHouseClaimType : 'O que aconteceu?',
    luisHouseWaterNeighbor: 'Sabe se os vizinhos também foram afectados?',
    luisHouseWaterWhen1: 'Quando detectou os danos?',
    luisHouseWaterAffectedZones0: 'Sugiro que os contacte. Eu depois ajudo-o a gerir o sinistro',
    luisHouseWaterAffectedZones: 'Que zonas da casa foram afectadas?',
    luisHouseWaterAffectedZones1: 'Vou informar a {insurer}. Depois vou precisar que me dê a morada dos seus vizinhos',
    luisHouseWaterAffectedZones2: 'Melhor assim. Que zonas da casa foram afectadas?',
    luisHouseWaterAffectedZones3: 'Que zonas da casa foram afectadas?',
    luisHouseWaterDamagedAssets: 'Algum dos seus bens foi danificado? Pense em coisas como um sofá, um quadro ou um vaso',
    luisHouseWaterClaimDescription0: 'Vou informar a {insurer}. Não deverá haver problema se tiver contratado a cobertura de conteúdo.',
    luisHouseWaterClaimDescription1: 'Faça uma breve descrição do que se passou. Descreva como detectou o problema e o que viu',
    luisHouseWaterClaimDescription2: 'Seja tão especifico quanto puder',
    luisHouseWaterRepairedDamagedAssets: 'Obrigado. Substituiu ou reparou o que esteve na origem do sinsitro?',
    luisHouseWaterRepairedDamagedAssets2: 'Por exemplo, substituiu alguma peça na casa de banho?',
    luisHouseWaterHasBudget: 'Já tem um orçamento para resolução de todos os danos?',
    luisHouseDamageHasBudget: 'E já tem algum orçamento?',
    luisAddress1: 'Perfeito. Qual a morada associada à apólice de seguro?',
    luisAddress2: 'Não tem problema. Qual a morada associada à apólice de seguro?',
    luisAddress3: 'Obrigado. Qual a morada associada à apólice de seguro?',
    luisAddress4: 'Vou precisar da morada associada ao seu seguro. Qual é o código postal?',
    luisKnowYourInsuranceNumber: 'Obrigado. Sabe o número da sua apólice de seguro?',
    luisKnowInsuranceNumber: 'Obrigado. Sabe o número da apólice de seguro',
    luisKnowInsuranceNumber2: 'Sabe o número da apólice?',
    luisOwnerName0: 'Não tem problema, mas é possível que a {insurer} lhe telefone para pedir este número',
    luisOwnerName: 'Como se chama o titular do seguro?',
    luisNif0: 'Não tem problema, mas é possível que a {insurer} lhe telefone para pedir este número',
    luisNif1: 'Qual é o seu número de contribuinte?',
    luisNif2: 'Qual é o número de contribuinte do titular da apólice?',
    luisPhone: 'Obrigado.  Qual é o seu contacto, caso precise de mais informações?',
    luisClaimReview: 'Perfeito. Por favor reveja a sua participação de sinistro',
    luisClaimReview2: 'É importante que garanta que está tudo correcto',
    luisTermsOfService: 'Obrigado {name}. Já só falta que leia e aprove os nossos termos de serviço',
    luisLogin0: 'Já tenho tudo o que preciso para participar o sinistro',
    luisLogin: 'Qual é o seu e-mail? Vou enviar a participação de sinistros também para si',
    luisEndClaim0: 'Já está! A {insurer} vai entrar em contacto consigo até {nextTwoWorkingDay}',
    luisWantToPhone: 'Estamos a construir a melhor experiência possível para o ajudar na participação do seu sinistro, mas ainda não temos o seguro {insuranceType}',
    luisWantToPhone2: 'Vou dar-lhe o telefone da sua seguradora. Quer?',
    luisEndClaim: 'Eu e a equipa da uthere vamos acompanhar o sinistro. Contacte-nos sempre que for necessário',
    luisEndClaimLife: 'Estamos trabalhar para lhe oferecer a melhor experiência de sinistros em Portugal mas infelizmente ainda não o consigo ajudar',
    luisEndClaimLife2: 'Talvez queira telefonar directamente para a {insurer}. Eles vão poder ajudá-lo',
    luisEndClaimNoOption: 'Estamos trabalhar para lhe oferecer a melhor experiência de sinistros em Portugal mas infelizmente ainda não o consigo ajudar',
    luisAddressNeighbors: 'Qual a morada dos vizinhos que foram afectados?',
    luisHouseRoberyTakeAnything: 'Espero que ninguém se tenha magoado. Levaram alguma coisa?',
    luisHouseRoberyWhen: 'Imagino como se está a sentir. Quando aconteceu o roubo?',
    luisHouseRoberyWhen2: 'Ainda bem. Quando aconteceu o roubo?',
    luisHouseRoberyPolice: 'Já participou o roubo à polícia?',
    luisHouseRoberyHasRelatory: 'Óptimo. Tem o relatório da policia consigo?',
    luisHouseDamageHasRelatory: 'Óptimo. Tem o relatório do técnico consigo?',
    luisIntroHouseUser : 'Olá {name}!  Eu vou ajudar a participar o seu sinistro. O que aconteceu?',
    luisInsuranceAtUthere : 'Qual é o seu NIF?',
    luisEndClaimNoReport : 'Envie-me cópia da participação à polícia para luis@uthere.eu. Quanto mais rápido o fizer mais rápido será tratado o seu sinistro',
    houseRoberyClaimDescription6: 'Não tem problema, mas a {insurer} vai precisar da cópia do relatório',
    houseRoberyClaimDescription: 'Faça uma breve descrição do que se passou. Seja tão especifico quanto puder',
    houseRoberyClaimDescription4: 'Vamos continuar. Faça uma breve descrição do que se passou. Seja tão específico quanto puder',
    houseRoberyClaimDescription1: 'Vai ter que fazer a participação do roubo à polícia e enviar cópia do relatório nos próximos dias. A {insurer} não vai aceitar o sinistro sem a cópia desta participação',
    houseRoberyClaimDescription2: 'Vai ter que fazer a participação do roubo à polícia e enviar cópia do relatório nos próximos dias',
    houseRoberyClaimDescription3: 'Sem esta informação, a {insurer} não vai aceitar o sinistro. Continuamos logo que tenha feito participação do roubo à polícia',
    houseRoberyClaimDescription5: 'Perfeito',
    luishouseDamageClaimDescription: 'Preciso que faça uma breve descrição do que se passou. Seja tão especifico quanto puder',
    luishouseDamageClaimDescription2: 'Não tem problema',
    luisPhoneUthere : 'Parece que não estou a conseguir encontrar a sua apólice',
    luisPhoneUthere2 : 'Ligue por favor para os nossos especialistas. Eles vão ajudá-lo',
    luisPhoneInsurer: 'Infelizmente sem esses dados não consigo ajudar',
    luisPhoneInsurer2: 'Mas posso dar-lhe o número de telefone da {insurer}. Eles vão poder ajudar',
    luisPhoneInsurer3: 'Obrigado. Eles vão poder ajuda',
    luisHouseDamageClaimWhatEquipment: 'Que equipamentos estão avariados ou danificados?',
    luisHouseDamageWhen: 'Quando detectou este problema?',
    luisHouseDamageSeenByTechnician: 'Os equipamentos já foram vistos por algum técnico?',
    luisHouseDamageEquipmentsInHouse: 'Os equipamentos estão em sua casa? ',
    luisHouseDamageEquipmentsInHouse3: 'Não se preocupe, vou passar esta informação à seguradora',
    luisHouseDamageEquipmentsInHouse2: 'Perfeito',
    luisSendParticipation : 'Perfeito. Já tenho tudo o que preciso para participar o sinistro',
    luisConfirmAddress : 'O sinistro foi na sua casa na {addressDescription}?',
    luisConfirmAddresses : 'O sinistro aconteceu em qual das suas casas?',
    luisWillAddCoverage : 'Já está! Lamento não o poder ajudar hoje',
    luisWillNotAddCoverage : 'Claro. Lamento não o poder ajudar hoje',
    luisHasNif : 'Qual é o seu NIF?',
    luisWillPhone1 : 'Olá {name}, vou informar os nossos especialistas que quer participar um sinistro',
    luisWillPhone : 'Eles vão entrar em contacto consigo nos próximos 10 minutos, e ajudá-lo com o seu sinistro',
    luisWillPhoneTommorrow : 'Eles vão entrar em contacto consigo amanhã de amanhã, logo que chegarem aos nossos escritórios, e ajudá-lo com o seu sinistro',
    luisWillPhoneNextWorkingDay : 'Eles vão entrar em contacto consigo na segunda-feira, logo que chegarem aos nossos escritórios, e ajudá-lo com o seu sinistro',
    //USER
    userHouseRoberyDontHaveCoverage : '{houseRoberyDontHaveCoverage}',
    userSendParticipation : 'Enviar participação',
    userChooseClaim: '{chooseClaim}',
    userWantToPhone: '{wantToPhone}',
    userChooseClaimAuto: 'Quero participar um sinistro automóvel',
    userChooseClaimWork: 'Quero participar um sinistro do meu seguro de acidentes de trabalho',
    userChooseClaimPersonal: 'Quero participar um sinistro do meu seguro de acidentes pessoais',
    userChooseClaimResponsability: 'Quero participar um sinistro do meu seguro de responsabilidade civil',
    userChooseClaimAnimals: 'Quero participar um sinistro do meu seguro de animais',
    userChooseClaimHealth: 'Quero participar um sinistro do meu seguro de saúde',
    userChooseClaimHouse: 'Um sinistro casa',
    userChooseClaimLife: 'Quero participar um sinistro do seguro de vida',
    userInsurer: '{insurer}',
    userInsuranceOwner: '{insuranceOwner}',
    userInsuranceOwnerRelation: '{insuranceOwnerRelation}',
    userInsuranceOwnerRelationDescription: '{insuranceOwnerRelationDescription}',
    userInsuranceOwnerRelation1: 'Sou familiar',
    userInsuranceOwnerRelation2: 'Sou amigo',
    userInsuranceOwnerRelation3: 'Sou o filho',
    userInsuranceOwnerRelation4: 'Sou a filha',
    userName: '{name} {familyNames}',
    userNumeroApolice : 'Sim, o número da minha apólice é {insuranceNumber}',
    userNifClaim : 'O meu NIF é o {nif}',
    userInsuranceAtUthere : '{insuranceAtUthere}',
    userHouseClaimType: '{houseClaimType}',
    userHouseClaimType1: 'Tenho danos causados por água',
    userHouseClaimType2: 'Equipamento avariado',
    userHouseClaimType3: 'Roubo',
    userHouseClaimType4: 'Tive um incêndio',
    userHouseClaimType5: 'Outro dano, explico quando fizer a descrição',
    userHouseWaterNeighbor: '{houseWaterNeighbor}',
    userWaterWhen: 'Em {houseWaterWhen}',
    userOtherWhen: 'Em {houseOtherWhen}',
    userHouseWaterAffectedZones: '{houseWaterAffectedZones}',
    userHouseWaterDamagedAssets: '{houseWaterDamagedAssets}',
    userHouseWaterWhatDamagedAssets: '{houseWaterWhatDamagedAssets}',
    userHouseWaterClaimDescription: '{houseWaterClaimDescription}',
    userHouseWaterRepairedDamagedAssets: '{houseWaterRepairedDamagedAssets}',
    userHouseWaterBudget: 'Sim, [toCurrency{houseWaterBudget}]',
    userHouseDamageBudget: 'Sim, [toCurrency{houseDamageBudget}]',
    userHouseWaterHasBudget: '{houseWaterHasBudget}',
    userHouseDamageHasBudget: '{houseDamageHasBudget}',
    userAddressClaim: '{address}',
    userKnowInsuranceNumber1: '{insuranceNumber}',
    userKnowInsuranceNumber0: '{knowInsuranceNumber}',
    userNif: '{nif}',
    userPhone: '{phone}',
    userClaimReview: '<u>Está tudo correcto</u>',
    userTermsOfService: 'Aceito',
    userLogin: '{email}',
    userAddressNeighbors: '{addressNeighbors}',
    userHouseRoberyTakeAnything: '{houseRoberyTakeAnything}',
    userHouseRoberyWhen: '{houseRoberyWhen}',
    userHouseRoberyPolice: '{houseRoberyPolice}',
    userHouseRoberyHasRelatory: '{houseRoberyHasRelatory}',
    userHouseDamageHasRelatory: '{houseDamageHasRelatory}',
    userHouseRoberyClaimDescription: '{houseRoberyClaimDescription}',
    userHouseDamageClaimWhatEquipment: '{houseDamageClaimWhatEquipment}',
    userHouseDamageWhen: '{houseDamageWhen}',
    userHouseDamageSeenByTechnician: '{houseDamageSeenByTechnician}',
    userHouseDamageEquipmentsInHouse: '{houseDamageEquipmentsInHouse}',
    userHouseDamageClaimDescription: '{houseDamageClaimDescription}',
    userOwnerName: '{ownerName} {ownerFamilyNames}',
    userHouseOtherClaimDescription: '{houseOtherClaimDescription}',
    userHouseRoberyHasRelatoryAttach : 'Fiz participação à polícia',
    userAddressConfirm : '{addressConfirm}',
    userHasNif : '{hasNif}',
    //LABELS
    lblSelect: 'Seleccionar',
    luisLoginBegin : 'Por favor insira o seu email',
    lblInsurerName: 'Nome da seguradora',
    lblRadioChooseClaimLife: 'Sinistro Vida',
    lblRadioChooseClaimHouse: 'Sinistro Casa',
    lblRadioChooseClaimCar: 'Carro',
    lblRadioChooseClaimWorkAccidents: 'Acidente de Trabalho',
    lblRadioChooseClaimOther: 'Outro',
    lblInsuranceOwner1: 'Sou',
    lblInsuranceOwner0: 'Não',
    lblInsuranceOwnerRelation1: 'Família',
    lblInsuranceOwnerRelation2: 'Amigo',
    lblInsuranceOwnerRelation3: 'Filho',
    lblInsuranceOwnerRelation4: 'Filha',
    lblInsuranceOwnerRelation5: 'Outro',
    lblName: 'Nome',
    lblHouseClaimType1: 'Dano por água',
    lblHouseClaimType2: 'Equipamento avariado',
    lblHouseClaimType3: 'Roubo',
    lblHouseClaimType4: 'Incêndio',
    lblHouseClaimType5: 'Outro dano',
    lblHouseWaterNeighbor1: 'Foram',
    lblHouseWaterNeighbor2: 'Não foram',
    lblHouseWaterNeighbor3: 'Não sei',
    lblHouseWaterNeighbor4: 'Não tenho vizinhos',
    lblYes: 'Sim',
    lblNo: 'Não',
    lblDescription: 'Descrição',
    lblNif: 'Número de contribuinte',
    lblPhone: 'Telemóvel',
    lblPhoneTo: 'Telefonar para a {insurer}',
    lblAteJa: 'Sair',
    lblExit: 'Sair',
    lblAffectedZones: 'Zonas afectadas',
    lblSendClaim: 'Enviar participação',
    lblSendingClaim : 'A enviar a sua participação para a {insurer}',
    lblEquipments: 'Equipamentos',
    lblRelatoryDamage: 'Relatório',
    lblPoliceReport : 'Relatório polícia',
    lblDonePoliceReport : 'Fiz participação à polícia',
    lblYesNif : 'NIF',
    lblYesApolice : 'Número da apólice',
    systemParticipacaoEnviada : 'Participação enviada'
}