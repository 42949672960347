
import { lang } from './SimulationLang';
import { autoLang } from './auto/SimulationAutoLang';
import { creditBuyFlow } from './life/credit/SimulationCreditBuyConfig';
import { creditTransferFlow } from './life/credit/SimulationCreditTransferConfig';
import { lifeFlow } from './life/SimulationLifeConfig';
import { autoFlow } from './auto/SimulationAutoConfig';
import { homeFlow } from './home/SimulationHomeConfig';
import { homeFlowB } from './home/SimulationHomeConfigB';
import { lifeFamilyFlow } from './life/family/SimulationLifeFamilyConfig';
import { lifeCreditFlow } from './life/SimulationLifeCreditFlow';
import { getVwoGroup } from '../../services/VwoServices';
import lara from '../../assets/lara/lara.png'



export function getFlow(branchInfo) {
    let version = getVwoGroup();
    if (flows[branchInfo.branch][branchInfo.subBranch][version]) {
        return flows[branchInfo.branch][branchInfo.subBranch][version];
    }
    else {
        return flows[branchInfo.branch][branchInfo.subBranch][1];
    }

}
export function getLang(branchInfo) {
    if (branchInfo && branchInfo.branch == 4) {
        return autoLang;
    }
    else {
        return lang;
    }

}

export function getAvatar() {
    return {
        img: lara,
        alt: "Lara",
        name: "Lara",
        desc: 'Assistente digital',
        header: "Olá, eu sou a Lara",
        subtitle: "Sou a sua <span className='u-fw-600'>assistente digital</span>, com uma voz e um coração humano💙<br/><br/>" +
            "Não se preocupe com chamadas para vendas de produtos. Aqui você <span className='u-fw-600'>simula e vê o seu preço de imediato.</span> Tudo é 100% digital!",
    };
}

const baseFlow = {
    begin: 'chooseInsurance',
    laraIntroMessage: 'laraIntroMessage',
    pages: {
        chooseInsurance: {
            allowBack: false,
            type: 'chooseInsurance',
            laraMessage: ['laraWhatInsurance'],
            desktopMessage: ['laraIntroMessageDesktop'],
            nextPageId: {
                sequence: [
                    {
                        result: 'continue',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH',

                            }
                        ],
                    },
                    {
                        result: 'chooseInsuranceLife',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseInsurance',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'plate',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseInsurance',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'postal',
                    }]
            },
            userMessage: {
                sequence:
                    [{
                        result: 'userChooseBaseInsuranceLife',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseInsurance',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userChooseBaseInsuranceAuto',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseInsurance',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userChooseBaseInsuranceHouse',
                    }]
            },
            options: {
                loader: {
                    time: 1000
                },
                valuesBranchMapping: {
                    1: { branch: 1, subBranch: 0 },
                    2: { branch: 3, subBranch: 0 },
                    3: { branch: 4, subBranch: 0 },
                },
                items: [
                    {
                        value: 1,
                        label: 'lblRadioChooseInsuranceBase1',

                    },
                    {
                        value: 2,
                        label: 'lblRadioChooseInsuranceBase2',

                    },
                    {
                        value: 3,
                        label: 'lblRadioChooseInsuranceBase3',

                    },
                ]
            }
        },
        dummy: {},
        dummy2: {},
        dummy3: {},
        dummy4: {},
        dummy5: {},
        dummy6: {},
        dummy7: {},
        dummy8: {},
        dummy9: {},
        dummy10: {},
        dummy11: {},
        dummy12: {},
        dummy13: {},
        dummy14: {},
        dummy15: {},
        dummy16: {},
        dummy17: {},
        dummy18: {},
        dummy19: {},
        dummy20: {},
        dummy21: {},
        dummy22: {},
        dummy23: {},
        dummy24: {},
        dummy25: {},
        dummy26: {},
        dummy27: {},
        dummy28: {},
        dummy29: {},
        dummy30: {},
    }
};
const flows = {
    0: { 0: { 1: baseFlow } },
    1: {
        0: { 1: lifeFlow },
        1: { 1: lifeFamilyFlow },
    },
    2: {
        0: { 1: lifeCreditFlow },
        1: { 1: creditBuyFlow },
        2: { 1: creditTransferFlow }
    },
    3: {
        0: { 1: homeFlow, 2: homeFlowB },
    },
    4: {
        0: { 1 : autoFlow},

    }
}