/**
 * * Inicializa o firebase com as devidas configurações de ambiente,
 * assim como o listener para o estado do utilizador
 */

let app = null;
let auth = null; 
let db = null;
let storage = null;
let functions = null;
let analytics = null;
let loaded = false;

export async function initFirebase() {
    return new Promise(async function (resolve, reject) {
        if (!loaded) {
            loaded = true;
            let firebase = await import("firebase/app");
            if (process.env.NODE_ENV === "development") {
                app = firebase.initializeApp(JSON.parse(process.env.REACT_APP_TITLE_FIREBASE_CONFIG));
            }
            else {
                let response = await fetch('/__/firebase/init.json');
                app = firebase.initializeApp(await response.json());
            }
            let analyticsImp = await import("firebase/analytics");
            analyticsImp.setConsent({
                analytics_storage: "granted",
                ad_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied"
            });
            analytics = analyticsImp.getAnalytics(app);
            try {
                let analyticsLoadTime = new Date().getTime() - window._INITIAL_LOADING_TIMESTAMP;
                let sessionValues = {
                    u_analytics_load_time: analyticsLoadTime
                }
                analyticsImp.setUserProperties(analytics, sessionValues);

            } catch (e) {
            }
            import("../services/AnalyticsServices").then(analyticsServices => {
                analyticsServices.initBasicAnalytics().then().catch();
            }).catch();

            import("firebase/auth").then(authImp => {
                auth = authImp.getAuth();
                authImp.onAuthStateChanged(auth, function (user) {
                    if (user) {
                        import("../store/store").then(storeImpl => {
                            storeImpl.store.dispatch({ type: 'user/setLogged', payload: { isUthereUser: !user.isAnonymous } });
                        });
                        import("./UthereFirebaseListeners").then(listImp => {
                            listImp.initializeListeners(user.uid);
                        });
                        if (user.email) {
                            import("../logic/cookies/CookiesLogic").then(CookiesLogicImp => {
                                CookiesLogicImp.setTermsAccepted();
                            });
                        }
                    }
                    else {
                        import("../store/store").then(storeImpl => {
                            storeImpl.store.dispatch({ type: 'user/removeData' });
                            storeImpl.store.dispatch({ type: 'processes/removeData' });
                        });
                    }
                });
            });

        }
    });
}
function getDb() {
    return new Promise(async function (resolve, reject) {
        if (!db) {
            import("firebase/firestore").then(firestoreImp => {
                db = firestoreImp.getFirestore(app);
                resolve(db);
            });
        }
        else {
            resolve(db);
        }
    });
};
function getStorage() {
    return new Promise(async function (resolve, reject) {
        if (!storage) {
            import("firebase/storage").then(storageImp => {
                storage = storageImp.getStorage(app);
                resolve(storage);
            });
        }
        else {
            resolve(storage);
        }
    });
};
function getFunctions() {
    return new Promise(async function (resolve, reject) {
        if (!functions) {
            import("firebase/functions").then(functionsImp => {
                functions = functionsImp.getFunctions(app, process.env.REACT_APP_FUNCTIONS_REGION);
                if (window.location.hostname === "localhost") {
                    functionsImp.connectFunctionsEmulator(functions, "localhost", 5001);
                }
                resolve(functions);
            });
        }
        else {
            resolve(functions);
        }
    });
}
export { getDb, auth, getFunctions, getStorage, analytics };
