import { initFirebase } from './firebase/UthereFirebase';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/home/Home';


/**
 * ROUTER WATCHER
 */
const RouteWatcher = lazy(() => import('./hooks/RouteWatcher'))

/**COOKIES */
const Cookies = lazy(() => import('./components/cookies/Cookies'));
/**SCROLL TOP */
const ScrollToTop = lazy(() => import('./hooks/ScrollToTop'));

/*PAGES */
const HomeHome = lazy(() => import('./pages/home-home/HomeHome'));
const HomeAuto = lazy(() => import('./pages/home-auto/HomeAuto'));
const Simulation = lazy(() => import('./pages/simulation/Simulation'));
const Claim = lazy(() => import('./pages/claim/Claim'));
const Legal = lazy(() => import('./pages/legal/Legal'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Login = lazy(() => import('./pages/login/Login'));
const InsuranceDetail = lazy(() => import('./pages/insurance-detail/InsuranceDetail'));
const InsuranceList = lazy(() => import('./pages/insurance-list/InsuranceList'));
const Mensagens = lazy(() => import('./pages/mensagens/Mensagens'));
const CancelEmails = lazy(() => import('./pages/cancel-emails/CancelEmails'));
const CancelInsurance = lazy(() => import('./pages/cancel-insurance/CancelInsurance'));
const Dev =
  process.env.NODE_ENV === "development" ?
    lazy(() => import('./pages/dev/Dev')) : null;

function App() {

  //Init firebase
  initFirebase();
  const pathname = window.location.pathname;
  return (
    <div>
      <Suspense fallback={null}>
        <Router>
          <ScrollToTop />
          <RouteWatcher />
          <div className="route-container">
            <Routes>
              <Route path='/casa' element={<HomeHome />} />

              <Route path='/fale-connosco' element={<Contact />} />
              <Route path='/vida-credito' element={<Home />} />
              <Route path='/vida' element={<Home />} />
              <Route path='/casa' element={<HomeHome />} />
              <Route path='/auto' element={<HomeAuto />} />
              <Route path='/simulacao' element={<Simulation />} />
              <Route path='/apolice' element={<InsuranceDetail />} />
              <Route path='/apolices' element={<InsuranceList />} />
              <Route path='/participacao-sinistro' element={<Claim />} />
              <Route path='/login' element={<Login />} />
              <Route path='/aspectos-legais' element={<Legal />} />
              <Route path='/cancelamento-seguro' element={<CancelInsurance />} />
              <Route path='/notificacoes' element={<Mensagens />} />
              <Route path='/cancelamento-emails' element={<CancelEmails />} />
              {process.env.NODE_ENV === "development" ?
                <Route path='/dev' element={<Dev />} />
                : null
              }
              {pathname === "/vida" || pathname === "/vida-credito" ?
                <Route path="*" element={<Home />} /> :
                pathname === "/casa" ?
                  <Route path="*" element={<HomeHome />} /> :
                  <Route path='*' element={<HomeAuto />} />
              }
            </Routes>
          </div>
          {pathname === "/cancelamento-seguro" ? null : <Cookies />}
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
