export function formatCurrency(value, digits) {
    let floatValue = parseFloat(value);
    let formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: digits === 0 || digits ? digits : 2
    })
    let temp = formatter.format(floatValue);
    return temp.replace(/\s/g, "");
}

export function formatNumber(value, digits) {
    let floatValue = parseFloat(value);
    let formatter = new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: digits === 0 || digits ? digits : 2
    })
    let temp = formatter.format(floatValue);
    return temp.replace(/\s/g, "");
}

export function formatIbanToNib(iban, hide) {
    let ibanToReturn = "PT50 " +  formatNibSpaces(iban);
    if (iban) {

        if(hide)
        {
            let hiddenIban = " ";
            for(let i=0; i< ibanToReturn.length; i++)
            {
                if(i > 14 && i < 24)
                {
                    hiddenIban += " • "; 
                }
                else
                {
                    hiddenIban +=  ibanToReturn[i];
                }
            }
            return hiddenIban;
        }

        return ibanToReturn;
    }
    else {
        return null;
    }
}


export function formatNib(nib, hide) {
    if (nib) {
        nib.replace(/\D/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim();
        return nib;
    }
    else {
        return null;
    }
}


function formatNibSpaces(target) {
    let valueToReturn = target;
    let realLength = target.replace("PT50", '').replace(/\s/g, "").length;
    if (realLength <= 21) {
        valueToReturn = target
            .replace("PT50", '')
            .replace(/\D/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim();
    }


    return valueToReturn;
};

export function setPortuguesStringFormatToFloat(inputString) {
    let valueToReturn = 0;
    let tmpString = "";
    if(inputString)
    {
        tmpString = inputString.replaceAll(" ", "");
        tmpString = tmpString.replaceAll("€", ""); 
        tmpString = tmpString.replaceAll(".", "");
        tmpString = tmpString.replace(",", "."); 
        try{
            valueToReturn = parseFloat(tmpString);    
        }catch(e){}
    }
    return valueToReturn;
}
