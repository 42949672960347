import './Home.scss';
import './../../css/Common.scss'
import '../../index.scss';
import '../../App.scss';

import { useEffect, lazy} from 'react';
import { initPage } from '../../logic/pages/PagesLogic';
import WebFont from 'webfontloader';
import HomeLife from '../../components/home-life/HomeLife';

const UthereScrollTop = lazy(() => import('../../components/common/uthere-scroll-top/UthereScrollTop'));
const UthereFooter = lazy(() => import('../../components/footer/Footer'));


/**
 * Home uthere
 */
function Home() {

  useEffect(() => {
    WebFont.load({
        custom: {
            families: ['Montserrat'],
        },
    });
    import("../../services/AnalyticsServices").then(async analyticsImpl => {
        analyticsImpl.setLoadedApp("homeLife");
    });

    initPage('home');
}, []);


  return (
    <div>
      <HomeLife />
      <UthereFooter />
      <UthereScrollTop />
    </div>
  )
};
export default Home;
