import './HomeLife.scss';

import { useNavigate, useLocation } from "react-router-dom";
import HomeHeader from '../new-home/header/HomeHeader';
import { useEffect, useState } from 'react';
import HomeMain from '../new-home/main/HomeMain';
import HomeFeatures from '../new-home/features/HomeFeatures';
import HomeHowTo from '../new-home/how-to/HomeHowTo';
import HomeAboutUthere from '../new-home/about-uthere/HomeAboutUthere';
import HomePartners from '../new-home/partners/HomePartners';
import HomeTestimony from '../new-home/testimony/HomeTestimony';
import HomeReviews from '../new-home/reviews/HomeReviews';
import HomeFaqs from '../new-home/faqs/HomeFaqs';
import HomeOtherInsurance from '../new-home/other-insurance/HomeOtherInsurance';

import { setEventScroll } from '../../services/AnalyticsServices';

/*
* HomeHeader
*/
function HomeLife() {
    const location = useLocation();
    const navigate = useNavigate();
    const [percentagesRecorded, setPercentagesRecorded] = useState({
        10: false,
        20: false,
        30: false,
        40: false,
        50: false,
        60: false,
        70: false,
        80: false,
        90: false,
        100: false,
    });

    useEffect(() => {
        document.body.addEventListener("scroll", setScrollEvents, true);
        return () => {
            document.body.removeEventListener('scroll', setScrollEvents, true);
        }
    }, []);

    const goToSimulation = () => {
        import("../../services/AnalyticsServices").then(async analyticsServicsImpl => {
            analyticsServicsImpl.setEventCTASelected(
                "Simular agora",
                'CTA Principal', 1, 1, 0, location.pathname
            )
        })
        navigate('/simulacao?branch=1')
    }

    const setScrollEvents = () => {
        setPercentagesRecorded(previousState => {
            let percent = (document.body.scrollTop / document.body.scrollHeight) * 100;
            let nearest = Math.round(percent / 10) * 10;
            if (nearest && !previousState[nearest]) {
                previousState[nearest] = true;
                try {
                    setEventScroll(location.pathname, nearest);
                } catch (e) { }
            }
            return {
                ...previousState,
            };
        })
    }

    return (
        <div className='home-life-container'>
            <HomeHeader simular={true} branch={1} />
            <HomeMain branch={1} goToSimulation={goToSimulation} />
            <HomeFeatures branch={1} />
            <HomeHowTo branch={1} />
            <HomeAboutUthere branch={1} />
            <HomePartners />
            <HomeTestimony branch={1} />
            <HomeFaqs branch={1} />
            <HomeReviews />
            <HomeOtherInsurance branch={1} />
        </div>
    );
};
export default HomeLife;
