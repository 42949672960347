import './HomeMain.scss';
import Button from '../button/Button';
import useNotifications from '../../../hooks/useNotifications';
import HomeNotifications from '../../home/home-notifications/HomeNotifications';

/**
* HomeHeader
*/
function HomeMain(props) {
    const notifications = useNotifications();
    const buttonProps = {
        label: "Simular agora"
    }
    return (
        <div
            className={`home-main-container
            ${props.branch === 4 ? "home-main-container-auto" : null}
            ${props.branch === 1 ? "home-main-container-life" : null}`}>
            {notifications && notifications.length > 0 ? <div className='home-main-notifications-container'>
                <HomeNotifications hideMore={false} /> :
            </div> : null}
            <div className='home-main-container-text'>
                <div className='home-main-container-text-main'>
                    {props.branch === 1 ?
                        <h1>Liberdade para escolher o melhor <span className='bold'>Seguro de Vida</span></h1> :
                        props.branch === 3 ? <h1>Descubra o melhor <span className='bold'>Seguro Multirrisco Habitação</span></h1> :
                        <h1>Está à procura de um<br/><span className='bold'>Seguro Auto mais barato?</span></h1>}
                </div>
                <div className='home-main-container-text-sec'>
                     {props.branch === 4 ? <h6>Simule e compre o seu<br/>Seguro Auto em minutos!</h6>
                        : <h6>Para todos os que acham os seguros<br/> caros, complexos e complicados</h6>}
                </div>
                <Button onClick={() => props.goToSimulation("Simular agora")} {...buttonProps} />
            </div>
            <div
                className={`
            ${props.branch === 1 ? "life-main-container-banner" : props.branch === 3 ? "home-main-container-banner" : "auto-main-container-banner"}`}></div>
        </div>
    );
};
export default HomeMain;