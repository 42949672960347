import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    logged: false,
    isUthereUser : false,
    email : null,
    personalData: {},
    embaixador: null,
  },
  reducers: {
    setData: (state, action) => {
      if (action.payload.embaixador) {
        state.embaixador = action.payload.embaixador;
      }
      if(action.payload.email)
      {
        state.email = action.payload.email;
      }
      if (action.payload.personalData) {
        state.personalData = action.payload.personalData;
      }
    },
    setLogged: (state, action) => {
      if(action.payload && action.payload.isUthereUser)
      {
        state.isUthereUser = true;
      }
      state.logged = true;
    },
    removeData: (state) => {
      state.logged = false;
      state.personalData = {};
    },
  },
});
export const { setData, removeData } = userSlice.actions;

export default userSlice.reducer;