/**
 * 
 * @returns 
 */
export function generateUid() {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + S4() + S4() + S4() + S4() + S4());
}
/**
 * 
 * @param {*} ms 
 * @returns 
 */
export function setTimeoutWait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function blockScroll() {
    document.body.classList.add("stop-scrolling");
}

export function unblockScroll() {
    document.body.classList.remove("stop-scrolling");
}

/**
 * 
 * @param {*} object 
 * @returns 
 */
export function cloneObject(object) {
    try {
        if (object)
            return JSON.parse(JSON.stringify(object));
        return null;
    } catch (e) { return null }
};

export function truncateIban(original) {
    let valueToReturn = original;
    if (original && original.length === 25) {
        valueToReturn = original.substring(0, 4) + " •••• " + original.substring(21, 25);
    }
    return valueToReturn;
}

/**
 * 
 * @param {*} object 
 * @returns 
 */
export function getNumberOfTrueKeys(object, from) {
    let objectToReturn = {
        numberOfTrueKeys: 0,
        keys: [],
    }
    if (object) {
        let keys = Object.keys(object);
        for (let i = 0; i < keys.length; i++) {
            if (from) {
                for (let j = 0; j < from.length; j++) {
                    if (from[j] === keys[i] && object[keys[i]]) {
                        objectToReturn.keys.push(keys[i]);
                        objectToReturn.numberOfTrueKeys += 1;
                        break;
                    }
                }
            }
            else {
                if (object[keys[i]]) {
                    objectToReturn.keys.push(keys[i]);
                    objectToReturn.numberOfTrueKeys += 1;
                }
            }
        }
    }

    return objectToReturn;
}


export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    let toReturn = Math.floor(Math.random() * (max - min) + min);
    return toReturn; // The maximum is exclusive and the minimum is inclusive
}

export function getEstimatedUserVisits() {
    const expectedWorstDay = 180;
    let today = new Date();
    let hours = today.getHours();
    const factor = hours < 7 ? 1 : hours < 11 ? 1.1 : hours < 13 ? 0.9 : hours < 17 ? 1.2 : hours < 21 ? 1.3 :
        hours < 23 ? 1.4 : 1.5;
    let result = Math.round(expectedWorstDay * factor);
    return result;

}