import './HomeReviews.scss';
import 'swiper/css';
import "swiper/css/pagination";
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Pagination, Navigation } from "swiper";

import { isTouch } from '../../../utils/DeviceUtils';


import Beatriz from '../../../assets/home/reviews/beatriz-campos.png';
import Jorge from '../../../assets/home/reviews/jorge-candido.png';
import Maria from '../../../assets/home/reviews/maria-beatriz-louro.png';
import Ricardo from '../../../assets/home/reviews/ricardo-paiagua.png';
import Alexander from '../../../assets/home/reviews/alexander-pattenden.png';
import Pedro from '../../../assets/home/reviews/pedro-branco.png';
import Joao from '../../../assets/home/reviews/joao-miguel.png';
import Aurora from '../../../assets/home/reviews/aurora-costa.png';
import Paula from '../../../assets/home/reviews/paula-mendonca.png';
import ecoSeguros from '../../../assets/home/reviews/ecoseguros.png';
import antena2 from '../../../assets/home/reviews/antena2.png';


import JorgeS from '../../../assets/signatures/jorge.svg';
import PaulaS from '../../../assets/signatures/paula.svg';
import AlexanderS from '../../../assets/signatures/alexander.svg';
import RicardoS from '../../../assets/signatures/ricardo.svg';
import PedroS from '../../../assets/signatures/pedro.svg';
import AuroraS from '../../../assets/signatures/aurora.svg';
import JoaoS from '../../../assets/signatures/joao.svg';
import MariaS from '../../../assets/signatures/maria.svg';
import BeatrizS from '../../../assets/signatures/beatriz.svg';

const reviewsToShow = {
    0 : true,
    1 : false,
    2 : false,
    3 : true,
    4 : true,
    5 : false,
    6 : true,
    7 : true,
    8 : false,
    9 : true,
    10 : true,
}

const reviews =
    [
        {
            id: "9",
            name: "Eco Seguros",
            signature: null,
            imgpng: ecoSeguros,
            text: 'Um projecto bem pensado',
        },
        {
            id: "10",
            name: "Radio Antena 2",
            signature: null,
            imgpng: antena2,
            text: 'Uma fintech de seguros... que se coloca ao lado do consumidor',
        },
        {
            id: "5",
            name: "Alexander",
            signature: AlexanderS,
            imgpng: Alexander,
            text: 'Excelente guia para quem percebe pouco (ou nada) de seguros ...simples de utilizar e ótimo para poupar dinheiro</span>...',
        },
        {
            id: "4",
            name: "Paula",
            signature: PaulaS,
            imgpng: Paula,
            text: 'Esta foi a melhor experiência que tive na compra de um seguro. Muito fácil, rápido e um preço fantástico.',
        },
        {
            id: "6",
            name: "Pedro",
            signature: PedroS,
            imgpng: Pedro,
            text: 'Encontrei a uthere por acaso. Muito boa experiência! Fácil de navegar e informações confiáveis...',
        },
        {
            id: "7",
            name: "Aurora",
            signature: AuroraS,
            imgpng: Aurora,
            text: 'Tive uma situação em que estava indecisa sobre o que fazer. Espreitei a uthere. Excedeu as expetativas ...',
        },
        {
            id: "8",
            name: "Joao",
            signature: JoaoS,
            imgpng: Joao,
            text: '...muito prática, eficiente, intuitiva e com oferta de apólices vasta...',
        },
        {
            id: "0",
            name: "Jorge",
            signature: JorgeS,
            imgpng: Jorge,
            text: 'Fácil, intuitivo e muito útil. Excelente oferta quando o custo dos seguros deve ser uma preocupação para todas as famílias....',
        },
        {
            id: "1",
            name: "Ricardo",
            signature: RicardoS,
            imgpng: Ricardo,
            text:
                'Foi tudo tão fácil e rápido. Foi a 1º vez que gostei de falar sobre seguros...',
        },
        {
            id: "2",
            name: "Beatriz",
            signature: MariaS,
            imgpng: Maria,
            text: 'Posso dizer que adorei? Obrigada uthere',
        },
        {
            id: "3",
            name: "Beatriz",
            signature: BeatrizS,
            imgpng: Beatriz,
            text: 'A uthere deu-me a  confiança para comprar um seguro de Vida...',
        },
    ];

/**
* HomeReviews
*/
function HomeReviews(props) {

    const swiperProps = {
        allowTouchMove: true,
        initialSlide: 4,
        loop: true,
        speed: 800,
        slidesPerView: "auto",
        spaceBetween: 24,
        centeredSlides: true,
        breakpoints: {
            576: {
            },
            768: {
                spaceBetween: 24,
            },
            992: {
                spaceBetween: 32,
            },
            1200: {
                spaceBetween: 48,
            },
        },
        pagination: {
            clickable: true,
            el: '#otherInsuranceSwiperPagination',
            renderBullet: (index, className) => {
                return '<span' +
                    ' class="proposal-home-swiper-pagination-bullet ' + className + '"></span>';
            }
        },
        navigation: {
            prevEl: '#reviewsPaginationPrevious',
            nextEl: '#reviewsPaginationNext',
        },
    }
    return (
        <div className={`
        ${!props.proposal ? "reviews-container" : "reviews-container-proposal"}`} >
            <div className='home-title-container home-title-review-container'>
                {!props.proposal ? <h2>O que <span className='bold'>dizem sobre nós</span></h2> : null}
                {!props.proposal ? <h6>Não nos queremos gabar, mas os nossos clientes gostam mesmo de nós</h6> : null}
                <div className='reviews-container-container-score' >
                    <svg className='reviews-container-container-score-stars' width="auto" height="auto" viewBox="0 0 148 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2818 3.17772C14.7646 2.08919 16.3094 2.08919 16.7921 3.17772L19.5622 9.42351C19.7578 9.86454 20.1706 10.1705 20.6494 10.2296L27.3059 11.0504C28.4533 11.1919 28.923 12.6014 28.0897 13.4028L23.0967 18.2049C22.762 18.5268 22.6127 18.996 22.6999 19.4522L24.0141 26.331C24.2352 27.4879 22.9925 28.3679 21.9746 27.7753L16.2278 24.4298C15.8008 24.1813 15.2732 24.1813 14.8462 24.4298L9.09932 27.7753C8.08139 28.3679 6.83878 27.4879 7.05982 26.331L8.37407 19.4522C8.46121 18.996 8.3119 18.5268 7.97719 18.2049L2.98424 13.4028C2.15097 12.6014 2.62059 11.1919 3.76801 11.0504L10.4246 10.2296C10.9034 10.1705 11.3161 9.86454 11.5117 9.42351L14.2818 3.17772Z" fill="#F4BE4A" />
                        <path d="M72.1334 3.17772C72.6161 2.08919 74.1609 2.08919 74.6437 3.17772L77.4138 9.42351C77.6094 9.86454 78.0221 10.1705 78.5009 10.2296L85.1575 11.0504C86.3049 11.1919 86.7745 12.6014 85.9413 13.4028L80.9483 18.2049C80.6136 18.5268 80.4643 18.996 80.5514 19.4522L81.8657 26.331C82.0867 27.4879 80.8441 28.3679 79.8262 27.7753L74.0793 24.4298C73.6523 24.1813 73.1247 24.1813 72.6977 24.4298L66.9509 27.7753C65.933 28.3679 64.6903 27.4879 64.9114 26.331L66.2256 19.4522C66.3128 18.996 66.1635 18.5268 65.8288 18.2049L60.8358 13.4028C60.0025 12.6014 60.4722 11.1919 61.6196 11.0504L68.2761 10.2296C68.7549 10.1705 69.1677 9.86454 69.3633 9.42351L72.1334 3.17772Z" fill="#F4BE4A" />
                        <path d="M43.8228 3.17772C44.3056 2.08919 45.8504 2.08919 46.3332 3.17772L49.1033 9.42351C49.2989 9.86454 49.7116 10.1705 50.1904 10.2296L56.8469 11.0504C57.9944 11.1919 58.464 12.6014 57.6307 13.4028L52.6378 18.2049C52.3031 18.5268 52.1537 18.996 52.2409 19.4522L53.5551 26.331C53.7762 27.4879 52.5336 28.3679 51.5156 27.7753L45.7688 24.4298C45.3418 24.1813 44.8142 24.1813 44.3872 24.4298L38.6403 27.7753C37.6224 28.3679 36.3798 27.4879 36.6008 26.331L37.9151 19.4522C38.0022 18.996 37.8529 18.5268 37.5182 18.2049L32.5253 13.4028C31.692 12.6014 32.1616 11.1919 33.309 11.0504L39.9656 10.2296C40.4444 10.1705 40.8571 9.86454 41.0527 9.42351L43.8228 3.17772Z" fill="#F4BE4A" />
                        <path d="M101.67 3.17772C102.153 2.08919 103.698 2.08919 104.181 3.17772L106.951 9.42351C107.147 9.86454 107.559 10.1705 108.038 10.2296L114.695 11.0504C115.842 11.1919 116.312 12.6014 115.478 13.4028L110.485 18.2049C110.151 18.5268 110.001 18.996 110.089 19.4522L111.403 26.331C111.624 27.4879 110.381 28.3679 109.363 27.7753L103.616 24.4298C103.189 24.1813 102.662 24.1813 102.235 24.4298L96.488 27.7753C95.4701 28.3679 94.2275 27.4879 94.4485 26.331L95.7627 19.4522C95.8499 18.996 95.7006 18.5268 95.3659 18.2049L90.3729 13.4028C89.5396 12.6014 90.0093 11.1919 91.1567 11.0504L97.8132 10.2296C98.2921 10.1705 98.7048 9.86454 98.9004 9.42351L101.67 3.17772Z" fill="#F4BE4A" />
                        <path d="M129.977 3.17772C130.46 2.08919 132.005 2.08919 132.487 3.17772L135.258 9.42351C135.453 9.86454 135.866 10.1705 136.345 10.2296L143.001 11.0504C144.149 11.1919 144.618 12.6014 143.785 13.4028L138.792 18.2049C138.457 18.5268 138.308 18.996 138.395 19.4522L139.709 26.331C139.93 27.4879 138.688 28.3679 137.67 27.7753L131.923 24.4298C131.496 24.1813 130.968 24.1813 130.541 24.4298L124.795 27.7753C123.777 28.3679 122.534 27.4879 122.755 26.331L124.069 19.4522C124.157 18.996 124.007 18.5268 123.673 18.2049L118.68 13.4028C117.846 12.6014 118.316 11.1919 119.463 11.0504L126.12 10.2296C126.599 10.1705 127.011 9.86454 127.207 9.42351L129.977 3.17772Z" fill="#FEEABF" />
                        <mask id="mask0_417_11886" maskUnits="userSpaceOnUse" x="117" y="0" width="21" height="31">
                            <rect x="117.041" y="0.347656" width="20.8" height="30.4" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_417_11886)">
                            <path d="M129.977 3.17772C130.46 2.08919 132.005 2.08919 132.487 3.17772L135.258 9.42351C135.453 9.86454 135.866 10.1705 136.345 10.2296L143.001 11.0504C144.149 11.1919 144.618 12.6014 143.785 13.4028L138.792 18.2049C138.457 18.5268 138.308 18.996 138.395 19.4522L139.709 26.331C139.93 27.4879 138.688 28.3679 137.67 27.7753L131.923 24.4298C131.496 24.1813 130.968 24.1813 130.541 24.4298L124.795 27.7753C123.777 28.3679 122.534 27.4879 122.755 26.331L124.069 19.4522C124.157 18.996 124.007 18.5268 123.673 18.2049L118.68 13.4028C117.846 12.6014 118.316 11.1919 119.463 11.0504L126.12 10.2296C126.599 10.1705 127.011 9.86454 127.207 9.42351L129.977 3.17772Z" fill="#F4BE4A" />
                        </g>
                    </svg>
                    <div className='reviews-container-container-score-google'>
                        4.8
                        <div className='reviews-container-container-score-google-height'>
                            <svg width="auto" height="auto" viewBox="0 0 61 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.65402 1.53223H8.19313C10.0648 1.57335 11.9059 2.33413 13.2283 3.6809C12.74 4.18466 12.2416 4.66786 11.7635 5.17161C11.0209 4.49308 10.1258 3.96876 9.14929 3.78371C7.70488 3.47529 6.14857 3.75287 4.9381 4.58561C3.61575 5.45947 2.72061 6.9399 2.52735 8.52314C2.31374 10.0858 2.75113 11.7307 3.75815 12.9439C4.72449 14.1261 6.19943 14.8664 7.72522 14.9178C9.14929 15 10.6344 14.5579 11.6719 13.5504C12.4857 12.8411 12.8621 11.7616 12.9841 10.7129C11.2956 10.7129 9.60703 10.7232 7.91849 10.7129V8.5951H15.0287C15.3949 10.8671 14.8659 13.4271 13.157 15.0514C12.0178 16.2029 10.4411 16.8814 8.83396 17.015C7.27765 17.1693 5.67048 16.8711 4.2871 16.1103C2.62907 15.2159 1.30671 13.7046 0.655706 11.9261C0.0453885 10.2914 0.0352166 8.44089 0.604846 6.79597C1.12362 5.29498 2.12047 3.96876 3.40214 3.0435C4.63294 2.12851 6.12822 1.62475 7.65402 1.53223Z" fill="#B3B3B3" />
                                <path d="M48.4492 2.06836H50.626V16.7492C49.9038 16.7492 49.1714 16.7595 48.4492 16.739C48.4594 11.8556 48.4492 6.96199 48.4492 2.06836Z" fill="#B3B3B3" />
                                <path d="M19.8719 7.15565C21.2146 6.89863 22.6692 7.18649 23.778 7.99867C24.785 8.71831 25.4868 9.84919 25.7005 11.0829C25.9751 12.5119 25.6293 14.0745 24.7138 15.2054C23.7271 16.4699 22.1098 17.1485 20.5331 17.0457C19.0887 16.9634 17.6849 16.2335 16.8305 15.0409C15.8642 13.725 15.6302 11.9156 16.1591 10.3735C16.6881 8.71831 18.1834 7.45379 19.8719 7.15565ZM20.1771 9.10898C19.6278 9.25291 19.1192 9.57161 18.753 10.024C17.7663 11.2165 17.8274 13.1596 18.9158 14.2699C19.5362 14.9073 20.4721 15.2054 21.3367 15.0306C22.1403 14.8867 22.8421 14.3418 23.2287 13.6222C23.9 12.3988 23.7068 10.7128 22.6794 9.75666C22.0182 9.13982 21.0519 8.8828 20.1771 9.10898Z" fill="#B3B3B3" />
                                <path d="M30.6578 7.15641C32.1938 6.85827 33.8721 7.29006 35.0216 8.37982C36.8932 10.0761 37.0966 13.2529 35.4996 15.2063C34.5333 16.4399 32.9668 17.1185 31.4207 17.0465C29.9457 17.0054 28.4912 16.2652 27.6164 15.0418C26.6297 13.695 26.4161 11.8444 26.9755 10.2715C27.535 8.66768 28.9998 7.44427 30.6578 7.15641ZM30.9629 9.10975C30.4137 9.25368 29.9051 9.57239 29.5389 10.0145C28.5624 11.1865 28.603 13.0987 29.6508 14.2193C30.2712 14.8875 31.2376 15.2165 32.1327 15.0315C32.9261 14.8773 33.6382 14.3427 34.0247 13.623C34.6859 12.3893 34.4926 10.7033 33.4551 9.74716C32.7939 9.13032 31.8276 8.88358 30.9629 9.10975Z" fill="#B3B3B3" />
                                <path d="M39.8022 7.75106C40.9719 7.01084 42.5282 6.80523 43.7896 7.44263C44.1863 7.61741 44.5118 7.91555 44.8271 8.21369C44.8373 7.93611 44.8271 7.64825 44.8373 7.36039C45.5188 7.37067 46.2003 7.36039 46.892 7.37067V16.4383C46.8819 17.8056 46.536 19.2552 45.5595 20.2524C44.4914 21.3525 42.8537 21.6917 41.3788 21.4656C39.8022 21.2291 38.4289 20.0674 37.8186 18.5972C38.4289 18.2991 39.0698 18.0626 39.7004 17.7851C40.0565 18.6281 40.7787 19.3477 41.684 19.5122C42.5893 19.6767 43.637 19.4505 44.227 18.6898C44.8576 17.9084 44.8576 16.8392 44.8271 15.8831C44.3592 16.3457 43.8201 16.757 43.1691 16.9112C41.7552 17.3121 40.1989 16.8187 39.1105 15.8523C38.0119 14.8859 37.3609 13.3952 37.4219 11.9148C37.4524 10.239 38.3883 8.62492 39.8022 7.75106ZM41.9078 9.06699C41.2873 9.1698 40.7075 9.51934 40.3108 10.0025C39.3546 11.154 39.3546 12.9942 40.3209 14.1251C40.8702 14.7934 41.7552 15.1635 42.6096 15.0709C43.4132 14.9887 44.1558 14.4747 44.5525 13.7653C45.2238 12.5624 45.1119 10.9278 44.2066 9.87917C43.6472 9.23148 42.752 8.91278 41.9078 9.06699Z" fill="#B3B3B3" />
                                <path d="M53.2376 8.27691C54.4582 7.12547 56.34 6.7348 57.9065 7.34136C59.3916 7.9068 60.3376 9.33583 60.8157 10.806C58.6084 11.7312 56.4112 12.6462 54.2039 13.5715C54.5091 14.1575 54.977 14.6921 55.6077 14.908C56.4926 15.2267 57.5505 15.1136 58.2931 14.5173C58.5881 14.2911 58.822 13.993 59.0458 13.7051C59.6052 14.0855 60.1647 14.4556 60.7242 14.836C59.9307 16.0389 58.5982 16.8819 57.164 17.0155C55.5771 17.2109 53.8886 16.594 52.8612 15.3398C51.1727 13.3659 51.3354 10.0555 53.2376 8.27691ZM54.326 10.1788C53.9801 10.6826 53.8377 11.2994 53.8479 11.906C55.3228 11.2892 56.7978 10.6723 58.2727 10.0452C58.0286 9.46948 57.4386 9.11993 56.8385 9.0274C55.8721 8.85263 54.8651 9.37695 54.326 10.1788Z" fill="#B3B3B3" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div>
            <div 
             className={`home-reviews-swiper
             ${props.white ? "home-reviews-swiper-white" : null}`}>
                <Swiper modules={[Controller, Pagination, Navigation]}
                    {...swiperProps} >
                    {reviews.map((item, i) => (
                        (!props.proposal && reviewsToShow[item.id] ) || (props.proposal && i < 4) ?
                        <SwiperSlide className='home-reviews-slide' key={i}>
                            <div className={`home-reviews-card`}>
                                <img className='home-reviews-card-avatar'
                                    alt={item.name}
                                    src={item.imgpng}></img>
                                <div className={`home-reviews-card-content`}>
                                    <div className={`home-reviews-card-content-name`}>
                                        {item.name ? parse(item.name) : null}
                                    </div>
                                    <div className={`home-reviews-card-content-review`}>
                                        “{item.text ? parse(item.text) : null}”
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> : null
                    ))}
                </Swiper>
                <div className="home-swiper-pagination home-swiper-navigation">
                    {!isTouch() ?
                        <svg id="reviewsPaginationPrevious" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6668 33.333L13.3335 19.9997L26.6668 6.66634" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> : null}
                    <div className='about-uthere-card-swiper-pagination'>
                        <div className="about-uthere-card-swiper-pagination-bullets" id="otherInsuranceSwiperPagination"></div>
                    </div>
                    {!isTouch() ? <svg id="reviewsPaginationNext" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3332 6.66699L26.6665 20.0003L13.3332 33.3337" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> : null}
                </div>
            </div>
        </div>
    );
};
export default HomeReviews;