import { useEffect, useState } from "react";
import { getNotifications } from "../logic/notification/NotificationsLogic";
import { cloneObject } from "../utils/GeneralUtils";
import {store} from "../store/store";


export default function useNotifications(props) {
    const [notifications, setNotifications] = useState(() => initNotifications(props));

    let unsubscribe = null;

    useEffect(() => {
        let notificationList = cloneObject(store.getState().processes.list);
        let notificationsNewSate = getNotifications(notificationList, props && props.type ? props.type : null, props && props.branch ? props.branch : null, null, props && props.all ? true : false);
        setNotifications(notificationsNewSate);
        unsubscribe = store.subscribe(() => {
            if (store.getState().processes) {
                let notificationsNewSate = getNotifications(store.getState().processes.list, props && props.type ? props.type : null, props && props.branch  ? props.branch : null, null, props && props.all ? true : false);
                setNotifications(notificationsNewSate);
            }
        });
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, []);

    return notifications;
}


function initNotifications(props) {
    let notificationList = cloneObject(store.getState().processes.list);
    let notificationsNewSate = getNotifications(notificationList, 
        props && props.type ? props.type : null, 
        props && props.branch ? props.branch : null);
    return notificationsNewSate;
}

