import { getCookie } from "../logic/cookies/CookiesLogic";


export function getVwoGroup() {
    /*
    let value = getCookie("_vis_opt_exp_22_combi");
    if (value != undefined && value != null) {
        return value;
    }
    else {
        return 1;
    }
    */
   return 1;
}