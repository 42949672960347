import * as SimulationConfig from '../simulation/SimulationConfig';
import * as ClaimConfig from '../claim/ClaimConfig';

export function getAvatar(branchInfo)
{
    if(branchInfo.type === 2)
    {
        return ClaimConfig.getAvatar();
    }
    else
    {
        return SimulationConfig.getAvatar();
    }    
}

export function getFlow(branchInfo) {
    if(branchInfo.type === 2)
    {
        return ClaimConfig.getFlow(branchInfo);
    }
    else
    {
        return SimulationConfig.getFlow(branchInfo);
    }
}

export function getLang(branchInfo) {
    if(branchInfo.type === 2)
    {
        return ClaimConfig.getLang();
    }
    else
    {
        return SimulationConfig.getLang(branchInfo);
    }
}