export const autoLang = {

    /**
     * LARA
     */
    laraAutoType: 'Olá, eu sou a Lara e vou procurar o melhor seguro auto para si. São apenas alguns segundos',
    laraAutoType2: 'Que seguro procura?',
    laraAutoType3 : 'Vamos recomeçar a simulação. Eu vou procurar o melhor seguro para si',
    laraAutoTypeDesktop: 'Olá, eu sou a Lara e vou procurar o melhor seguro auto para si. Que seguro procura?',
    laraIntroMessage: 'Descubra o melhor seguro para si',
    laraAutoPlate: 'Olá, eu sou a Lara e vou procurar o melhor seguro para si. São apenas alguns segundos',
    laraAutoPlate2: 'Vamos começar pela matricula do seu carro',
    laraAutoContinue: 'Eu vou procurar o melhor seguro para si. Vamos  a isso?',
    laraAutoContinue2: 'Quer continuar a sua simulação ou começar uma nova?',
    laraCarConfirm1: 'Óptimo!',
    laraCarConfirm2: 'Deixe-me adivinhar. O seu carro é um {carDescritpion}. Acertei? 😀',
    laraCarConfirm3: 'Deixe-me adivinhar. É um {carDescritpion}. Acertei? 😀',
    laraAutoContinueDesktop: 'Olá, eu sou a Lara, já tem uma uma simulação activa. Quer continuar a sua simulação ou começar uma nova?',
    laraIKnewIt: 'Eu sabia! 😀',
    laraAutoCarVersion: 'Qual a versão do seu {brand}?',
    laraAutoCarVersion2: 'Qual a versão do {brand}?',
    laraAutoCarVersion3: 'Eu sabia! 😀 Qual a versão do {brand}?',
    laraAutoCarExtras: 'O seu {brand} tem reboque para atrelado?',
    laraAutoCarExtras2 : 'O {brand} tem reboque para atrelado?',
    laraAutoCarOwner: 'É o proprietário?',
    laraUsualDriver: 'É o condutor habitual?',
    laraUsualDriver2: 'O condutor habitual é a pessoa que normalmente conduz o carro',
    laraUsualDriver3: 'Outras pessoas também podem conduzir o carro, desde que ocasionalmente. E também estão protegidas pelo seguro',
    laraNoCarUse1 : 'Infelizmente a uthere ainda mão comercializa seguros para taxis ou TVDE',
    laraNoCarUse2 :'Infelizmente a uthere ainda não comercializa seguros para carros para transporte de mercadoria',
    laraCantShowProposalNotCarOwner: 'Infelizmente só consigo apresentar uma proposta se for o proprietário do carro',
    laraContactUserNotCar: 'Opps! Parece que é um pouco mais complicado do que pensei',
    laraContactUserNotCar2: 'Um dos meus colegas vai entrar em contacto consigo. Qual é o seu e-mail?',
    laraContactUserNotCarDesktop: 'Opps! Parece que é um pouco mais complicado do que pensei',
    laraContactUserNotCarDesktopHelper : 'Deixe-me o seu contacto. Um dos nossos especialistas irá contactá-lo',
    laraEndContactUserNotCar: 'Obrigada. Já enviei o seu contacto para a nossa equipa',
    laraLicenseDate0: 'E em que data o condutor habitual tirou a sua carta de condução?',
    laraLicenseDate1: 'Em que data tirou a carta de condução? Pode obter esta informação no campo 9 B, no verso da carta de condução',
    laraLicenseDate2: 'E em que data tirou a sua carta de condução?',
    laraLicenseDate3: 'Pode obter esta informação no campo 9 B, no verso da sua carta de condução',
    laraAddress0: 'Qual é a  morada do condutor habitual?',
    laraAddress1: 'Vamos começar pelo código de postal',
    laraAddress2: 'Qual é a sua  morada?',
    laraAddress3: 'Vamos começar pelo seu código postal',
    laraAddressDesktop: 'Qual é a morada do condutor habitual? Vamos começar pelo código postal',
    laraAddressDesktop2: 'Qual é a sua morada? Vamos começar pelo código postal',
    laraAddressDesktop3 : 'Qual é a  morada da sede social da empresa? Vamos começar pelo código postal',
    laraAddress6 : 'Qual é a  morada da sede social da empresa?',
    laraNif0: 'Já tenho quase tudo',
    laraNif1: 'Óptimo! Qual é o NIF do condutor habitual?',
    laraNif2: 'Óptimo! Qual é o seu NIF?',
    laraNif3: 'Já tenho quase tudo. Qual é o NIF do condutor habitual?',
    laraNif4: 'Já tenho quase tudo. Qual é o seu NIF?',
    laraNif5: 'Preciso desta informação para encontrar o melhor preço possível para si',
    laraNif6: 'Qual é o NIF da empresa?',
    laraNotOwnerNoDriver : 'Infelizmente não vou poder fazer a simulação. Preciso que seja o proprietário ou o condutor habitual',
    laraCarUse0: 'Perfeito!',
    laraCarUse1: 'Utiliza o seu carro apenas para uso particular?',
    laraCarUse3: 'Não tem problema, mas para simular o seguro vou precisar das informações do condutor habitual',
    laraCarUse2: 'O carro é utilizado a título pessoal ou para actividades comerciais? Por exemplo, para transporte de pessoas ou materiais',
    laraCarUse4: 'Utiliza o carro apenas para uso particular?',
    laraCarUse5 : 'O condutor habitual utiliza o carro apenas para uso particular?',
    laraCarUseDesktopNoUsualDriver : 'Não tem problema, Vou precisar das informações do condutor habitual. Utiliza o carro apenas para uso particular?',
    laraCarUseDesktop : 'Perfeito! Utiliza o seu carro apenas para uso particular?',
    laraCarUseDesktop2 : 'Perfeito! Utiliza o carro apenas para uso particular?',
    laraNifHelper : 'Preciso desta informação para encontrar o melhor preço possível para si',
    bornDate0: 'Já tenho quase tudo',
    bornDate1: 'Qual é a sua data de nascimento?',
    bornDate2: 'Qual é a data de nascimento do condutor habitual?',

    bornDateDesktop1: 'Já tenho quase tudo. Qual é a sua data de nascimento?',
    bornDateDesktop2: 'Já tenho quase tudo. Qual é a data de nascimento do condutor habitual?',
    laraGenerateProposal: 'Obrigada. Vou analisar os seus dados e procurar a melhor proposta para si',
    laraGenerateProposalOther : 'Obrigada. Vou analisar os seus dados e procurar a melhor proposta',
    laraHappyBirthday: 'Parabéns! 🎉🍾',
    laraAlmostBirthday: 'Uau. Está quase a fazer anos 😀',
    laraGeneratePersonalDesktop: 'Vou emitir a sua proposta. São apenas alguns segundos',
    laraProposalShow0: 'Pronto para ver a sua proposta?',
    laraProposalShow1: 'Se escolher a nossa proposta eu trato de tudo, e informo a sua seguradora actual. Não tem que fazer nada',
    
    laraFinalBeforeProposal : 'Não consigo encontrar informação sobre a sua experiência de condução',
    laraFinalBeforeProposal1 : 'Não consigo encontrar informação sobre carros em nome da empresa',
    laraFinalBeforeProposal2 : 'Não teve nenhum sinistro nos último anos. Parabéns! 👏',
    laraFinalBeforeProposal3 : 'Só teve 1 sinistro nos último anos. Óptimo!',
    laraFinalBeforeProposal4 : 'Vejo que teve mais do que 1 sinistro nos último anos',
    laraFinalBeforeProposal5 : 'Os carros da empresa não tiveram nenhum sinistro nos últimos anos. Óptimo!',
    laraFinalBeforeProposal6 : 'Vejo que os carros da empresa tiveram 1 sinistro nos últimos anos',
    laraFinalBeforeProposal7 :'Vejo que os carros da empresa tiveram mais do que 1 sinistro nos último anos',

    laraFinalBeforeProposal8 :'Provavelmente é a 1a vez que tem um carro em seu nome',
    laraFinalBeforeProposal9 :'Acho que vai ter o bonus máximo',
    laraFinalBeforeProposal10 :'Procurei o melhor bonus e o melhor preço para si',
    laraFinalBeforeProposal11 :'Provavelmente é a 1a vez que tem um carro em nome desta empresa',
    laraHelpCanceling0: 'Parabéns! A Tranquilidade vai enviar todos os documentos para o seu e-mail',
    laraHelpCanceling1: 'Quer ajuda na anulação do seu seguro actual?',
    laraHelpCancelingDesktop : 'Parabéns! A Tranquilidade vai enviar todos os documentos para o seu e-mail. Quer ajuda na anulação do seu seguro actual?',
    laraHelpCancelingDesktopHelper : 'Eu vou ajudar. Preciso que leia a assine a carta de anulação que enviarei para a sua seguradora',
    laraSignCancel0: 'Eu vou ajudar',
    laraSignCancel1: 'Preciso que leia a assine a carta de anulação que enviarei para a sua seguradora',
    laraSignCancel2: 'Eu vou ajudar. Vou enviar-lhe um link por SMS para o seu telemóvel onde poderá ler e assinar a carta de anulação',
    laraRateSimulation: 'Perfeito. Eu agora trato de tudo!',
    laraExitSimulation: 'Obrigada',
    laraVersionConfirm: 'Não tem probelma. Vou considerar a versão {consideredVersion}. É a versão que melhor garantias oferece na seguradora',
    laraCarSearch: 'Vou pesquisar toda a informação que preciso para o seu carro',
    laraDontHaveInsuranceType0: 'Oops! Parece que esta é uma matricula de uma mota',
    laraDontHaveInsuranceType: 'Infelizmente a uthere ainda comercializa seguros para motas',
    laraDontHaveInsuranceType2: 'Infelizmente a uthere ainda comercializa seguros para autocaravanas',
    laraDontHaveInsuranceType20 : 'Oops! Parece que esta é uma matricula de uma autocaravana',
    laraDontHaveInsuranceType3: 'Infelizmente a uthere ainda comercializa seguros para pesados',
    laraDontHaveInsuranceType30: 'Oops! Parece que esta é uma matricula de um pesado',
    laraDontHaveInsuranceType4: 'Infelizmente a uthere ainda comercializa seguros para o tipo de veículo indicado',
    laraHasDanosProprios0 : 'Obrigada. Para finalizar a sua proposta, preciso fazer-lhe mais 2 perguntas',
    laraHasDanosProprios : 'O seguro actual do {brand} tem cobertura de danos próprios?',
    laraHasDanosPropriosDesktop : 'Para finalizar a sua proposta, preciso fazer-lhe mais 2 perguntas. O seguro actual do {brand} tem cobertura de danos próprios?',
    laraCarNumberOfDoors: 'Com 3 ou 5 portas?',
    laraCarGarage : 'O {brand} fica normalmente estacionado na rua ou numa garagem?',
    laraNoCarData : 'Ops! Infelizemente não estou a conseguir encontar para já informação sobre o seu carro, por favor tente mais tarde',
    laraCarModel : 'Qual é o modelo do {brand}?',
    laraAlreadyClient : 'Recomendo que mantenha o seu seguro actual na Tranquilidade',
    laraAlreadyClient2 : 'Posso procurar outra opção caso queira',
    laraContactUserAlreadyClient : 'Deixe-me o seu e-mail. Eu depois envio-lhe uma outra proposta para o seu e-mail',
    laraEndContactUserAlreadyClient : 'Até já',
    laraContactUserAlreadyClientNo : 'Obrigada',
    laraNoCarExtras : 'Infelizmente não vou poder fazer a simulação',
    laraNoCarExtras2 : 'Não fazemos seguros para carros com reboques com peso superior a 300 kg',
    laraNoCarExtrasDesktop : 'Infelizmente não vou poder fazer a simulação. Não fazemos seguros para carros com reboques com peso superior a 300 kg',
    laraUsualDriverHelper : "O condutor habitual é a pessoa que normalmente conduz o carro",
    laraUsualDriverHelper2 : "Outras pessoas também podem conduzir o carro, desde que ocasionalmente . E também estão protegidas pelo seguro",
    /**LABELS */
    lblContinueSimulation: 'Continuar simulação',
    lblStartNewSimulation: 'Começar uma nova',
    lblCarConfirm1: 'Sim',
    lblCarConfirm2: 'Não',
    lblAutoCarExtras1: 'Sim',
    lblAutoCarExtras2: 'Não',
    lblAutoCarOwner1: 'Sim',
    lblAutoCarOwner2: 'É da empresa',
    lblAutoCarOwner3: 'É de um familiar',
    lblAutoCarOwner4: 'Vou comprar',
    lblAutoCarOwner5: 'É de outra pessoa',
    lblUsualDriver1: 'Sim',
    lblUsualDriver2: 'Não',
    lblNif: 'NIF',
    lblSelectCarVersion: 'Seleccione a versão',
    lblSelectCarModel : 'Seleccione o modelo',
    lblLicenseDate: "Data carta",
    lblNo: "Não",
    lblConfirm: "Confirmar",
    lblCarUse1: 'Uso particular',
    lblCarUse2: 'Taxi & TVDE',
    lblCarUse3: 'Mercadorias',
    lblBorndDate: 'Data nascimento',
    lblHelpCanceling1: "Sim",
    lblHelpCanceling2: "Não",
    lblAgree: 'Concordo',
    lblUnderstood: 'Óptimo',
    lblAutoType1: 'Carro',
    lblAutoType2: 'Mota',
    lblAutoType3: 'Autocaravana',
    lblAutoType4: 'Camião',
    lblCarNumberOfDoors1: '3 portas',
    lblCarNumberOfDoors2: '5 portas',
    lblHasDanosProprios1 : 'Sim',
    lblHasDanosProprios2 : 'Não',
    lblHasDanosProprios3 : 'Não sei',
    lblCarGarage1 : 'Na rua',
    lblCarGarage2 : 'Na garagem',
    lblAlreadyClient1 : 'Sim',
    lblAlreadyClient2 : 'Não',
    /**
     * USER
     */
    userAddress: '{addressDescription}',
    userRateSimulation: '{rateDescription}',
    userMessage: 'Já está',
    userCarExtras : 'Sim, com {reboquePeso}',
    userCarExtras2 : 'Não',

    /**
     * LETTERS
     */

    cancelLeter: "Exmos Senhores,<br/><br/>Solicito que seja anulada a apólice do meu seguro automóvel ({carDescription}, matrícula {plate}), a partir do próximo dia {emissionDate}).<br/><br/>Mais informo que não autorizo que seja efectuado mais nenhum débito na minha conta de nenhum valor associado à apólice cuja anulação estou agora a comunicar.<br/><br/>Obrigado,"
}