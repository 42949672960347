import './Button.scss';

/**
* Button
*/
function Button(props) {
    return (
        <button onClick={props.onClick} 
        className={`home-button-container
                    ${props.additionalClass  ? props.additionalClass : null}`}>{props.label}</button>
    );
};
export default Button;