import { getFunctions } from '../firebase/UthereFirebase';
import { store } from '../store/store';
import { getCurrentState } from '../logic/process/ProcessLogic';
import { getUserType } from './UserServices';
import { getUserId } from './UserServices';
import { cloneObject } from '../utils/GeneralUtils';
import { setEventFinishCheckout } from './AnalyticsServices';
import { isUthereUser } from './UserServices';

export function getActiveProcessesList(type) {
    let result = null;
    let processes = store.getState().processes;
    if (processes && processes.list) {
        let processesKeys = Object.keys(processes.list);
        for (let i = 0; i < processesKeys.length; i++) {
            let currentProcess = processes.list[processesKeys[i]];
            if (currentProcess.state !== 0 && (!type || currentProcess.type === type)) {
                if (result === null) {
                    result = [];
                    result.push(currentProcess);
                }
            }
        }
    }
    return result;
}

export async function markNotificationsAsRead() {
    return new Promise(async function (resolve, reject) {
        try {
            if (getUserType() !== 'guest') {
                store.dispatch({ type: 'processes/readProcesses' });
                let uthereSaveProcessDetailsInput = {
                    markAsRead: true
                }
                let functions = await getFunctions();
                let functionsImpl = await import("firebase/functions");
                const uthereSaveProcessDetails = functionsImpl.httpsCallable(functions, 'uthereSaveProcessDetails');
                await uthereSaveProcessDetails(uthereSaveProcessDetailsInput);
            }
            else {
                store.dispatch({ type: 'processes/readProcesses' });
            }
            resolve();
        }
        catch (e) {
            reject(e);
        }
    });
}

/**
 * 
 * @param {*} processData 
 * @returns 
 */
export async function saveProcessDetails(processData, changeState, emailType, email, name, error) {
    return new Promise(async function (resolve, reject) {
        try {
            //Guarda dados do processo na store
            let processPayLoad = {
                processId: processData.processId,
                process: processData,
                changeState: changeState
            }
            store.dispatch({ type: 'processes/setProcess', payload: processPayLoad });
            //Atualiza dados na BD
            if (getUserType() !== 'guest' || email) {
                let uthereSaveProcessDetailsInput = {
                    email: email,
                    name: name,
                    processData: processData,
                    changeState: changeState,
                    emailType: emailType,
                }

                if (error) {
                    uthereSaveProcessDetailsInput.error = error;
                }
                let functions = await getFunctions();
                let functionsImpl = await import("firebase/functions");
                let allDocuments = store.getState().documents.list;
                let documentsToSave = [];
                let allDocumentsKeys = allDocuments ? Object.keys(allDocuments) : null;
                if (allDocumentsKeys) {
                    for (let i = 0; i < allDocumentsKeys.length; i++) {
                        let current = cloneObject(allDocuments[allDocumentsKeys[i]]);
                        if (current && current.processId === processData.processId && !current.saved) {
                            current.id = allDocumentsKeys[i];
                            documentsToSave.push(current);
                        }
                    }
                    uthereSaveProcessDetailsInput.documents = [];
                    for (let i = 0; i < documentsToSave.length; i++) {
                        uthereSaveProcessDetailsInput.documents.push({
                            id: documentsToSave[i].id,
                            processId: documentsToSave[i].processId,
                            file: documentsToSave[i].document
                        })
                    }
                    if (documentsToSave.length > 0) {
                        let payload = {
                            processId: documentsToSave[0].processId
                        }
                        store.dispatch({ type: 'documents/setSaved', payload: payload });
                    }
                }
                const uthereSaveProcessDetails = functionsImpl.httpsCallable(functions, 'uthereSaveProcessDetails');
                await uthereSaveProcessDetails(uthereSaveProcessDetailsInput);
                if (changeState) {
                    setEventFinishCheckout(processData.branch, processData.subBranch,
                        processData.selectedInsurerId);
                }
            }
            resolve();
        } catch (e) {
            reject(e)
        }
    });
};

/**
 * 
 * @param {*} processId 
 * @returns 
 */
export async function cancelProcess(processId) {
    return new Promise(async function (resolve, reject) {
        try {
            let userId = getUserId();
            if (userId) {
                let uthereCancelProcessInput = {
                    processId: processId,
                    userId: userId
                }
                let functions = await getFunctions();
                let functionsImpl = await import("firebase/functions");
                const uthereCancelProcess = functionsImpl.httpsCallable(functions, 'uthereCancelProcess');
                await uthereCancelProcess(uthereCancelProcessInput);
            }
            let processPayLoad = {
                processId: processId,
            }
            store.dispatch({ type: 'processes/cancelProcess', payload: processPayLoad });
            resolve();
        } catch (e) {
            reject(e)
        }
    });
};

/**
 * 
 * @returns 
 */
export function getProcessWithSameBranchAndSubBranch() {
    let currentSimulationState = getCurrentState();
    let currentBranch = currentSimulationState.branch;
    let currentSubBranch = currentSimulationState.subBranch;
    let allProcess = store.getState().processes.list;
    let processKeys = Object.keys(allProcess);
    for (let i = 0; i < processKeys.length; i++) {
        let currentProcess = allProcess[processKeys[i]];
        if (currentProcess.branch === currentBranch && currentProcess.subBranch === currentSubBranch
            && currentProcess.state === 1) {
            return currentProcess.processId;
        }
    }
    return null;
}

/**
 * Get guest process Data
 * @param {*} email 
 * @param {*} processId 
 */
export function getProcessData(email, processId) {
    return new Promise(async function (resolve, reject) {
        try {
            if (email && processId) {
                let serviceInput = {
                    email: email,
                    processId: processId
                }
                let functions = await getFunctions();
                let functionsImpl = await import("firebase/functions");
                const uthereGetProcessDataService = functionsImpl.httpsCallable(functions, 'uthereGetProcessData');
                let resultTemp = await uthereGetProcessDataService(serviceInput);
                let result = resultTemp && resultTemp.data ? resultTemp.data : null;
                if (result) {
                    let payload = {
                        processes: {}
                    }
                    payload.processes[processId] = result;
                    store.dispatch({ type: 'processes/setProcesses', payload: payload });
                }
                resolve(result);
            }
            else {
                reject();
            }
        } catch (e) {
            reject(e)
        }
    });
}