import './HomeHeader.scss';
import uthereLogo from '../../../assets/svg/uthere.svg';
import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { getActiveInsurances } from '../../../services/InsuranceServices';
import { isMobile } from '../../../utils/DeviceUtils';
import { logOut } from '../../../services/UserServices';
import * as InsurersConfig from '../../../config/proposal/insurersConfig';
import useUser from '../../../hooks/useUser';
import { store } from '../../../store/store';
import { getActiveProcessesList } from '../../../services/ProcessesServices';
/**
* HomeHeader
*/
function HomeHeader(props) {
    const user = useUser();
    const [hamburgerOpened, setHamburgerOpened] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [stateInsurances, setStateInsurances] = useState(getActiveInsurances(null, null, true));
    const [priceInfo, setPriceInfo] = useState(getPriceInfo(props.price));
    const uContainerFullNav = useRef(null);
    const uBtnSimular = useRef(null);
    const mobile = isMobile();
    const navigate = useNavigate();
    const location = useLocation();
    const activeProcessList = getActiveProcessesList();
    const menuType = location && (location.pathname === "/simulacao" || location.pathname === "/participacao-sinistro") ?
        "process" : location && location.pathname === "/cancelamento-emails" ? "emailCancel" : "normal";
    let unsubscribe = null;
    useEffect(() => {
        setPriceInfo(getPriceInfo(props.price))
        return () => {
        }
    }, [props.price])
    useEffect(() => {
        setScrollEvents(uContainerFullNav.current, uBtnSimular.current);
        return () => {
        }
    }, []);
    useEffect(() => {
        unsubscribe = store.subscribe(() => {
            if (store.getState().insurances) {
                setStateInsurances(getActiveInsurances(null, null, true))
            }
        });
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, []);

    const onBtnSimular = (branch) => {
        let urlString = '/simulacao';
        if (branch) {
            urlString += '?branch=' + branch;
        }
        import("../../../services/AnalyticsServices").then(async analyticsServicsImpl => {
            analyticsServicsImpl.setEventCTASelected(
                'Simular',
                'CTA Header', 1, branch, 0, location.pathname
            )
        })
        navigate(urlString);
    }

    const toggleHamburger = (type) => {
        setHamburgerOpened(!hamburgerOpened)
    }

    const handleRestart = () => {
        setHamburgerOpened(!hamburgerOpened);
        if (props.handleRestart) {
            props.handleRestart();
        }
    }

    const handleCloseProcess = () => {
        setHamburgerOpened(!hamburgerOpened);
        if (props.handleClose) {
            props.handleClose();
        }
    }

    const setScrollEvents = (uContainerFullNav, uBtnSimular) => {
        document.body.addEventListener("scroll", function () {
            if (uContainerFullNav) {
                if (document.body.scrollTop > 1) {
                    uContainerFullNav.classList.add("uthere-home-header-scroll");
                } else {
                    uContainerFullNav.classList.remove("uthere-home-header-scroll");
                }
            }
            if (uBtnSimular) {
                if (document.body.scrollTop > 280) {
                    setButtonDisabled(false);
                    uBtnSimular.classList.add("uthere-home-header-button-show");
                    uBtnSimular.classList.remove("uthere-home-header-button-hidden");
                } else if (uBtnSimular.classList && uBtnSimular.classList.length === 2) {
                    uBtnSimular.classList.remove("uthere-home-header-button-show");
                    uBtnSimular.classList.add("uthere-home-header-button-hidden");
                    setButtonDisabled(true);
                }
            }
        });
    }

    const handleFaq = () => {
        if (props.handleFaq) {
            props.handleFaq();
        }
        toggleHamburger();
    }

    const handleContact = () => {
        if (props.handleContact) {
            props.handleContact();
        }
        toggleHamburger();
    }


    return (
        <div style={{ width: "100%" }}>
            <div
                className={`uthere-home-header ${props.notFixed ? "uthere-home-header-realtive" : ""}`}
                ref={uContainerFullNav}>
                <div
                    className={`uthere-home-header-up ${props.showProgress || props.showBorder ? "uthere-home-header-up-boder" : ""}`}>
                    {!props.title && !props.handleBack && !props.hideLogo && (!hamburgerOpened || !mobile) ?
                        <div className='uthere-home-header-container-right'>
                            {props.navigationType != 1 ?
                                <NavLink to={"/home"} href={"/home"}>
                                    <img className='uthere-home-logo' src={uthereLogo} alt="uthere" />
                                </NavLink> : <img className='uthere-home-logo' src={uthereLogo} alt="uthere" />}
                            <div className='uthere-home-header-claim'>ajudamos a encontrar o seguro ideal para si</div>
                        </div> :
                        <div className='uthere-home-header-container-right'>
                            {props.handleBack && !hamburgerOpened ?
                                <div className='uthere-home-header-back' onClick={props.handleBack}>
                                    <svg width="auto" height="auto" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 20.5L8 12.5L16 4.5" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                : null
                            }
                            {props.proposalProps && props.proposalProps.proposal && !props.readOnly ?
                                <div style={{ opacity: props.showProposalHeader ? 1 : 0 }} className='header-desktop-proposal-container'>
                                    {props.proposalProps.proposal.map((item, i) => (
                                        <div key={i} id={"optionProposal" + i} className='header-desktop-proposal-container-detail'
                                            onClick={() => props.proposalProps.setProposalIndex(i)}>
                                            <img src={'../insurers/' + InsurersConfig.headerLogos[item.insurerId]}
                                                alt={InsurersConfig.insurerName[item.insurerId]} />
                                            <div style={{ "--insurerColor": InsurersConfig.insurerColor[item.insurerId] }}
                                                className={`header-desktop-proposal-container-price 
                                                    ${props.proposalProps.selectedState.activeIndex === i ? "header-desktop-proposal-container-price-selected" : ""}`}>
                                                <span className="header-desktop-proposal-price-main">
                                                    {Math.trunc(item.price)}
                                                </span>,{
                                                    item.price.toString().split('.').length > 1 ?
                                                        item.price.toString().split('.')[1].substring(0, 2) : "00"
                                                }€
                                            </div>
                                        </div>
                                    ))}

                                </div> : null
                            }
                            {props.price && !props.readOnly ?
                                <div style={{ opacity: props.showProposalHeaderHouse ? 1 : 0 }} className='header-desktop-proposal-container'>
                                    <div className='desktop-header-box-price'>

                                        <div className='desktop-header-box-price-main'>{priceInfo.int}</div>
                                        <div className='desktop-header-box-price-secondary'>
                                            <div className='desktop-header-box-price-secondary-period'>{priceInfo.periodo}</div>
                                            <div className='desktop-header-box-price-secondary-cents'>,{priceInfo.cent} €</div>
                                        </div>
                                        {priceInfo.otherPrice ? <div className='desktop-header-box-price-main desktop-header-box-price-other'>
                                            {priceInfo.otherPrice}€<div></div></div> : null}
                                    </div>
                                </div> : null}
                        </div>
                    }
                    {props.title && (!hamburgerOpened || !isMobile()) ?
                        <div className='uthere-home-header-title'>{props.title}</div>
                        : null}
                    <div className='uthere-home-header-container-left'>
                        {props.handleCloseManual ?
                            <div className='uthere-home-header-back' onClick={props.handleCloseManual}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="auto" height="auto" viewBox="0 0 24 24" fill="none">
                                    <path d="M4 20L20 3.99999" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4 4L20 20" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            : null
                        }
                        {props.simular ?
                            <button disabled={buttonDisabled} onClick={() => onBtnSimular(props.branch)}
                                className='uthere-home-header-button' ref={uBtnSimular}>
                                Simular
                            </button> : null}
                        {props.price && !isMobile() ?
                            <div style={{ opacity: props.showProposalHeaderHouse ? 1 : 0 }} className='header-desktop-proposal-container'>
                                <div>
                                    {props.handleChoseProposal ?
                                        <div className='desktop-header-box-button' onClick={props.handleChoseProposal}>
                                            Comprar
                                        </div> : null}
                                </div>

                            </div> : null}
                        {props.handleCustom && props.customHeaderLabel ? <div className='header-custom-action' onClick={props.handleCustom}>{props.customHeaderLabel}</div> : null}
                        {!props.hideHamburger ? <div>
                            {!props.hideHamburger ?
                                <div
                                    className={`uthere-home-header-hamburger ${hamburgerOpened ? "is-active" : ""}`} onClick={toggleHamburger}>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </div> : null}
                            {!mobile && hamburgerOpened ?
                                <div>
                                    <div
                                        className={`uthere-menu-opacity ${!hamburgerOpened ? "uthere-menu-opacity-hidden" : ""}`}
                                        onClick={toggleHamburger}></div>
                                    <div className="uthere-home-header-desktop-container">
                                        <div className="uthere-home-header-desktop-menu">
                                            <div className='uthere-home-header-desktop-menu-top'>
                                                {menuType === "process" ? <div>Tem uma dúvida?</div> : null}
                                                <div className='uthere-home-header-hamburger-menu-top-options'>
                                                    {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                                        <NavLink onClick={toggleHamburger} to={"/auto"}
                                                            href={"/auto"}>Automóvel</NavLink> : null}
                                                    {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                                        <NavLink onClick={toggleHamburger} to={"/casa"}
                                                            href={"/casa"}>Multirrisco Habitação</NavLink> : null}
                                                    {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                                        <NavLink onClick={toggleHamburger} to={"/vida"}
                                                            href={"/vida"}>Vida</NavLink> : null}
                                                    {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                                        <div className='link-soon'><div>Saúde</div> <div className='link-soon-soon'>Em breve</div></div> : null}
                                                    {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ? <div className='uthere-home-header-menu-seprator'></div> : null}
                                                    {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                                        <NavLink onClick={() => toggleHamburger(2)} to={"/participacao-sinistro"}
                                                            href={"/participacao-sinistro"}>Participar sinistro</NavLink> : null}
                                                    {menuType !== "process" && props.navigationType != 1 ?
                                                        <NavLink onClick={toggleHamburger} to={"/fale-connosco"}
                                                            href={"/fale-connosco"}>Fale connosco</NavLink> : null}
                                                    {menuType === "process" && props.navigationType != 1 && props.handleFaq ?
                                                        <div onClick={handleFaq}>Perguntas frequentes</div> : null}
                                                    {(menuType === "process" || menuType == "emailCancel" || props.navigationType == 1) && props.handleContact ?
                                                        <div onClick={handleContact}>Enviar mensagem</div> : null}
                                                    {(menuType === "process" || props.navigationType == 1) && mobile ?
                                                        <a href="tel:+351 914 399 883">Ligar para +351 914 399 883</a> : null}
                                                    {menuType === "process" ? <div className='uthere-home-header-menu-seprator'></div> : null}
                                                    {
                                                        process.env.NODE_ENV === "development" ?
                                                            <NavLink to="/dev" onClick={toggleHamburger} >Back office</NavLink>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='uthere-home-header-hamburger-menu-bottom'>
                                                {activeProcessList && menuType !== "process" && user.type !== "uthereUser" && props.navigationType != 1 ?
                                                    <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                        <svg width="auto" height="auto" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.9767 16.9408V15.9945C15.5467 14.5752 15.07 13.6289 15.07 11.7365V9.84399C15.07 7.4926 13.1491 5.58594 10.7801 5.58594C8.41108 5.58594 6.49014 7.4926 6.49014 9.84399V11.7365C6.49014 13.6289 6.01348 14.5752 4.5835 15.9945V16.9408H16.9767Z" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M12.2105 17.8877C12.2105 18.4105 11.57 18.8339 10.7806 18.8339C9.9911 18.8339 9.35059 18.4105 9.35059 17.8877" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M10.7798 4.16699V5.58634" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                            <circle cx="17.8748" cy="4.62533" r="3.20833" fill="#F02C13" />
                                                        </svg>

                                                        <div>
                                                            <NavLink to="/notificacoes"
                                                                onClick={toggleHamburger} >Notifcações</NavLink>
                                                        </div>
                                                    </div>
                                                    : null}
                                                {user.type !== "uthereUser" && menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                                    <div onClick={() => navigate("/login")} className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                        <svg width="auto" height="auto" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M11.9167 14.25L14.6667 11.5L11.9167 8.75" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M14.6667 11.5L3.66675 11.5" stroke="#333333" strokeLinecap="round" />
                                                            <path d="M11 4.16699L16.3333 4.16699C17.4379 4.16699 18.3333 5.06242 18.3333 6.16699L18.3333 16.8337C18.3333 17.9382 17.4379 18.8337 16.3333 18.8337L11 18.8337" stroke="#333333" strokeLinecap="round" />
                                                        </svg>
                                                        <div>Entrar</div>
                                                    </div>

                                                    :
                                                    <div className='uthere-home-header-hamburger-menu-bottom-user-options'>
                                                        {activeProcessList && menuType !== "process" ?
                                                            <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                                <svg width="auto" height="auto" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M16.9767 16.9408V15.9945C15.5467 14.5752 15.07 13.6289 15.07 11.7365V9.84399C15.07 7.4926 13.1491 5.58594 10.7801 5.58594C8.41108 5.58594 6.49014 7.4926 6.49014 9.84399V11.7365C6.49014 13.6289 6.01348 14.5752 4.5835 15.9945V16.9408H16.9767Z" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M12.2105 17.8877C12.2105 18.4105 11.57 18.8339 10.7806 18.8339C9.9911 18.8339 9.35059 18.4105 9.35059 17.8877" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.7798 4.16699V5.58634" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <circle cx="17.8748" cy="4.62533" r="3.20833" fill="#F02C13" />
                                                                </svg>

                                                                <div>
                                                                    <NavLink to="/notificacoes"
                                                                        onClick={toggleHamburger} >Notifcações</NavLink>
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {stateInsurances && stateInsurances.length === 1 && menuType !== "process" && menuType !== "emailCancel" ?
                                                            <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                                <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9 9H15" stroke="#333333" strokeLinecap="round" />
                                                                    <path d="M9 12H15" stroke="#333333" strokeLinecap="round" />
                                                                    <path d="M13 16L15 16" stroke="#333333" strokeLinecap="round" />
                                                                    <rect x="6" y="4" width="12" height="16" rx="1" stroke="#333333" />
                                                                </svg>
                                                                <div>
                                                                    <NavLink to="/apolice" state={
                                                                        {
                                                                            id: stateInsurances[0].id,
                                                                        }}
                                                                        onClick={toggleHamburger} >Meu seguro</NavLink>
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {stateInsurances && stateInsurances.length > 1 && menuType !== "process" && menuType !== "emailCancel" ?
                                                            <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                                <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9 9H15" stroke="#333333" strokeLinecap="round" />
                                                                    <path d="M9 12H15" stroke="#333333" strokeLinecap="round" />
                                                                    <path d="M13 16L15 16" stroke="#333333" strokeLinecap="round" />
                                                                    <rect x="6" y="4" width="12" height="16" rx="1" stroke="#333333" />
                                                                </svg>
                                                                <div>
                                                                    <NavLink to="/apolices" href={"/apolices"}>Meus seguros</NavLink>
                                                                </div>
                                                            </div>
                                                            : null}
                                                        {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                                            <div onClick={logOut} className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                                <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17 15L20 12L17 9" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M20 12L8 12" stroke="#333333" strokeLinecap="round" />
                                                                    <path d="M12 4L6 4C4.89543 4 4 4.89543 4 6L4 18C4 19.1046 4.89543 20 6 20L12 20" stroke="#333333" strokeLinecap="round" />
                                                                </svg>
                                                                <div>Sair</div>
                                                            </div> : null}
                                                        {menuType === "process" || menuType === "error" || menuType === "emailCancel" ?
                                                            <div onClick={handleCloseProcess}
                                                                className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                                <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g >
                                                                        <path d="M12 3L2 9.67744V12H4.44615V21H10.0915V14.1774H13.9085V21H19.5538V12H22V9.67744L12 3Z" stroke="#333333" strokeLinejoin="round" />
                                                                    </g>
                                                                </svg>
                                                                <div>Voltar para a home
                                                                </div>
                                                            </div> : null}
                                                        {menuType === "process" && props.handleRestart ?
                                                            <div onClick={handleRestart} className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                                                <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.12402 7.95556C6.50781 5.59111 9.08024 4 12.0164 4C16.425 4 19.9997 7.58222 19.9997 12C19.9997 16.4178 16.425 20 12.0164 20C10.6947 20 9.44393 19.68 8.34399 19.1022" stroke="#333333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M4.51371 10.2083L8.09545 7.97195C8.32376 7.82543 8.32376 7.4707 8.09545 7.33189L4.51371 5.09551C4.2854 4.94899 4 5.12636 4 5.41169V9.87673C4 10.1698 4.2854 10.3471 4.51371 10.2083Z" fill="#333333" />
                                                                </svg>
                                                                <div>
                                                                    Recomeçar {location.pathname === "/participacao-sinistro" ? "sinistro" : "simulação"}</div>
                                                            </div> : null}
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div> : null}
                        </div> : null}
                    </div>
                </div>
                {/*props.showProgress ?
                    <div className='uthere-home-header-progress-container'>
                        <div className='uthere-home-header-process-progress' style={{ width: props.indexRatio + '%' }}></div>
                                            </div> : null*/}
            </div>

            {mobile ?
                <div className={`uthere-home-header-hamburger-menu ${hamburgerOpened ? "is-active" : ""}`} >
                    <div className='uthere-home-header-hamburger-menu-top'>
                        {menuType === "process" ? <div>Tem uma dúvida?</div> : null}
                        <div className='uthere-home-header-hamburger-menu-top-options'>
                            {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                <NavLink onClick={toggleHamburger} to={"/auto"}
                                    href={"/auto"}>Automóvel</NavLink> : null}
                            {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                <NavLink onClick={toggleHamburger} to={"/casa"}
                                    href={"/casa"}>Multirrisco Habitação</NavLink> : null}
                            {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                <NavLink onClick={toggleHamburger} to={"/vida"}
                                    href={"/vida"}>Vida</NavLink> : null}
                            {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                <div className='link-soon'><div>Saúde</div> <div className='link-soon-soon'>Em breve</div></div> : null}
                            {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ? <div className='uthere-home-header-menu-seprator'></div> : null}
                            {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                <NavLink onClick={() => toggleHamburger(2)} to={"/participacao-sinistro"}
                                    href={"/participacao-sinistro"}>Participar sinistro</NavLink> : null}
                            {menuType !== "process" && props.navigationType != 1 ?
                                <NavLink onClick={toggleHamburger} to={"/fale-connosco"}
                                    href={"/fale-connosco"}>Fale connosco</NavLink> : null}
                            {menuType === "process" && props.handleFaq && props.navigationType != 1 ?
                                <div onClick={props.handleFaq}>Perguntas frequentes</div> : null}
                            {(menuType === "process" || props.navigationType == 1) && props.handleContact ?
                                <div onClick={props.handleContact}>Enviar mensagem</div> : null}
                            {(menuType === "process" || menuType == "emailCancel" || props.navigationType == 1) && mobile ?
                                <a href="tel:+351 914 399 883">Ligar para +351 914 399 883</a> : null}
                            {menuType === "process" ? <div className='uthere-home-header-menu-seprator'></div> : null}
                            {
                                process.env.NODE_ENV === "development" ?
                                    <NavLink to="/dev" onClick={toggleHamburger} >Back office</NavLink>
                                    : null
                            }
                        </div>
                    </div>

                    <div className='uthere-home-header-hamburger-menu-bottom'>
                        {activeProcessList && menuType !== "process" && user.type !== "uthereUser" ?
                            <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                <svg width="auto" height="auto" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.9767 16.9408V15.9945C15.5467 14.5752 15.07 13.6289 15.07 11.7365V9.84399C15.07 7.4926 13.1491 5.58594 10.7801 5.58594C8.41108 5.58594 6.49014 7.4926 6.49014 9.84399V11.7365C6.49014 13.6289 6.01348 14.5752 4.5835 15.9945V16.9408H16.9767Z" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.2105 17.8877C12.2105 18.4105 11.57 18.8339 10.7806 18.8339C9.9911 18.8339 9.35059 18.4105 9.35059 17.8877" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10.7798 4.16699V5.58634" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                    <circle cx="17.8748" cy="4.62533" r="3.20833" fill="#F02C13" />
                                </svg>

                                <div>
                                    <NavLink to="/notificacoes"
                                        onClick={toggleHamburger}>Notifcações</NavLink>
                                </div>
                            </div>
                            : null}
                        {user.type !== "uthereUser" && menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                            <div onClick={() => navigate("/login")} className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                <svg width="auto" height="auto" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9167 14.25L14.6667 11.5L11.9167 8.75" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.6667 11.5L3.66675 11.5" stroke="#333333" strokeLinecap="round" />
                                    <path d="M11 4.16699L16.3333 4.16699C17.4379 4.16699 18.3333 5.06242 18.3333 6.16699L18.3333 16.8337C18.3333 17.9382 17.4379 18.8337 16.3333 18.8337L11 18.8337" stroke="#333333" strokeLinecap="round" />
                                </svg>
                                <div>Entrar</div>
                            </div>
                            :
                            <div className='uthere-home-header-hamburger-menu-bottom-user-options'>
                                {activeProcessList && menuType !== "process" ?
                                    <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                        <svg width="auto" height="auto" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.9767 16.9408V15.9945C15.5467 14.5752 15.07 13.6289 15.07 11.7365V9.84399C15.07 7.4926 13.1491 5.58594 10.7801 5.58594C8.41108 5.58594 6.49014 7.4926 6.49014 9.84399V11.7365C6.49014 13.6289 6.01348 14.5752 4.5835 15.9945V16.9408H16.9767Z" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.2105 17.8877C12.2105 18.4105 11.57 18.8339 10.7806 18.8339C9.9911 18.8339 9.35059 18.4105 9.35059 17.8877" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.7798 4.16699V5.58634" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                            <circle cx="17.8748" cy="4.62533" r="3.20833" fill="#F02C13" />
                                        </svg>

                                        <div>
                                            <NavLink to="/notificacoes"
                                                onClick={toggleHamburger} >Notificações</NavLink>
                                        </div>
                                    </div>
                                    : null}
                                {stateInsurances && stateInsurances.length === 1 && menuType !== "process" && props.navigationType != 1 ?
                                    <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                        <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 9H15" stroke="#333333" strokeLinecap="round" />
                                            <path d="M9 12H15" stroke="#333333" strokeLinecap="round" />
                                            <path d="M13 16L15 16" stroke="#333333" strokeLinecap="round" />
                                            <rect x="6" y="4" width="12" height="16" rx="1" stroke="#333333" />
                                        </svg>
                                        <div>
                                            <NavLink to="/apolice" state={
                                                {
                                                    id: stateInsurances[0].id,
                                                }}
                                                onClick={toggleHamburger} >Meu seguro</NavLink>
                                        </div>
                                    </div>
                                    : null}
                                {stateInsurances && stateInsurances.length > 1 && menuType !== "process" && props.navigationType != 1 ?
                                    <div className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                        <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 9H15" stroke="#333333" strokeLinecap="round" />
                                            <path d="M9 12H15" stroke="#333333" strokeLinecap="round" />
                                            <path d="M13 16L15 16" stroke="#333333" strokeLinecap="round" />
                                            <rect x="6" y="4" width="12" height="16" rx="1" stroke="#333333" />
                                        </svg>
                                        <div>
                                            <NavLink to="/apolices" href={"/apolices"}>Meus seguros</NavLink>
                                        </div>
                                    </div>
                                    : null}
                                {menuType !== "process" && menuType !== "emailCancel" && props.navigationType != 1 ?
                                    <div onClick={logOut} className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                        <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 15L20 12L17 9" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M20 12L8 12" stroke="#333333" strokeLinecap="round" />
                                            <path d="M12 4L6 4C4.89543 4 4 4.89543 4 6L4 18C4 19.1046 4.89543 20 6 20L12 20" stroke="#333333" strokeLinecap="round" />
                                        </svg>
                                        <div>Sair</div>
                                    </div> : null}
                                {(menuType === "process" || menuType == "emailCancel") && props.navigationType != 1 ?
                                    <div onClick={handleCloseProcess}
                                        className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                        <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g >
                                                <path d="M12 3L2 9.67744V12H4.44615V21H10.0915V14.1774H13.9085V21H19.5538V12H22V9.67744L12 3Z" stroke="#333333" strokeLinejoin="round" />
                                            </g>
                                        </svg>
                                        <div>Voltar para a home
                                        </div>
                                    </div> : null}
                                {menuType === "process" && props.handleRestart && props.navigationType != 1 ?
                                    <div onClick={handleRestart} className='uthere-home-header-hamburger-menu-bottom-user-options-items'>
                                        <svg width="auto" height="auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.12402 7.95556C6.50781 5.59111 9.08024 4 12.0164 4C16.425 4 19.9997 7.58222 19.9997 12C19.9997 16.4178 16.425 20 12.0164 20C10.6947 20 9.44393 19.68 8.34399 19.1022" stroke="#333333" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M4.51371 10.2083L8.09545 7.97195C8.32376 7.82543 8.32376 7.4707 8.09545 7.33189L4.51371 5.09551C4.2854 4.94899 4 5.12636 4 5.41169V9.87673C4 10.1698 4.2854 10.3471 4.51371 10.2083Z" fill="#333333" />
                                        </svg>
                                        <div>
                                            Recomeçar {location.pathname === "/participacao-sinistro" ? "sinistro" : "simulação"}</div>
                                    </div> : null}
                            </div>
                        }
                    </div>
                </div> : null

            }
        </div>
    );
};
export default HomeHeader;

function getPriceInfo(price) {
    let objectToReturn = {};
    if (price && price.price && price.periodo) {
        let priceString = price.price.toString();
        let priceSplit = priceString.split(".");
        let intPart = priceSplit[0];
        let centPart = priceSplit.length > 1 ? priceSplit[1] : "00";
        objectToReturn.int = intPart;
        objectToReturn.cent = centPart;
        objectToReturn.periodo = price.periodo;
        if (price.otherPrice) {
            objectToReturn.otherPrice = price.otherPrice;
        }
    }
    return objectToReturn;
}