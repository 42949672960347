
import { lang } from './ClaimLang';
import { houseClaimFlow } from './house/HouseClaimConfig';
import { lifeClaimFlow } from './life/LifeClaimConfig';
import { otherClaimFlow } from './other/OtherClaimConfig';
import luis from '../../assets/luis/luis.png'


export function getAvatar() {
    return {
        img: luis,
        alt: "Luís",
        desc: 'Assistente digital sinistros',
        name: 'Luís',
        header: "Olá, eu sou o Luís",
        subtitle: "Sou o <span className='u-fw-600'>assistente digital de sinistros</span>, da uthere.<br/><br>" +
            "Eu sei que participar um sinistro pode ser difícil. Não se preocupe. <span className='u-fw-600'>Eu vou ajudar</span> e falar com a sua seguradora. É muito rápido 🚀"
    };
}

export function getFlow(branchInfo) {
    return flows[branchInfo.branch];
}
export function getLang() {
    return lang;
}

const baseFlow = {
    begin: 'chooseClaim',
    laraIntroMessage: 'luisIntroMessageName',
    pages: {
        chooseClaim: {
            type: 'chooseInsurance',
            laraMessage: ['luisWhatInsuranceMessage'],
            desktopMessage: ['luisWhatInsuranceMessage'],
            nextPageId: {
                sequence: [
                    {
                        result: 'insurer',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseClaim',
                                    },
                                    {
                                        type: 'constant',
                                        value: 99,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'insuranceAtUthere',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'NOT_UTHERE_USER',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseClaim',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseClaimType',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'UTHERE_USER',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseClaim',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'insuranceAtUthere',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'NOT_UTHERE_USER',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'chooseClaim',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'inform',
                    }]
            },
            userMessage:
            {
                sequence:
                    [
                        {
                            result: 'userChooseClaimHouse',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'chooseClaim',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaimLife',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'chooseClaim',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaimAuto',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'insuranceType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'Automóvel',
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaimWork',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'insuranceType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'Acidente de Trabalho',
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaimPersonal',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'insuranceType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'Acidente Pessoal',
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaimResponsability',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'insuranceType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'Responsablidade Civil',
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaimAnimals',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'insuranceType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'Animais',
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaimHealth',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'insuranceType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'Saúde',
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'userChooseClaim',
                        }]
            },
            options: {
                valuesBranchMapping: {
                    1: { branch: 1 },
                    2: { branch: 3 },
                    99: { branch: 99 },
                },
                items: [
                    {
                        value: 1,
                        label: 'lblRadioChooseClaimLife',

                    },
                    {
                        value: 2,
                        label: 'lblRadioChooseClaimHouse',

                    },
                    {
                        value: 99,
                        label: 'lblRadioChooseClaimOther',
                        followup: [
                            {
                                id: 'insuranceType',
                                title: 'A que seguro se refere?',
                                type: 'radio',
                                list: 'insuranceList',
                            }]

                    },
                ]
            }
        },
        dummy: {},
        dummy2: {},
        dummy3: {},
        dummy4: {},
        dummy5: {},
        dummy6: {},
        dummy7: {},
        dummy8: {},
        dummy9: {},
        dummy10: {},
        dummy11: {},
        dummy12: {},
        dummy13: {},
        dummy14: {},
        dummy15: {},
        dummy16: {},
        dummy17: {},
        dummy18: {},
        dummy19: {},
        dummy20: {},
        dummy21: {},
        dummy22: {},
        dummy23: {},
        dummy24: {},
        dummy25: {},
        dummy26: {},
        dummy27: {},
        dummy28: {},
        dummy29: {},
        dummy30: {},
        dummy31: {},
        dummy32: {},
        dummy33: {},
        dummy34: {},
        dummy35: {},
        dummy36: {},
        dummy37: {},
        dummy38: {},
        dummy39: {},
        dummy40: {},
    }
};
export const flows = {
    0: baseFlow,
    1: lifeClaimFlow,
    3: houseClaimFlow,
    99: otherClaimFlow
}

