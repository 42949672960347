export const creditTransferFlow = {
    begin: {
        sequence: [
            {
                result: 'continue',
                type: 'OR',
                conditions: [
                    {
                        comparator: 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH',

                    }
                ],
            },
            {
                result: 'bornDate',
            }]
    },
    laraIntroMessage:
    {
        sequence:
            [{
                result: ['laraIntroMessage'],
                type: 'OR',
                conditions: [
                    {
                        comparator: 'NOT_EMPTY',
                        values: [
                            {
                                type: 'userVariable',
                                value: 'name',
                            },
                        ]
                    }
                ],
            },
            {
                result: ['laraIntroMessage'],
            }]
    },
    pages: {
        continue: {
            type: 'continue',
            nextPageId: 'bornDate',
            userMessage: 'userContinue',
            laraMessage: ['laraContinue'],
            desktopMessage: ['laraContinue'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblContinueSimulation',

                    },
                    {
                        value: 2,
                        label: 'lblStartNewSimulation',

                    },
                ]
            }
        },
        bornDate: {
            type: 'date',
            desktopMessage: ['laraBornDateNoName'],
            laraMessage: ['laraLifeCreditTransferBegin', 'laraBornDateNoName'],
            userMessage: 'userBornDate',
            nextPageId: 'ocupation',
            options: {
                onBirthday: "Parabéns! 🎉🍾. Que bom dia para poupar e comprar um presente para si 😀",
                onAlmostBirthday: "Uau. Está quase a fazer anos 😀",
                validation: {
                    type: "AGE",
                    min: 18,
                    max: 65,
                    minText: "Não podemos apresentar uma proposta se não tiver 18 anos",
                    maxText: "Não podemos apresentar uma proposta se tiver mais do que 65 anos"
                },
                label: 'lblBorndDate',
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 40,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 17,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 66,
                            },
                        ]
                    }
                }
            }
        },
        ocupation: {
            type: 'ocupation',
            nextPageId: 'ocupationActivities',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHappyBirthday', 'laraOcupation'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                        ],
                    },
                    {
                        result: ['laraHappyBirthday', 'laraOcupation'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                        ],
                    },
                    {
                        result: ['laraOcupation'],
                    }]
            },
            desktopMessage: ['laraOcupation'],
            userMessage: 'userOcupation',
        },
        ocupationActivities: {
            type: 'ocupationActivities',
            nextPageId: 'activities',
            laraMessage: ['laraOcupationActivities'],
            desktopMessage: ['laraOcupationActivities'],
            userMessage: 'userOcupationActivities',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                        {
                            value: 2,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                        {
                            value: 3,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                        {
                            value: 4,
                            text: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',

                        },
                    ]
                },
                items:
                    [
                        {
                            value: 4,
                            label: 'lblOcupationActivities4',

                        },
                        {
                            value: 1,
                            label: 'lblOcupationActivities1',

                        },
                        {
                            value: 2,
                            label: 'lblOcupationActivities2',

                        },
                        {
                            value: 3,
                            label: 'lblOcupationActivities3',

                        },
                    ]
            }
        },
        activities : {
            type: 'switch',
            nextPageId: {
                sequence:
                    [{
                        result: 'whatSports',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'activities',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'insuranceOwnership',
                    }]
            },
            laraMessage: ['laraActivities', 'laraActivities2'],
            desktopMessage: ['laraActivities'],
            userMessage: 'userActivities',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblMotorcycle',
                    },
                    {
                        value: 2,
                        label: 'lblSports',
                    },
                ]
            }
        },
        whatSports: {
            laraMessage: ['laraSports'],
            desktopMessage : ['laraSports'],
            userMessage: 'userSports',
            nextPageId: 'insuranceOwnership',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectSports',
                list: 'sportList',
                subType : 'sports',
            }
        },
        insuranceOwnership: {
            type: 'radio',
            nextPageId: {
                sequence: [

                    {
                        result: 'bornDateOther',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],

                    },
                    {
                        result: 'accept',
                    }]
            },
            laraMessage: ['laraInsuranceOwnership'],
            desktopMessage: ["laraInsuranceOwnership"],
            userMessage: 'userInsuranceOwnership',
            options: {
                otherValues: { otherGender: 'gender' },
                items: [
                    {
                        value: 0,
                        label: 'lblInMyName',
                        otherValues: { gender: 'U' }
                    },
                    {
                        value: 1,
                        label: 'lblInMyNameAndWife',
                        otherValues: { gender: 'F' }
                    },
                    {
                        value: 2,
                        label: 'lblInMyNameAndHusband',
                        otherValues: { gender: 'M' }
                    },
                    {
                        value: 3,
                        label: 'lblInMyNameAndOther',
                        otherValues: { gender: 'U' }
                    },
                ]
            }
        },
        bornDateOther: {
            type: 'date',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraOtherBornDate2F', 'laraOtherBornDateF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDate2M', 'laraOtherBornDateM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDate2', 'laraOtherBornDate'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraOtherBornDateF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDateM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOtherBornDate'],
                    }]
            },
            userMessage: 'userBornDateOther',
            nextPageId: 'ocupationOther',
            options: {
                onBirthday: "Parabéns! 🎉🍾",
                onAlmostBirthday: "Uau. Está quase a fazer anos 😀",
                validation: {
                    type: "AGE",
                    min: 18,
                    max: 65,
                    minText: "Não podemos apresentar uma proposta se não tiver 18 anos",
                    maxText: "Não podemos apresentar uma proposta se tiver mais do que 65 anos"
                },
                label: 'lblBorndDate',
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 30,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 17,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 90,
                            },
                        ]
                    }
                }
            }
        },
        ocupationOther: {
            type: 'ocupation',
            nextPageId: 'ocupationActivitiesOther',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHappyBirthday', 'laraOcupationOtherF'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraHappyBirthday', 'laraOcupationOtherM'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraHappyBirthday', 'laraOcupationOther'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                        ],
                    },
                    {
                        result: ['laraAlmostBirthday', 'laraOcupationOtherF'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraAlmostBirthday', 'laraOcupationOtherM'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraAlmostBirthday', 'laraOcupationOther'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDateOther',
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOther'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraOcupationOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationOther'],
                    }]
            },
            userMessage: 'userOcupationOther',
            options: {
                genderValue: 'otherGender'
            }
        },
        ocupationActivitiesOther: {
            type: 'ocupationActivities',
            nextPageId: "activitiesOther",
            laraMessage: {
                sequence:
                    [{
                        result: ['laraOcupationActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOther'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraOcupationActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraOcupationActivitiesOther'],
                    }]
            },
            userMessage: 'userOcupationActivitiesOther',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                        {
                            value: 2,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                        {
                            value: 3,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                        {
                            value: 4,
                            text: {
                                genderValue: 'otherGender',
                                "F": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
                                "M": 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido'
                            }

                        },
                    ]
                },
                items:
                    [
                        {
                            value: 1,
                            label: 'lblOcupationActivitiesOther1',

                        },
                        {
                            value: 2,
                            label: 'lblOcupationActivitiesOther2',

                        },
                        {
                            value: 3,
                            label: 'lblOcupationActivitiesOther3',

                        },
                        {
                            value: 4,
                            label: 'lblOcupationActivitiesOther4',

                        },
                    ]
            }
        },
        activitiesOther : {
            type: 'switch',
            nextPageId: {
                sequence:
                    [{
                        result: 'whatSportsOther',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'activitiesOther',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'accept',
                    }]
            },
            laraMessage: {
                sequence:
                    [{
                        result: ['laraActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOther'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraActivitiesOtherF'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOtherM'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'otherGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'M',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraActivitiesOther'],
                    }]
            },
            userMessage: 'userActivitiesOther',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblMotorcycleOther',
                    },
                    {
                        value: 2,
                        label: 'lblSportsOther',
                    },
                ]
            }
        },
        whatSportsOther: {
            laraMessage: ['laraSports'],
            desktopMessage : ['laraSports'],
            userMessage: 'userSportsOther',
            nextPageId: 'accept',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectSports',
                list: 'sportList',
                subType : 'sports',
            }
        },
        accept: {
            type: 'next',
            nextPageId: 'remainingDebtValue',
            userMessage: "lblLetsGo",
            laraMessage: ['laraCommentLifeTransfer'],
            desktopMessage: ['laraCommentLifeTransfer'],
            options: {
                label: "lblContinue",
            }
        },
        remainingDebtValue: {
            type: 'input',
            refreshProposal: true,
            laraMessage: ['laraRemainingDebtValue'],
            desktopMessage: ['laraRemainingDebtValue'],
            nextPageId: 'insuranceInBank',
            userMessage: 'userRemainingDebtValue',
            options: {
                type: 'currency',
                decimals: 0,
                label: 'lblRemainingDebtValue',
                min: 5000,
                minWarn: 'O valor mínimo são 5.000€',
                max: 3000000,
                maxWarn: 'Uff, o valor máximo são 3 milhões de euro',
            }
        },
        insuranceInBank: {
            type: 'radio',
            nextPageId: "knowCurrentInsuranceCost",
            laraMessage: ['laraInsuranceInBank'],
            desktopMessage: ['laraInsuranceInBankDesktop'],
            userMessage:
            {
                sequence:
                    [{
                        result: 'userInsuranceInBankF',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceInBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'bankGender',
                                    },
                                    {
                                        type: 'constant',
                                        value: 'F',
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userInsuranceInBankM',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceInBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userInsuranceInBankNo',
                    }]
            },
            options: {
                columns: true,
                onDesktopChange: {
                    items: [
                        {
                            value: 2,
                            text: 'Óptimo. Pode transferir o seu seguro sem nenhuma preocupação',

                        },
                    ]
                },
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'insuranceBank',
                                title: 'Em que banco tem o seu empréstimo?',
                                type: 'radio',
                                list: 'bankList',
                                otherValues: { bankGender: 'gender', bankItp: 'itp' },
                            }]
                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                ]
            },
        },
        knowCurrentInsuranceCost: {
            type: 'radio',
            nextPageId: {
                sequence: [
                    {
                        result: 'insuranceBank',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceInBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'trip',
                    }]
            },
            laraMessage:
            {
                sequence:
                    [{
                        result: ['laraInsuranceInBankResponse', 'laraKnowCurrentInsuranceCost'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceInBank',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraKnowCurrentInsuranceCost'],
                    }]
            },
            desktopMessage: ["laraKnowCurrentInsuranceCostDesktop"],
            userMessage: {
                sequence:
                    [{
                        result: 'userKnowCurrentInsuranceCost',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'knowCurrentInsuranceCost',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'userKnowCurrentInsuranceCostNo',
                    }]
            },
            options: {
                followupInline: true,
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                        followup: [
                            {
                                id: 'currentInsuranceCost',
                                title: 'Quanto paga pelo seu seguro?',
                                type: 'text',
                                options: {
                                    maxChars: 160,
                                    type: 'currency',
                                    label: 'Valor em Euro',
                                    min: 5,
                                    minWarn: 'Parece-me um pouco baixo. Tente novamente',
                                    max: 3000,
                                    maxWarn: 'Parece-me um pouco elevado. Tente novamente',
                                }
                            }]
                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                ]
            }
        },
        insuranceBank: {
            laraMessage: ['laraInsuranceBank', 'laraInsuranceBank2'],
            desktopMessage: ['laraInsuranceBank'],
            desktopMessageSecondary: ['laraInsuranceBank2'],
            userMessage: 'userInsuranceBank',
            nextPageId: 'trip',
            type: 'fullRadio',
            options: {
                label: 'lblSelectBank',
                list: 'bankList',
                otherValues: { bankGender: 'gender', bankItp: 'itp' },
            }

        },
        trip: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'tripWhere',
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'trip',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'generateProposal',
                        }
                    ]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraTripTwo'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTransferTripDesktop'],
                    }]
            },
            laraMessage: {
                sequence:
                    [{
                        result: ['laraTripTwo'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTransferTripDesktop'],
                    }]
            },
            userMessage: 'userTrip',
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                    {
                        value: 3,
                        label: 'lblDontKnowYet',

                    },
                ]
            }
        },
        tripWhere: {
            laraMessage: {
                sequence:
                    [{
                        result: ['laraTripWhereTwo'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTripWhere'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraTripWhereTwo'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraTripWhere'],
                    }]
            },
            userMessage: 'userTripWhere',
            nextPageId: 'generateProposal',
            type: 'fullSwitch',
            options: {
                label: 'lblSelectCountries',
                list: 'countryList',
            }
        },
        generateProposal: {
            type: 'loader',
            generateProposal: true,
            laraMessage: ['laraProposal'],
            desktopMessage: ['laraProposal'],
            userMessage: {
                sequence:
                    [{
                        result: null,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EMAIL_USER',
                            }
                        ],
                    },
                    {
                        result: null,
                    }]
            },
            options: {
                barNumber: 4,
                messages: [ "A analisar os <br/><b> seus dados</b>",
                "A procurar a <br/> <b> melhor proposta </b>",
                "A calcular  <br/><b> descontos</b>",
                "Um pouco de  <br/><b> magia uthere 😉</b>",
                "Encontrei a<br/><b>sua proposta</b>"],
                interval: 30
            },
            nextPageId: "showProposal",
        },
        showProposal: {
            allowBack : false,
            type: 'proposal',
            options: {
                subType: 'show',
            },
            nextPageId: "personalData",
            laraMessage: ['laraProposalShow'],
            desktopMessage: ['laraProposalShow'],
            userMessage: 'userProposal',
            userMessageOptions: {
                valueId: 'selectedProposal',
                type: 'window',
                items: [
                    {
                        id: 'selectedProposal',
                        type: 'selectedProposal',
                        titleLabel: 'Proposta'
                    },
                ]
            }

        },
        personalData: {
            type: 'form',
            allowBack: false,
            userMessage: 'userPersonalDataNoName',
            laraMessage: {
                sequence:
                    [{
                        result: ['laraPersonalDataBuyMinutes','laraPersonalDataBuyTwo'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraPersonalDataDesktop'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraPersonalDataBuyTwo'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: ['laraPersonalDataDesktop'],
                    }]
            },
            nextPageId: 'questionnaireCheckout',
            userMessageOptions: {
                valueId: 'personalData',
                type: 'window',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais'
                    },
                ]
            },
            options: {
                showReview: true,
                buttonLabel: 'Dados pessoais',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais',
                        preValues: {
                            firstName: 'name',
                            familyNames: 'familyNames',
                            email: 'email',
                        }
                    },
                ]
            }
        },
        questionnaireCheckout: {
            type: 'questionnaire',
            userMessage: 'userQuestionnaire',
            laraMessage: ['laraQuestionaireName'],
            desktopMessage: ['laraQuestionaireName'],
            nextPageId: {
                sequence: [
                    {
                        result: 'personalDataOther',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'insuranceOwnership',
                                    },
                                    {
                                        type: 'constant',
                                        value: 0,
                                    },
                                ]
                            }
                        ],

                    },
                    {
                        result: 'paymentData',
                    }]
            },
            valueId: 'questionnaire',
            userMessageOptions: {
                type: 'window',
                valueId: 'questionnaire',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        type: 'questionnaire',
                    }
                ]
            },
            options: {
                buttonLabel: 'Dados de saúde',
                genderValue : 'gender',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        text : 'Estamos quase. Vou também precisar de alguns dados de gerais de saúde',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        text : 'Tem alguma doença ou fez alguma cirurgia?',
                        type: 'questionnaire',
                    },
                ]
            }
        },
        personalDataOther: {
            type: 'form',
            userMessage: 'userPersonalDataOther',
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraPersonalDataOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOtherM'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'M',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOther'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraPersonalDataOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOtherM'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'M',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraPersonalDataOther'],
                        }]
            },
            nextPageId: 'questionnaireOther',
            userMessageOptions: {
                valueId: 'personalDataOther',
                type: 'window',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais',
                    },
                ]
            },
            options: {
                showReview: true,
                buttonLabel: 'Dados pessoais',
                items: [
                    {
                        id: 'personalData',
                        type: 'form',
                        titleLabel: 'Dados pessoais',
                        preValues: {
                            firstName: 'otherName',
                            familyNames: 'otherFamilyNames',
                        }
                    },
                ]
            }
        },
        questionnaireOther: {
            type: 'questionnaire',
            userMessage: 'userQuestionnaireOther',
            laraMessage: {
                sequence:
                    [
                        {
                            result: ['laraQuestionaireOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraQuestionaireOtherM'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'M',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraQuestionaireOther'],
                        }]
            },
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraQuestionaireOtherF'],
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'otherGender',
                                        },
                                        {
                                            type: 'constant',
                                            value: 'F',
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraQuestionaireOther'],
                        }]
            },
            nextPageId: 'paymentData',
            userMessageOptions: {
                type: 'window',
                valueId: 'questionnaireOther',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e cirurgias',
                        type: 'questionnaire',
                    }
                ]
            },
            options: {
                buttonLabel: 'Dados de saúde',
                items: [
                    {
                        id: 'questionnaire1',
                        titleLabel: 'Dados gerais',
                        text:
                        {
                            value: "otherGender",
                            M: 'Agora vou precisar de alguns dados de gerais de saúde do seu marido',
                            F: 'Agora vou precisar de alguns dados de gerais de saúde da sua mulher',
                            U: 'Agora vou precisar de alguns dados de gerais de saúde da segunda pessoa',
                        },
                        type: 'questionnaire',
                    },
                    {
                        id: 'questionnaire2',
                        titleLabel: 'Doenças e Cirurgias',
                        text:
                        {
                            value: "otherGender",
                            M: 'O seu marido, tem alguma doença ou fez alguma cirurgia?',
                            F: 'A sua mulher, tem alguma doença ou fez alguma cirurgia?',
                            U: 'A segunda pessoa, tem alguma doença ou fez alguma cirurgia?',
                        },
                        type: 'questionnaire',
                    },
                ]
            }
        },
        paymentData: {
            type: 'paymentData',
            laraMessage: ['laraPaymentTransfer', 'laraPaymentData1'],
            desktopMessageSecondary: ['laraPaymentDataDesktopSecondaryTransfer'],
            desktopMessage: ['laraPaymentDataDesktop'],
            userMessage: 'userPaymentData',
            nextPageId: 'endSimulation',
            submit: {
                label: "lblSendingPropostal",
                state: 2
            }
        },
        endSimulation: {
            disableExitConfirm: true,
            last: true,
            desktopMessage:
            {
                sequence:
                    [{
                        result: ['laraEndSimulation2'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'analiseTecnica',
                                    },
                                    {
                                        type: 'constant',
                                        value: true,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraEndSimulation2'],
                    }]
            },
            laraMessage: ['laraEndSimulation', 'laraEndSimulation2'],
            hideAvatar: true,
            type: 'rate',
            userMessage: 'userMessageEndSimulation',
            nextPageId: 'exitSimulation',
            allowBack: false,
            allowRestart: false,
        },
        exitSimulation: {
            hideAvatar: true,
            laraMessage: {
                sequence:
                    [{
                        result: ['laraExitSimulation'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'rateStarts',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraExitSimulationBad'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraExitSimulation'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'GREATER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'rateStarts',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraExitSimulationBad'],
                    }]
            },
            disableExitConfirm: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
        }
    }
};
