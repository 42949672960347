import './HomeTestimony.scss';

/**
* HomeTestimony
*/
function HomeTestimony(props) {
    return (
        <div className='home-testimony-container'>
            <div className='home-title-container home-title-testimony'>
                {props.branch === 1 ?
                    <h2><span className='bold'>Como ajudámos</span><br /> o João</h2>
                    : props.branch === 3 ?
                        <h2><span className='bold'>Como ajudámos</span><br /> a Paula e o Luís</h2>
                        : props.branch === 4 ?
                            <h2><span className='bold'>Como ajudámos</span><br /> o António</h2>
                            : null
                }
            </div>
            <div className='home-testimony-container-main'>
                <div
                    className={`
                ${props.branch === 1 ? "home-testimony-container-main-background-life" : props.branch === 3 ? "home-testimony-container-main-background"
                            : props.branch == 4 ? "home-testimony-container-main-background-auto" : null
                        }`}></div>
                <div className='home-testimony-container-main-content'>
                    <div className='home-testimony-container-main-sub-content'>
                        <div className='home-testimony-container-main-sub-sub-content'>
                            <div className='home-testimony-container-main-sub-sub-content-box'>
                                {props.branch === 1 ?
                                    <div className='home-testimony-container-main-sub-sub-content-box-text'>
                                        'Não queria acreditar que poderia poupar tanto. E eu não tive que fazer nada, a uthere tratou da transferência. Em menos de 5 minutos tinha começado a poupar 600€ por ano'<br /><br />Há 10 anos o João pediu um empréstimo para compra da sua casa de sonho. E comprou o seguro de vida que o banco pediu.<br /><br />No final de Junho, quando olhou para o seu extrato bancário percebeu pela primeira vez que estava, de facto, a pagar muito.<br /><br />Um amigo falou-lhe da uthere. Abriu o site, e em poucos minutos poupou 65%. Pagava 76€ por mês e passou a pagar 26€ por mês
                                    </div> :
                                    props.branch === 3 ? <div className='home-testimony-container-main-sub-sub-content-box-text'>
                                        Em 2 dias recebemos a visita do perito que detectou um problema na canalização entre a cozinha e a sala. A seguradora pagou €3.350 do orçamento total de €3.500 e nós pagamos apenas o valor da franquia (€150).<br /><br />Todo o processo demorou 6 dias, mais 15 dias para as obras de arranjo da canalização.<br /><br />Sem um Seguro Multirriscos não teríamos conseguido poupar tanto dinheiro. Isto só foi possível porque a uthere ajudou-nos a perceber qual o melhor seguro para as nossas necssidades.
                                    </div>
                                        :
                                        props.branch === 4 ? <div className='home-testimony-container-main-sub-sub-content-box-text'>
                                        "Saímos cedo de casa, desejosos de começar as nossa férias de verão.<br /><br />
                                        A meio da viagem, o carro parou. Sem saber o que se passou, os meus filhos começaram a ficar irrequietos, Não poderia ser um prior início de férias.<br /><br />
                                        Mas bastou aceder ao site da uthere, que em poucos segundos me pôs em contacto com a assistência em viagem da minha seguradora.<br /><br />
                                        Eu nem precisei procurar o número de telefone.<br /><br />
                                        O carro foi levado para a oficina e nós fomos levados até ao nosso destino de férias."
                                        </div> : null
                                }
                                <svg width="auto" height="auto" viewBox="0 0 85 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.7239 56.4584C23.9032 56.4584 25.9116 55.0688 26.8517 52.9126L32.9197 39.3043C33.518 37.9626 33.8171 36.5251 33.8171 35.0397V13.3334C33.8171 10.698 31.8941 8.54175 29.5439 8.54175H12.451C10.1007 8.54175 8.17773 10.698 8.17773 13.3334V32.5001C8.17773 35.1355 10.1007 37.2917 12.451 37.2917H20.9974L16.596 47.1626C14.673 51.4272 17.4506 56.4584 21.7239 56.4584ZM64.4561 56.4584C66.6354 56.4584 68.6439 55.0688 69.584 52.9126L75.6519 39.3043C76.2502 37.9626 76.5493 36.5251 76.5493 35.0397V13.3334C76.5493 10.698 74.6264 8.54175 72.2761 8.54175H55.1832C52.8329 8.54175 50.91 10.698 50.91 13.3334V32.5001C50.91 35.1355 52.8329 37.2917 55.1832 37.2917H63.7296L59.3282 47.1626C57.4053 51.4272 60.1829 56.4584 64.4561 56.4584Z" fill="#1F3DEB" />
                                </svg>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HomeTestimony;