import { createSlice } from '@reduxjs/toolkit';

export const processesSlice = createSlice({
  name: 'processes',
  initialState: {
    list: {}, 
  },
  reducers: {
    setProcess: (state, action) => {
      state.list[action.payload.processId] = action.payload.process;
    },
    setProcesses: (state, action) => {
      if (action.payload && action.payload.processes) {
        let processKeysToAppend = Object.keys(action.payload.processes);
        for (let i = 0; i < processKeysToAppend.length; i++) {
          state.list[processKeysToAppend[i]] = action.payload.processes[processKeysToAppend[i]];
        }
      }
    },
    readProcesses: (state) => {
      let keys = Object.keys(state.list);
      for (let i = 0; i < keys.length; i++) {
        state.list[keys[i]].read = true;
      }
    },

    readProcess: (state, action) => {
      if (action.payload && action.payload.processId) {
        let keys = Object.keys(state.list);
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === action.payload.processId) {
            state.list[keys[i]].read = true;
          }
          break;
        }
      }
    },
    cancelProcess: (state, action) => {
      if (action.payload && action.payload.processId) {
        let keys = Object.keys(state.list);
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === action.payload.processId) {
            state.list[keys[i]].state = 0;
          }
          break;
        }
      }
    },
    removeData: (state) => {
      state.list = {};
    },
  },
});
export const { setProcess, setProcesses, removeData, readProcesses, readProcess, cancelProcess } = processesSlice.actions;

export const selectProcesses = (state) => state.list;

export default processesSlice.reducer;