import './ChatButton.scss';
import { isMobile } from '../../../../utils/DeviceUtils';
import UthereLoader from '../../../common/uthere-loader/UthereLoader';

/**
* Chat Button
*/
function ChatButton(props) {

    const device = isMobile() ? "mobile" : "desktop";

    const handleButtonPress = async () => {
        if ((device === "desktop" && props.loading)) {
        }
        else if (props.handleButtonPress) {
            props.handleButtonPress();
        }
    }
    return (
        <button type="submit" className={`
        ${props.full ? "chat-button-full" : ""}
        ${device === "desktop" || props.desktop ? "button-basic-desktop chat-button-animation-hover" : "button-basic"}
        ${(device === "desktop" || props.desktop) && props.modal ? "button-basic-desktop-modal" : null}
        ${(device === "desktop" || props.desktop) && props.secondary ? "button-basic-desktop-secondary" : null}
        ${(device === "desktop" || props.desktop) && props.loading && !props.color ? "button-basic-desktop-loading" : null}
        ${props.buttonPressed && !props.disableAnimate && device === "mobile" ? "button-basic-active" : ""}`}
            style={{ background: props.color }}
            onClick={handleButtonPress} disabled={props.disabled}>
            {!props.loading && (device === "mobile" && !props.desktop) ?
                <strong style={{ color: props.labelColor }}>
                    {props.label ? props.label : 'Confirmar'}
                </strong> : null}
            {(device === "desktop" || props.desktop) && !props.content ?
                <strong className={`
                ${props.loading ? "button-label-not-visible" : ""}
                ${(device === "desktop" || props.desktop) && props.loading ? "button-loader-loading" : ""}`}
                    style={{ color: props.labelColor }}>
                    {props.label ? props.label : 'Confirmar'}
                </strong> : null}
            <span></span>
            {props.loading ?
                <div className={`
                ${device === "desktop" || props.desktop ? "button-loader-container" : "button-loader-container-mobile"}`}>
                    &nbsp;<UthereLoader process={true} /> </div> : null}
            {props.content ?
                props.content
                : null}
        </button>
    );
};
export default ChatButton;