import Lottie from 'react-lottie';

/**
 * HomeHome
 */
function UthereLottie(props) {

    const windowWidth = window.innerWidth;
    const height = windowWidth < 341 ? 401 : windowWidth < 992 ? 432 : windowWidth < 1200 ? 504 : 560;
    const width = windowWidth < 341 ? 264 : windowWidth < 992 ? 284 : windowWidth < 1200 ? 332 : 368;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: props.src,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div>
            <Lottie
                options={defaultOptions}
                height={height}
                width={width}
            />
        </div>
    )
};
export default UthereLottie;


