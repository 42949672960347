import './HomeAboutUthere.scss';
import 'swiper/css';
import "swiper/css/pagination";
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Pagination, Navigation } from "swiper";
import { isTouch } from '../../../utils/DeviceUtils';

/**
* AboutUthere
*/
function HomeAboutUthere(props) {

    const info = props.branch == 4 ?
    [
        {
            class: "about-uthere-card-auto",
            title: "Trabalhamos para si",
            text: "<span class='bold'>Procuramos o melhor para si.<br/>Depois é só escolher</span>"
        },
        {
            class: "about-uthere-card-auto-2",
            title: "À sua medida",
            text: "<span class='bold'>O seu seguro. <br/>O seu carro.<br/>As coberturas que quiser</span>" 
        },
        {
            class: "about-uthere-card-auto-3",
            title: "Descomplicamos",
            text: "<span class='bold'>Em português?<br/>Claro!</span>"
        },
        {
            class: "about-uthere-card-auto-4",
            title: "Fácil de comunicar",
            text: "<span class='bold'>Estamos sempre disponíveis.<br/>E nunca pedimos a mesma coisa duas vezes</span>"
        },
        {
            class: "about-uthere-card-auto-5",
            title: "Tudo no mesmo sitio",
            text: "<span class='bold'>Todos os seus seguros,<br/>independente da sua seguradora</span>"
        },
    ] :
    [
        {
            class: "about-uthere-card-woman",
            title: "Trabalhamos para si",
            text: "<span class='bold'>Procuramos no mercado o melhor para si.</span><br/>Depois é só escolher"
        },
        {
            class: "about-uthere-card-living-room",
            title: "À sua medida",
            text: props.branch === 1 ?
                "<span class='bold'>O seu seguro. <br/>A sua vida. </span><br/>As coberturas que quiser" :
                "<span class='bold'>O seu seguro. <br/>A sua casa. </span><br/>As coberturas que quiser"
        },
        {
            class: "about-uthere-card-man",
            title: "Descomplicamos",
            text: "<span class='bold'>Em português?<br/>Claro!</span>"
        },
        {
            class: "about-uthere-card-woman-2",
            title: "Fácil de comunicar",
            text: "<span class='bold'>Estamos sempre disponíveis.</span><br/>E nunca pedimos a mesma coisa duas vezes"
        },
        {
            class: "about-uthere-card-shirt",
            title: "Tudo no mesmo sitio",
            text: "<span class='bold'>Todos os seus seguros, </span><br/>independente da sua seguradora"
        },
    ];

    const swiperProps = {
        allowTouchMove: true,
        initialSlide: 0,
        speed: 800,
        slidesPerView: "auto",
        spaceBetween: 24,
        loop: true,
        centeredSlides: true,
        breakpoints: {
            576: {
            },
            768: {
                spaceBetween: 32,
            },
            992: {
                spaceBetween: 40,
            },
            1200: {
                spaceBetween: 48,
            },
        },
        pagination: {
            clickable: true,
            el: '#aboutUthereSwiperPagination',
            renderBullet: (index, className) => {
                return '<span' +
                    ' class="proposal-home-swiper-pagination-bullet ' + className + '"></span>';
            }
        },
        navigation: {
            prevEl: '#aboutUtherePaginationPrevious',
            nextEl: '#aboutUtherePaginationNext',
        },
    }

    return (
        <div className='about-uthere-container'>
            <div className='home-title-container about-uthere-container-title about-uthere-container-title-padding'>
                <h2>Somos um <span className='bold'>mediador de seguros</span> diferente</h2>
            </div>
            <div className='about-uthere-container-swipper'>
                <Swiper modules={[Controller, Pagination, Navigation]}
                    {...swiperProps}>
                    {info.map((item, i) => (
                        <SwiperSlide className='about-uthere-slide' key={i}>
                            <div className={`about-uthere-card ${item.class}`}>
                                <div className='about-uthere-card-texts'>
                                    <div className='about-uthere-card-texts-title'>{item.title ? parse(item.title) : null}</div>
                                    <div className='about-uthere-card-texts-text'>{item.text ? parse(item.text) : null}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className='home-swiper-pagination home-swiper-navigation about-uthere-card-swiper-pagination-2'>
                    {!isTouch() ?
                        <svg id="aboutUtherePaginationPrevious" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6668 33.333L13.3335 19.9997L26.6668 6.66634" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> : null}
                    <div className='about-uthere-card-swiper-pagination'>
                        <div className="about-uthere-card-swiper-pagination-bullets" id="aboutUthereSwiperPagination"></div>
                    </div>
                    {!isTouch() ? <svg id="aboutUtherePaginationNext" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3332 6.66699L26.6665 20.0003L13.3332 33.3337" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> : null}
                </div>
            </div>
        </div>
    );
};
export default HomeAboutUthere;