import './HomePartners.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper";


import Allianz from '../../../assets/home/partners/allianz.png';
import Una from '../../../assets/home/partners/una.png';
import Real from '../../../assets/home/partners/real.png';
import Metlife from '../../../assets/home/partners/metlife.png';
import Tranqulidade from '../../../assets/home/partners/tranquilidade.png';
/**
* HomePartners
*/
function HomePartners() {

    const partners = [
        {
            alt: 'Allianz',
            src: Allianz,
            class: 'partners-slide-allianz',
        },
        {
            alt: 'Una',
            class: 'partners-slide-una',
            src: Una,
        },
        {
            alt: 'MetLife',
            class: 'partners-slide-metlife',
            src: Metlife,
        },
        {
            alt: 'Real',
            class: 'partners-slide-real',
            src: Real,
        },
        {
            alt: 'Tranquilidade',
            class: 'partners-slide-tranquilidade',
            src: Tranqulidade,
        },
    ]
    return (
        <div className='partners-container'>
            <div className='home-title-container about-uthere-container-title'>
                <h2>Trabalhamos com<br /><span className='bold'>óptimas seguradoras!</span></h2>
            </div>
            <div className='partners-container-swipper-home'>
                <Swiper
                    spaceBetween={48}
                    allowTouchMove={false}
                    slidesPerView={'auto'}
                    loop={true}
                    speed={3000}
                    autoplay={{
                        delay: 0,
                    }}
                    modules={[Autoplay]}
                    breakpoints={{
                        375: {
                            speed: 3000,
                            spaceBetween: 56,
                        },
                        768: {
                            speed: 2000,
                        },
                        992: {
                            speed: 4400,
                            spaceBetween: 96,
                        },
                    }}>
                    <div>
                        {partners.map((item, i) => (
                            <SwiperSlide key={i} className={`${item.class}`}>
                                <picture>
                                    <img src={item.src} alt={item.alt} />
                                </picture>
                            </SwiperSlide>
                        ))}
                    </div>
                </Swiper>
            </div>
        </div>
    );
};
export default HomePartners;