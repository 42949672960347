import { store } from '../store/store';
import { isoToUthereDate } from '../utils/DateUtils';
import { auth, getFunctions } from '../firebase/UthereFirebase';
import { generateUid, cloneObject } from '../utils/GeneralUtils';
import { getCookie } from '../logic/cookies/CookiesLogic';
/**
 * 
 * @param {*} nif 
 * @returns 
 */
export function userExists(nif, sendEmail) {
    return new Promise(async function (resolve, reject) {
        try {
            let uthereUserExistsInput = {
                nif: nif,
                sendEmail: sendEmail
            };
            let functions = await getFunctions();
            let functionsImpl = await import("firebase/functions");
            const uthereUserExists = functionsImpl.httpsCallable(functions, 'uthereUserExists');
            let result = await uthereUserExists(uthereUserExistsInput);
            if (result && result.data) {
                resolve(result.data);
            }
            else {
                resolve(null);
            }
        }
        catch (e) {
            reject();
        }
    });
}

export function getUserData() {
    let dataToReturn = {};
    if (store.getState().user) {
        dataToReturn = store.getState().user;
    }
    return dataToReturn;

}


/**
 * 
 * @param {*} email 
 * @param {*} password 
 * @returns 
 */
export function userSingIn(email, password, fromProcess) {
    return new Promise(async function (resolve, reject) {
        if (auth.currentUser && !auth.currentUser.isAnonymous) {
            resolve();
        }
        else {
            let authImp = await import("firebase/auth");
            authImp.signInWithEmailAndPassword(auth, email, password).then(async function () {
                resolve()
            }).catch((error) => {
                reject(error);
            });
        }
    });
}

export function logOut() {
    return new Promise(async function (resolve, reject) {
        try {
            import("../firebase/UthereFirebaseListeners").then(UthereFirebaseListeners => {
                UthereFirebaseListeners.unsubscribe();
            });
            let authImp = await import("firebase/auth");
            await authImp.signOut(auth);
            store.dispatch({ type: 'processes/removeData' });
            store.dispatch({ type: 'user/removeData' });
            store.dispatch({ type: 'insurances/removeData' });
            resolve();
        }
        catch (e) {
            reject(e);
        }
    });
}

/**
 * Gets the user id in session, or null if is not in session
 * @returns the id of the logged user
 */
export function getUserId() {
    if (auth.currentUser) {
        return auth.currentUser.uid;
    }
    return null;
}

/**
 * Gets the email in session, or null if is not in session
 * @returns
 */
export function getUserEmail() {
    if (auth && auth.currentUser) {
        return auth.currentUser.email;
    }
    return null;
}

export function isUthereUser() {
    return getUserType() === "uthereUser";
}

/**
 * Returns the type of user in session
 * @returns the user type
 */
export function getUserType() {
    if (auth && auth.currentUser) {
        return "uthereUser";
    }
    else {
        return "guest";
    }
};
/**
 * Register the anonymous user during the simulaiton
 */
export function registerUserInSimulation(email) {
    return new Promise(async function (resolve, reject) {
        try {

            if (auth.currentUser && auth.currentUser.isAnonymous) {
                let authImp = await import("firebase/auth");
                let credential = authImp.EmailAuthProvider.credential(email, generateUid());
                const prevUser = auth.currentUser;
                await authImp.linkWithCredential(prevUser, credential)
                resolve();
            }
            else {
                let authImp = await import("firebase/auth");
                await authImp.createUserWithEmailAndPassword(auth, email, generateUid());
                resolve();
            }
        }
        catch (e) {
            reject(e);
        }
    });
};
/**
 * 
 * @param {*} user 
 * @returns 
 */
export function mapUserDataFromProcess(processValues) {
    let dataToReturn = {
        personalData: {}
    }
    let hasUserData = false;
    if (processValues) {
        if (processValues.name) {
            hasUserData = true;
            dataToReturn.personalData.name = processValues.name;
        }
        if (processValues.email) {
            hasUserData = true;
            dataToReturn.personalData.email = processValues.email;
        }
        if (processValues.gender) {
            hasUserData = true;
            dataToReturn.personalData.gender = processValues.gender;
        }
        if (processValues.familyNames) {
            hasUserData = true;
            dataToReturn.personalData.familyNames = processValues.familyNames;
        }
        if (processValues.bornDate) {
            hasUserData = true;
            dataToReturn.personalData.bornDate = processValues.bornDate;
        }
    }
    let tempEmbName = getCookie("emb");
    if (tempEmbName) {
        dataToReturn.embaixador = tempEmbName;
    }
    return hasUserData ? dataToReturn : null;
}

export function getPresonalData() {
    if (store.getState().user) {
        return cloneObject(store.getState().user.personalData);
    }
}

/**
 * 
 */
export function getPersonalDataForProcess() {
    let objectToReturn = {
        userValues: {},
        userTexts: {},
    }
    if (store.getState().user) {
        let userData = store.getState().user;
        if (userData) {
            if (userData.personalData.name) {
                objectToReturn.userValues.name = userData.personalData.name;
                objectToReturn.userTexts.name = userData.personalData.name;
            }
            if (userData.personalData.familyNames) {
                objectToReturn.userValues.familyNames = userData.personalData.familyNames;
                objectToReturn.userTexts.familyNames = userData.personalData.familyNames;
            }
            if (userData.gender) {
                objectToReturn.userValues.gender = userData.personalData.gender;
            }
            if (userData.personalData.bornDate) {
                objectToReturn.userValues.bornDate = userData.personalData.bornDate;
                objectToReturn.userTexts.bornDate = isoToUthereDate(userData.personalData.bornDate);
            }
            if (userData.personalData.nif) {
                objectToReturn.userValues.nif = userData.personalData.nif;
                objectToReturn.userTexts.nif = userData.personalData.nif;
            }
            if (userData.personalData.tel) {
                objectToReturn.userValues.tel = userData.personalData.tel;
                objectToReturn.userTexts.tel = userData.personalData.tel;
            }
            if (userData.personalData.documentId) {
                objectToReturn.userValues.documentId = userData.personalData.documentId;
                objectToReturn.userTexts.documentId = userData.personalData.documentId;
            }
            if (getUserEmail()) {
                objectToReturn.userValues.email = getUserEmail();
            }
        }
    }
    return objectToReturn;
}