import { createSlice } from '@reduxjs/toolkit';

export const carsSlice = createSlice({
  name: 'auto',
  initialState: {
    currentPlate: {},
    allInfo: {},
    proposalOk: {},
    plates: {},
    processesState: {}

  },
  reducers: {
    setCurrentPlate: (state, action) => {
      state.currentPlate[action.payload.processId] = action.payload.plate;
      state.allInfo[action.payload.processId] = false;
    },
    setPlateInfo: (state, action) => {
      let allInfo = false;



      if (action.payload.data) {

        if (action.payload.data.tranquilidade && (action.payload.data.tranquilidade.listOfVersions || !action.payload.data.error)) {
          allInfo = true;
        }
        else
          if (action.payload.data.needManualVersion && action.payload.data.allianz && action.payload.data.models) {
            allInfo = true;
          }
          else if (action.payload.data.invalidCategory) {
            allInfo = true;
          }
      }
      state.plates[action.payload.plate] = action.payload.data;
      state.allInfo[action.payload.processId] = allInfo;
    },
    setProcessState: (state, action) => {
      state.processesState[action.payload.processId] = action.payload.data;
    },
  },
});
export const { setPlateInfo, setProcessState, setCurrentPlate } = carsSlice.actions;



export default carsSlice.reducer;