export const otherClaimFlow = {
    begin: "insurer",
    pages: {
        insurer: {
            laraMessage: ['luisWantToPhone', 'luisInsurer4'],
            blockSave: true,
            userMessage: 'userInsurer',
            nextPageId: 'exit',
            type: 'fullRadio',
            blockSave: true,
            options: {
                label: 'lblInsurerName',
                list: 'insurerLifeList',
                otherValues: { insurerGender: 'gender', insurerPhone : 'phone' , insurerEmail : "email" },
            }
        },
        exit: {
            type: 'exit',
            blockSave: true,
            last: true,
            allowBack: false,
            laraMessage : ['luisInsurerData', 'luisInsurerData2'],
            options: {
                label: 'lblExit',
                cancel: true,
            }
        },
    }
}