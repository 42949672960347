import './HomeOtherInsurance.scss';
import 'swiper/css';
import "swiper/css/pagination";
import parse from 'html-react-parser';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Pagination, Navigation } from "swiper";
import { useNavigate, useLocation } from "react-router-dom";
import { isTouch } from '../../../utils/DeviceUtils';
import Button from '../button/Button';

/**
* HomeOtherInsurance
*/
function HomeOtherInsurance(props) {

    const navigate = useNavigate();
    const location = useLocation();

    const info = props.branch === 1 ? [

        {
            class: "other-insurance-card-image-auto",
            title: "Auto",
            button: "Ver seguro",
            page: '/auto',
        },
        {
            class: "other-insurance-card-image-home",
            title: "Multirrisco Habitação",
            button: "Ver seguro",
            page: '/casa',
        },
        {
            class: "other-insurance-card-image-saude",
            title: "Saúde",
            inactive: true,
            button: "Brevemente"
        },
        {
            class: "other-insurance-card-image-life",
            title: "Vida",
            button: "Simular seguro",
            branch: 1,
            subBranch: 0,
        },
    ] : props.branch === 3 ?
        [
            {
                class: "other-insurance-card-image-auto",
                title: "Auto",
                button: "Ver seguro",
                page: '/auto',
            },
            {
                class: "other-insurance-card-image-life",
                title: "Vida",
                button: "Ver seguro",
                page: '/vida',
            },
            {
                class: "other-insurance-card-image-home",
                title: "Multirrisco Habitação",
                button: "Simular seguro",
                branch: 3,
                subBranch: 0,
            },
            {
                class: "other-insurance-card-image-saude",
                title: "Saúde",
                inactive: true,
                button: "Brevemente"
            },

        ]
        :
        [
            {
                class: "other-insurance-card-image-home",
                title: "Multirrisco Habitação",
                button: "Ver seguro",
                page: '/casa',
            },
            {
                class: "other-insurance-card-image-life",
                title: "Vida",
                button: "Ver seguro",
                page: '/vida',
            },
            {
                class: "other-insurance-card-image-saude",
                title: "Saúde",
                inactive: true,
                button: "Brevemente"
            },
            {
                class: "other-insurance-card-image-auto",
                title: "Auto",
                button: "Simular seguro",
                branch: 4,
            },
        ];

    const swiperProps = {
        allowTouchMove: true,
        initialSlide: 0,
        loop: true,
        speed: 800,
        slidesPerView: "auto",
        spaceBetween: 24,
        centeredSlides: true,
        breakpoints: {
            375: {
                spaceBetween: 32,
            },
            576: {
            },
            768: {
                spaceBetween: 40,
            },
            992: {
                spaceBetween: 48,
            },
        },
        pagination: {
            clickable: true,
            el: '#otherInsurancesSwiperPagination',
            renderBullet: (index, className) => {
                return '<span' +
                    ' class="proposal-home-swiper-pagination-bullet ' + className + '"></span>';
            }
        },
        navigation: {
            prevEl: '#otherInsurancePaginationPrevious',
            nextEl: '#otherInsurancePaginationNext',
        },
    }

    const goToSimulationBranch = (branch, subBranch, page) => {
        if (page) {
            navigate(page);
        }
        else
            if (branch) {
                let link = '/simulacao?branch=' + branch;
                if (subBranch !== undefined && subBranch !== null) {
                    link += "&subBranch=" + subBranch;
                }
                import("../../../services/AnalyticsServices").then(async analyticsServicsImpl => {
                    analyticsServicsImpl.setEventCTASelected(
                        'Simular seguro',
                        'CTA outros seugros', 1, branch, 0, location.pathname
                    )
                })
                navigate(link);
            }
    }

    return (
        <div className='other-insurance-container '>
            <div className='home-title-container other-insurance-container-title'>
                <h2><span className='bold'>Conheça os seguros</span> que temos para si</h2>
            </div>
            <div className='other-insurance-swipper'>
                <Swiper modules={[Controller, Pagination, Navigation]}
                    {...swiperProps}>
                    {info.map((item, i) => (
                        !props.exclude && !item.branch || item.branch != props.exclude ?
                            <SwiperSlide className='other-insurance-slide' key={i}>
                                <div className={`other-insurance-card`}>
                                    <div className={`other-insurance-card-image ${item.class}`}></div>
                                    <div className='other-insurance-card-title-container'>
                                        <h3 className='bold'>
                                            {item.title ? parse(item.title) : null}
                                        </h3>
                                    </div>
                                    <Button additionalClass={item.inactive ? "other-insurance-button other-insurance-button-inactive" : "other-insurance-button"} label={item.button} onClick={() => goToSimulationBranch(item.branch, item.subBranch, item.page)} />
                                </div>
                            </SwiperSlide> : null
                    ))}
                </Swiper>
                <div className="home-swiper-pagination home-swiper-navigation">
                    {!isTouch() ?
                        <svg id="otherInsurancePaginationPrevious" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.6668 33.333L13.3335 19.9997L26.6668 6.66634" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> : null}
                    <div className='about-uthere-card-swiper-pagination'>
                        <div className="about-uthere-card-swiper-pagination-bullets" id="otherInsurancesSwiperPagination"></div>
                    </div>
                    {!isTouch() ? <svg id="otherInsurancePaginationNext" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3332 6.66699L26.6665 20.0003L13.3332 33.3337" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                    </svg> : null}
                </div>
            </div>
        </div>
    );
};
export default HomeOtherInsurance;