import lara from '../../../assets/lara/lara.png'

export function getAvatar() {
    return {
        img: lara,
        alt: "Lara",
        name: "Lara",
        desc: 'Assistente digital',
        header: "Olá, eu sou a Lara",
        subtitle: "Sou a sua <span className='u-fw-600'>assistente digital</span>, com uma voz e um coração humano💙<br/><br/>" +
            "Não se preocupe com chamadas para vendas de produtos. Aqui você <span className='u-fw-600'>simula e vê o seu preço de imediato.</span> Tudo é 100% digital!",
    };
}

export const homeFlow = {
    begin: {
        sequence: [
            {
                result: 'continue',
                type: 'OR',
                conditions: [
                    {
                        comparator: 'PROCESS_OF_SAME_BRANCH_SUB_BRANCH',

                    }
                ],
            },
            {
                result: 'postal',
            }]
    },
    laraIntroMessage:
    {
        sequence:
            [
            {
                result: ['laraIntroMessageHome'],
            }]
    },
    pages: {
        continue: {
            type: 'continue',
            nextPageId: 'postal',
            userMessage: 'userContinue',
            laraMessage: ['laraChooseInsuranceHouse', 'laraContinue'],
            desktopMessage: ['laraContinueDesktop'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblContinueSimulation',

                    },
                    {
                        value: 2,
                        label: 'lblStartNewSimulation',

                    },
                ]
            }
        },
        postal: {
            type: 'postal',
            laraMessage: ['laraHouseAddress', 'laraHouseAddress2'],
            desktopMessage:  ['laraHouseAddressDesktop'],
            desktopMessageHelper: ['laraHouseAddressHelper'],
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'houseType',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseTypeTemp',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'floor',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseFloorIsNumbered',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'houseAnex',
                        }]
            },
        },
        houseType: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'floorNumber',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseType',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'houseAnex',
                        }]
            },
            laraMessage: ['laraHouseType'],
            desktopMessage: ['laraHouseType'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblHouseType2',

                    },
                    {
                        value: 2,
                        label: 'lblHouseType1',

                    },
                ]
            }
        },
        floorNumber: {
            type: 'postalFloor',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'floor',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseFloorIsNumbered',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'houseAnex',
                        }]
            },
            laraMessage: ['laraFloorNumber'],
            desktopMessage: ['laraFloorNumber'],
        },
        floor: {
            type: 'radio',
            nextPageId: "houseAnex",
            laraMessage: ['laraHouseFloor'],
            desktopMessage: ['laraHouseFloor'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',

                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseAnex: {
            type: 'switch',
            nextPageId: 'houseUse',
            laraMessage:
            {
                sequence:
                    [{
                        result: ['laraHouseAnexHouse'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAnexHouse2'],
                    }]
            },
            desktopMessage:
            {
                sequence:
                    [{
                        result: ['laraHouseAnexHouse'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseType',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAnexHouse2'],
                    }
                    ]
            },
            userMessage: 'userHouseAnex',
            options: {
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                responseToLower: true,
                items: [
                    {
                        value: 1,
                        label: 'lblHouseAnex1',
                        sufix: {
                            sequence:
                                [{
                                    result: ' para {garageNumberCars} carros',
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'GREATER',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'garageNumberCars',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 1,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: ' para {garageNumberCars} carro',
                                }]
                        },
                        followup: [
                            {
                                id: 'garageNumberCars',
                                header: 'Quantos carros consegue estacionar na sua garagem?',
                                type: 'wheel',
                                options: {
                                    min: {
                                        type: 'constant',
                                        value: 1,
                                    },
                                    max: {
                                        type: 'constant',
                                        value: 10,
                                    },
                                    default: {
                                        type: 'constant',
                                        value: 1,
                                    },
                                }
                            }]
                    },
                    {
                        value: 3,
                        label: 'lblHouseAnex3',
                    },
                    {
                        value: 2,
                        label: 'lblHouseAnex2',
                        condition: {
                            sequence:
                                [{
                                    result: true,
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseType',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 2,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: false,
                                }]
                        },
                    },
                    {
                        value: 5,
                        label: 'lblHouseAnex5',
                        condition: {
                            sequence:
                                [{
                                    result: true,
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseType',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 1,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: false,
                                }]
                        },

                    },
                    {
                        value: 4,
                        label: 'lblHouseAnex4',
                        responseLabel: '{otherAnexDescription}',
                        followup: [
                            {
                                id: 'otherAnexDescription',
                                header: 'A que tipo de anexo se refere?',
                                type: 'text',
                                options: {
                                    maxChars: 160,
                                    type: 'textarea',
                                    label: 'Descrição',
                                }
                            }]
                    },
                ]
            }
        },
        houseUse: {
            type: 'radio',
            nextPageId: "houseLive",
            laraMessage: ['laraHouseUse'],
            desktopMessage: ['laraHouseUseDesktop'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblHouseUse1',

                    },
                    {
                        value: 3,
                        label: 'lblHouseUse3',

                    },
                    {
                        value: 2,
                        label: 'lblHouseUse2',

                    },
                ]
            },
        },
        houseLive: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'houseArrendatarioConfirmation',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseUse',
                                        },
                                        {
                                            type: 'constant',
                                            value: 3,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'houseComercialUse',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseLive',
                                        },
                                        {
                                            type: 'constant',
                                            value: 2,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'houseArea',
                        }]
            },
            userMessage: 'userHouseLive',
            laraMessage:
            {
                sequence:
                    [{
                        result: ['laraHouseLive2'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseLive3'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseLive'],
                    }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraHouseLive2'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseLive3'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseLiveDesktop'],
                    }]
            },
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblHouseLive1',
                        labelCondition: {
                            sequence:
                                [{
                                    result: "Vou viver aqui",
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseUse',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 2,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: "Sim, vivo aqui",
                                }]
                        },
                    },
                    {
                        value: 2,
                        label: 'lblHouseLive2',
                        labelCondition: {
                            sequence:
                                [{
                                    result: "Vou arrendar",
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseUse',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 2,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: "Não, arrendei",
                                }]
                        },
                        condition: {
                            sequence:
                                [{
                                    result: false,
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseUse',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 3,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: true,
                                }]
                        },
                    },
                    {
                        value: 3,
                        label: 'lblHouseLive3',
                    },
                    {
                        value: 4,
                        label: 'lblHouseLive4',
                        condition: {
                            sequence:
                                [{
                                    result: false,
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseUse',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 2,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: true,
                                }]
                        },
                    },
                ]
            }
        },
        houseComercialUse: {
            type: 'radio',
            nextPageId: {
                sequence:
                    [
                        {
                            result: 'houseArea',
                            type: 'AND',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseComercialUse',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                            ],
                        },
                        {
                            result: 'houseContact',
                        }]
            },
            laraMessage: ['laraHouseComercialUse', 'laraHouseComercialUse2'],
            desktopMessage: ['laraHouseComercialUse'],
            desktopMessageHelper: ['laraHouseComercialUse2'],
            options: {
                items: [
                    {
                        value: 1,
                        label: 'lblHouseComercialUse',

                    },
                    {
                        value: 2,
                        label: 'lblHouseComercialUse2',

                    },
                    {
                        value: 3,
                        label: 'lblHouseComercialUse3',

                    },
                ]
            },
        },
        houseContact: {
            type: "contact",
            laraMessage: ['laraHouseContact', 'laraHouseContact2'],
            desktopMessage: ['laraHouseContactDesktop'],
            nextPageId: 'endContact',
            options: {
                cancel: true,
                subject: 'Utilizador pretende seguro para casa de uso comercial',
                contactText: 'Utilizador pretende seguro para casa de uso comercial. É esperado contacto de especialista'
            },
        },
        houseArrendatarioConfirmation: {
            nextPageId: "houseArea",
            laraMessage: ['laraHouseArrendatarioConfirmation', 'laraHouseArrendatarioConfirmation2'],
            desktopMessage: ['laraHouseArrendatarioConfirmation'],
            desktopMessageHelper: ['laraHouseArrendatarioConfirmation2'],
            type: "next",
            userMessage: 'lblUnderstand',
            options: {
                label: 'lblUnderstand'
            }
        },
        houseArea: {
            type: 'input',
            userMessage: "userAreaOnly",
            refreshProposal: true,
            laraMessage: ['laraHouseAreaHouse'],
            desktopMessage: ['laraHouseAreaHouse'],
            nextPageId: {
                sequence:
                    [{
                        result: "houseAreaAnex",
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseYear',
                    }]
            },
            options: {
                type: 'int',
                mode: 'numeric',
                decimals: 0,
                label: 'lblArea',
                min: 20,
                minWarn: 'A área da casa parece-me um pouco pequena. Tente outra vez',
                max: 2000,
                maxWarn: 'A área da casa parece-me um pouco grande demais. Tente outra vez',
            }
        },
        houseAreaAnex: {
            type: 'input',
            userMessage: "userAreaAnex",
            refreshProposal: true,
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHouseAreaAnex6'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex4'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex5'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex3'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex1'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    { result: 'laraHouseAreaAnex2' }]
            },
            desktopMessage: {
                sequence:
                    [{
                        result: ['laraHouseAreaAnex6'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex4'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex5'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex3'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseAreaAnex1'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'CONTAINS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseAnex',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    { result: ['laraHouseAreaAnex2'] }]
            },
            nextPageId: 'houseYear',
            options: {
                type: 'int',
                mode: 'numeric',
                decimals: 0,
                label: 'lblArea',
                max: 200,
                min: 5,
                maxWarn: 'A área dos anexos parece-me um pouco grande demais. Tente outra vez',
                minWarn: 'A área dos anexos parece-me um pouco pequena. Tente outra vez',
            }
        },
        houseYear: {
            type: 'input',
            refreshProposal: true,
            laraMessage: ['laraHouseYear', 'laraHouseYear2'],
            desktopMessage: ['laraHouseYear'],
            desktopMessageHelper: ['laraHouseYear2'],
            nextPageId: {
                sequence: [
                    {
                        result: 'houseRenovations',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'LESSER',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseYear',
                                    },
                                    {
                                        type: 'operation',
                                        operation: 'MINUS',
                                        value: 'yearToday',
                                        value2: 39,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseMaterials',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseMaterials',
                    }]
            },
            options: {
                type: 'int',
                mode: 'numeric',
                decimals: 0,
                label: 'lblYear',
                min: 1850,
                max: 2023,
                minWarn: 'Só posso fazer simulações se ano de construção for superior a 1850',
                maxWarn: 'Ups! Parece que escolheu uma data no futuro. Tente outra vez',
            }
        },
        houseRenovations: {
            type: 'radio',
            refreshProposal: true,
            laraMessage: ['laraHouseRenovations', 'laraHouseRenovations2'],
            desktopMessage: ['laraHouseRenovations'],
            desktopMessageHelper: ['laraHouseRenovations2'],
            userMessage:
            {
                sequence:
                    [{
                        result: 'userHouseRenovations',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseRenovations',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },

                    {
                        result: 'userHouseRenovations2',
                    }]
            },
            nextPageId: 'houseMaterials',
            options: {
                columns: true,
                onDesktopChange: {
                    items: [
                        {
                            value: 3,
                            text: 'Vou simular como se não tivessem sido feitas obras de renovação',

                        },
                    ]
                },
                items: [
                    {
                        value: 1,
                        label: 'lblHouseRenovationsYes',
                        followup: [
                            {
                                id: 'houseRenovationsYear',
                                header: 'Em que ano?',
                                type: 'wheel',
                                options: {
                                    max: {
                                        type: 'constant',
                                        value: 1850,
                                    },
                                    min: {
                                        type: 'systemVariable',
                                        value: 'yearToday',
                                    },
                                    default: {
                                        type: 'systemVariable',
                                        value: 'yearToday',
                                    },
                                },
                            }]
                    },
                    {
                        value: 2,
                        label: 'lblHouseRenovationsNo',

                    },
                    {
                        value: 3,
                        label: 'lblHouseRenovationsDontKnow',

                    },
                ]
            }
        },
        houseMaterials: {
            type: 'houseMaterials',
            userMessage:
            {
                sequence:
                    [{
                        result: 'userHouseMaterials2',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseMaterials',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },

                    {
                        result: 'userHouseMaterials',
                    }]
            },
            refreshProposal: true,
            desktopMessage: {
                sequence:
                    [
                        {
                            result: ['laraHouseMaterials2'],
                            type: 'OR',
                            conditions: [
                                {
                                    comparator: 'EQUALS',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseRenovations',
                                        },
                                        {
                                            type: 'constant',
                                            value: 1,
                                        },
                                    ]
                                },
                                {
                                    comparator: 'GREATER',
                                    values: [
                                        {
                                            type: 'userVariable',
                                            value: 'houseYear',
                                        },
                                        {
                                            type: 'operation',
                                            operation: 'MINUS',
                                            value: 'yearToday',
                                            value2: 40,
                                        },
                                    ]
                                }
                            ],
                        },
                        {
                            result: ['laraHouseMaterials'],
                        }]
            },
            nextPageId: {
                sequence: [
                    {
                        result: 'houseMaterialsConfirm',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseMaterials',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseEquipments',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            }
                        ],
                    },
                    {
                        result: 'houseEquipments',
                    }]
            },
            laraMessage:
            {
                sequence:
                    [{
                        result: ['laraHouseMaterials3', 'laraHouseMaterials'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseRenovations',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseMaterials'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseRenovations',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseMaterials2'],
                    }]
            },
        },
        houseMaterialsConfirm: {
            nextPageId: "houseEquipments",
            laraMessage: ['laraHouseMaterialsConfirm'],
            desktopMessage: ['laraHouseMaterialsConfirm'],
            type: "next",
            userMessage: 'lblAgree',
            options: {
                label: 'lblAgree'
            }
        },
        houseEquipments: {
            type: 'switch',
            proposalAsync: {
                sequence:
                    [{
                        result: true,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: true,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseLive',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: false,
                    }]
            },
            nextPageId: {
                sequence: [
                    {
                        result: 'bornDate',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseCredit',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseLive',
                                    },
                                    {
                                        type: 'constant',
                                        value: 4,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'hasBens',
                    }]
            },
            desktopMessage: ['laraHouseEquipmentsDesktop'],
            laraMessage: ['laraHouseEquipments'],
            options: {
                responseToLower: true,
                mandatory: false,
                notSelectedLabel: 'lblNo',
                selectedLabel: 'lblConfirm',
                items: [
                    {
                        value: 1,
                        label: 'lblHouseEquipments1',

                    },
                    {
                        value: 2,
                        label: 'lblHouseEquipments2',
                    },
                    {
                        value: 3,
                        condition: {
                            sequence:
                                [{
                                    result: true,
                                    type: 'OR',
                                    conditions: [
                                        {
                                            comparator: 'EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseType',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 1,
                                                },
                                            ]
                                        },
                                        {
                                            comparator: 'NOT_EQUALS',
                                            values: [
                                                {
                                                    type: 'userVariable',
                                                    value: 'houseFloorIsNumbered',
                                                },
                                                {
                                                    type: 'constant',
                                                    value: 1,
                                                },
                                            ]
                                        },
                                    ],
                                },
                                {
                                    result: false,
                                }]
                        },
                        label: 'lblHouseEquipments3',
                    },
                ]
            }
        },
        hasBens: {
            proposalAsync: {
                sequence:
                    [{
                        result: true,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'hasBens',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: false,
                    }]
            },
            type: 'radio',
            refreshProposal: false,
            nextPageId: {
                sequence: [
                    {
                        result: 'houseBens',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'hasBens',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseCredit',
                    }]
            },
            desktopMessage: ['laraHasBens'],
            laraMessage: ['laraHasBens'],
            options: {
                columns: true,
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseBens: {
            proposalAsync: true,
            type: 'radio',
            refreshProposal: false,
            nextPageId: "houseCredit",
            desktopMessage: ['laraHouseBens'],
            laraMessage: ['laraHouseBens'],
            userMessage:
            {
                sequence:
                    [{
                        result: 'userHouseBens',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseBens',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },

                    {
                        result: 'userHouseBens2',
                    }]
            },
            options: {
                columns: true,
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: 'Vou incluir esta cobertura no seu seguro',

                        },
                    ]
                },
                items: [
                    {
                        value: 1,
                        label: 'lblHouseBens',
                    },
                    {
                        value: 2,
                        label: 'lblHouseBens2',

                    },
                ]
            }
        },
        houseCredit: {
            type: 'radio',
            refreshProposal: false,
            nextPageId: {
                sequence: [
                    {
                        result: 'houseHasFenomenos',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseCredit',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseActiveContract',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseCredit',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'houseActiveContract',
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseUse',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'bornDate',
                    }]
            },
            laraMessage: {
                sequence:
                    [{
                        result: ['laraIncludeBensCasa', 'laraHouseCreditDesktop'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseBens',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'hasBens',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraIncludeBens', 'laraHouseCreditDesktop'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseBens',
                                    },
                                    {
                                        type: 'constant',
                                        value: 2,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'hasBens',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseCreditDesktop'],
                    }]
            },
            desktopMessage: ['laraHouseCreditDesktop'],
            options: {
                columns: true,
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: 'Se escolher a nossa proposta eu trato de tudo. Informo a sua seguradora actual e o seu banco. Não tem que fazer nada',

                        },
                    ]
                },
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseActiveContract: {
            type: 'radio',
            refreshProposal: false,
            nextPageId: {
                sequence: [
                    {
                        result: 'houseHasFenomenos',
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseActiveContract',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: 'bornDate',
                    }]
            },
            desktopMessage: ['laraHouseActiveContract'],
            laraMessage: ['laraHouseActiveContract'],
            options: {
                columns: true,
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: 'Se escolher a nossa proposta eu trato de tudo, e informo a sua seguradora actual. Não tem que fazer nada',

                        },
                    ]
                },
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                ]
            }
        },
        houseHasFenomenos: {
            type: 'radio',
            refreshProposal: false,
            nextPageId: "bornDate",
            desktopMessage: ['laraHouseHasFenomenos'],
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHouseActiveContract2', 'laraHouseHasFenomenos'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseCredit',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseActiveContract3', 'laraHouseHasFenomenos'],
                    }]
            },
            options: {
                columns: true,
                onDesktopChange: {
                    items: [
                        {
                            value: 1,
                            text: 'Vou incluir esta cobertura no seu seguro',

                        },
                        {
                            value: 3,
                            text: 'Não tem problema. Caso queira, pode incluir esta cobertura na proposta que lhe apresentar',

                        },
                    ]
                },
                items: [
                    {
                        value: 1,
                        label: 'lblYes',
                    },
                    {
                        value: 2,
                        label: 'lblNo',

                    },
                    {
                        value: 3,
                        label: 'lblDontKnowYet',

                    },
                ]
            }
        },
        bornDate: {
            type: 'date',
            allowBack: true,
            desktopMessage: ['laraBornDateHouse'],
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHasFenomenos2', 'laraBornDateHouse'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseHasFenomenos',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseCredit',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHasFenomenos2', 'laraBornDateHouse'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseActiveContract',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseHasFenomenos',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHasFenomenos3', 'laraBornDateHouse'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseHasFenomenos',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseCredit',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHasFenomenos3', 'laraBornDateHouse'],
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseActiveContract',
                                    },
                                    {
                                        type: 'constant',
                                        value: 1,
                                    },
                                ]
                            },
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'houseHasFenomenos',
                                    },
                                    {
                                        type: 'constant',
                                        value: 3,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraBornDateHouse'],
                    }]
            },
            userMessage: 'userBornDate',
            nextPageId: 'generateProposal',
            options: {
                onBirthday: "Parabéns! 🎉🍾",
                onAlmostBirthday: "Uau. Está quase a fazer anos 😀",
                validation: {
                    type: "AGE",
                    min: 18,
                    max: 100,
                    minText: "Não podemos apresentar uma proposta se não tiver 18 anos",
                    maxText: "Não podemos apresentar uma proposta se tiver mais do que 100 anos"
                },
                label: 'lblBorndDate',
                default:
                {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 40,
                            },
                        ]
                    }
                },
                max: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 1,
                            },
                        ]
                    }
                },
                min: {
                    year: {
                        operation: 'MINUS',
                        values: [
                            {
                                type: 'systemVariable',
                                value: 'yearToday',
                            },
                            {
                                type: 'constant',
                                value: 125,
                            },
                        ]
                    }
                }
            }
        },
        generateProposal: {
            type: 'loader',
            desktopMessage: ['laraProposalHome'],
            laraMessage: {
                sequence:
                    [{
                        result: ['laraHappyBirthdaySecondary', 'laraProposalHome'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                        ],
                    },
                    {
                        result: ['laraAlmostBirthdaySecondary', 'laraProposalHome'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'ALMOST_BIRTHDAY',
                                bornDateValueId: 'bornDate',
                            },
                        ],
                    },
                    {
                        result: ['laraProposalHome'],
                    }]
            },
            userMessage: {
                sequence:
                    [{
                        result: null,
                        type: 'AND',
                        conditions: [
                            {
                                comparator: 'EMAIL_USER',
                            }
                        ],
                    },
                    {
                        result: null,
                    }]
            },
            options: {
                barNumber: 4,
                messages: [ "A analisar os <br/><b> seus dados</b>",
                "A procurar a <br/> <b> melhor proposta </b>",
                "A calcular  <br/><b> descontos</b>",
                "Um pouco de  <br/><b> magia uthere 😉</b>",
                "Encontrei a<br/><b>sua proposta</b>"],
                interval: 60
            },
            nextPageId: "showProposal",
        },
        showProposal: {
            allowBack: false,
            type: 'proposal',
            options: {
                subType: 'show',
            },
            nextPageId: "endSimulation",
            laraMessage: ['laraProposalShow'],
            desktopMessage: ['laraProposalShow'],
            userMessage: null,
            userMessageType: 'homeProposal',
            userMessageOptions: {
                valueId: 'selectedProposal',
                type: 'window',
                items: [
                    {
                        id: 'selectedProposal',
                        type: 'selectedProposal',
                        titleLabel: 'Proposta'
                    },
                ]
            }

        },
        endSimulation: {
            disableExitConfirm: true,
            last: true,
            laraMessage:
            {
                sequence:
                    [{
                        result: ['laraHouseEndSimulationAnaliseTecnica', 'laraHouseEndSimulationAnaliseTecnica2'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'analiseTecnica',
                                    },
                                    {
                                        type: 'constant',
                                        value: true,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseEndSimulation'],
                    }]
            },
            desktopMessage:
            {
                sequence:
                    [{
                        result: ['laraHouseEndSimulationAnaliseTecnica'],
                        type: 'OR',
                        conditions: [
                            {
                                comparator: 'EQUALS',
                                values: [
                                    {
                                        type: 'userVariable',
                                        value: 'analiseTecnica',
                                    },
                                    {
                                        type: 'constant',
                                        value: true,
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        result: ['laraHouseEndSimulation'],
                    }]
            },
            hideAvatar: true,
            type: 'rate',
            userMessage: 'userMessageEndSimulation',
            nextPageId: 'exitSimulation',
            allowBack: false,
            allowRestart: false,
        },
        exitSimulation: {
            hideAvatar: true,
            laraMessage: ['laraExitSimulation'],
            desktopMessage: ['laraHouseEndSimulationAnaliseTecnica'],
            disableExitConfirm: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
        },
        endContact: {
            laraMessage: ['laraEndContact'],
            desktopMessage: ['laraEndContact'],
            disableExitConfirm: true,
            last: true,
            type: 'exitOnly',
            allowBack: false,
            allowRestart: false,
        },
    }
};